import { ChartTypeRegistry } from 'chart.js'
import { RefObject } from 'react'
import { Chart, ChartProps } from 'react-chartjs-2'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

export interface ChartComponentProps<TType extends keyof ChartTypeRegistry, TData, TLabel> {
  chartRef: RefObject<ChartJSOrUndefined<TType, TData, TLabel>>
  props: ChartProps<TType, TData, TLabel>
}

export const ChartComponent = <TType extends keyof ChartTypeRegistry, TData, TLabel>({
  chartRef,
  props,
}: ChartComponentProps<TType, TData, TLabel>) => {
  // console.debug('ChartComponent: rendering ChartComponent')
  return <Chart ref={chartRef} {...props} />
}
