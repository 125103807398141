import { RefObject, useEffect, useMemo, useState } from 'react'
import { ChartProps } from 'react-chartjs-2'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

import { SimpleLegend } from './simple-legend/SimpleLegend'

interface UseLegendProps {
  chartRef: RefObject<ChartJSOrUndefined>
  chartProps: ChartProps
  showDatalabels?: boolean
  showActiveDatasets?: boolean
  defaultActiveDatasets?: number[]
}

export const useLegend = ({
  chartRef,
  chartProps,
  showDatalabels = false,
  showActiveDatasets = false,
  defaultActiveDatasets = [],
}: UseLegendProps) => {
  const legendItems = useMemo(
    () =>
      chartProps.data.datasets.map((dataset) => ({
        label: dataset.label as string,
        color: (dataset.borderColor || dataset.backgroundColor) as string,
      })),
    [chartProps.data.datasets],
  )

  const [active, setActive] = useState(defaultActiveDatasets || [])

  useEffect(() => {
    // console.debug('useRevenueChart: useEffect: update datalabels')
    if (chartRef.current) {
      // console.debug('useRevenueChart: useEffect: update datalabels')
      if (showDatalabels) {
        chartRef.current!.data.datasets.forEach((dataset, datasetIdx) => {
          if (dataset.datalabels) {
            dataset.datalabels.display = active.indexOf(datasetIdx) > -1
          }
        })
        chartRef.current.update()
      }
    }
  })

  const activeProps = showActiveDatasets && {
    active,
    setActive: (idx: number) => {
      if (defaultActiveDatasets.indexOf(idx) === -1) {
        setActive((curr) => (curr.indexOf(idx) > -1 ? curr.filter((x) => x !== idx) : [...curr, idx]))
      }
    },
  }

  return () => (
    <SimpleLegend
      items={legendItems}
      {...activeProps}
      onHover={(idx) => {
        if (chartRef?.current) {
          if (idx !== undefined) {
            const numData = chartRef.current.data.datasets[idx].data.length
            const activeElements = Array(numData)
              .fill(0)
              .map((v, i) => ({ datasetIndex: idx, index: i }))

            // console.debug('RevenueLegend: highlightDataset: setting active elements', activeElements)
            chartRef.current.setActiveElements(activeElements)
          } else {
            // console.debug('RevenueLegend: highlightDataset: clearing active elements')
            chartRef.current.setActiveElements([])
          }
          chartRef.current.update()
        }
      }}
    />
  )
}
