import { nopeResolver } from '@hookform/resolvers/nope'
import classNames from 'classnames'
import Nope from 'nope-validator'
import { Fragment, useContext } from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { EmailCredentials } from '../../auth/AuthClient'
import { AuthContext } from '../../auth/AuthContext'
import { Button } from '../../components/common/button/Button'
import { Input } from '../../components/common/form/Input'
import { Loader } from '../../components/loader/Loader'
import { Logo } from '../../components/logo/Logo'

import styles from './login.module.scss'

export const LoginPage = () => {
  const loginSchema = Nope.object().shape({
    email: Nope.string().required().email(),
    password: Nope.string().required(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailCredentials>({
    resolver: nopeResolver(loginSchema),
  })

  const { client } = useContext(AuthContext)
  const navigate = useNavigate()

  const {
    mutate: loginWithEmail,
    isLoading: isLoggingIn,
    error: loginError,
  } = useMutation('loginWithEmail', async (credentials: EmailCredentials) => {
    await client.loginWithEmailPassword(credentials)
    navigate('/')
  })

  const onSubmit = (data: EmailCredentials) => loginWithEmail(data)

  const loginErrorMessage = (loginError as any)?.response?.data?.message

  return (
    <Fragment>
      <div className={styles.page}>
        <span className={styles.content}>
          <span className={styles.logo}>
            <Logo />
            <span className={`${styles.logoText}`}>Counts</span>
          </span>
          <span className={styles.formPanel}>
            <h1 className={styles.title}>Welcome to Counts</h1>
            <Form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
              {isLoggingIn ? (
                <Loader />
              ) : (
                <Fragment>
                  {!!loginError && (
                    <span className={styles.error}>
                      Error logging in{loginErrorMessage && `: ${loginErrorMessage}`}
                    </span>
                  )}
                  <Input
                    {...register('email')}
                    className={classNames(styles.input)}
                    variant={'floating'}
                    icon={'clear'}
                    placeholder={'Email Address'}
                    error={errors?.email?.message}
                    autoComplete={'email'}
                    id={'inputEmail'}
                    tabIndex={1}
                  />
                  <Input
                    {...register('password')}
                    className={styles.input}
                    variant={'floating'}
                    icon={'show'}
                    placeholder={'Password'}
                    type={'password'}
                    error={errors?.password?.message}
                    autoComplete={'current-password'}
                    id={'inputPassword'}
                    tabIndex={2}
                  />
                  <Button variant={'primary'} type={'submit'} className={styles.loginButton} tabIndex={3}>
                    Login
                  </Button>
                </Fragment>
              )}
            </Form>
          </span>
        </span>
      </div>
    </Fragment>
  )
}
