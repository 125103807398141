import { color } from 'chart.js/helpers'

import { ReactComponent as TickIcon } from '../../../../assets/icons/tick.svg'
import { Button } from '../../button/Button'

import styles from './SimpleLegend.module.scss'

export interface SimpleLegendItem {
  label: string
  color: string
}

export interface SimpleLegendProps {
  items: SimpleLegendItem[]
  onHover?: (idx?: number) => void
  active?: number | number[]
  setActive?: (idx: number) => void
}

export const SimpleLegend = ({ items, active, onHover, setActive }: SimpleLegendProps) => {
  if (active && !Array.isArray(active)) {
    active = [active]
  }

  return (
    <div className={styles.legend}>
      {items.map((item, idx) => {
        return (
          <Button
            variant={'transparent'}
            key={idx}
            className={styles.legendItem}
            onMouseEnter={() => onHover && onHover(idx)}
            onMouseLeave={() => onHover && onHover()}
            onClick={() => setActive && setActive(idx)}
          >
            <span className={styles.color} style={{ backgroundColor: item.color }}>
              {(active as number[])?.indexOf(idx) > -1 && (
                <TickIcon className={styles.tick} style={{ stroke: color('white').rgbString() }} />
              )}
            </span>
            <span className={styles.label}>{item.label}</span>
          </Button>
        )
      })}
    </div>
  )
}
