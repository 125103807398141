import { Fragment, useContext, useMemo } from 'react'

import { AnalyticsStat } from '../../components/analytics/AnalyticsStat'
import { ComparativeCard } from '../../components/analytics/ComparativeCard'
import { DoughnutCard } from '../../components/analytics/DoughnutCard'
import { MonthlyChart } from '../../components/analytics/MonthlyChart'
import { AccountingContext } from '../../layouts/AccountingLayout'
import { Voucher } from '../../types/accounting'
import { calculateAnalyticsData } from '../../utils/analytics'
import { DateFormatUtils, DateTransformUtils } from '../../utils/date'

import styles from './analytics.module.scss'

export const Purchases = () => {
  // console.debug('rendering purchases page')

  const { vouchers, selectedFy } = useContext(AccountingContext)

  const purchaseVouchers: Voucher[] = useMemo(
    () =>
      vouchers
        .filter((x: Voucher) => x.master.superParent?.name === 'Purchase Accounts')
        .map((x: Voucher) => ({ ...x, amount: -x.amount })),
    [vouchers],
  )

  const analyticsData = calculateAnalyticsData(purchaseVouchers, selectedFy)

  const fyString = DateFormatUtils.FY(analyticsData.fy)

  const numInvoices = analyticsData.fyVouchers.length
  const suppliersData = analyticsData.grouped.partyName
  const productsData = analyticsData.grouped.name

  return (
    <Fragment>
      <span className={styles.stats}>
        <span className={styles.mainStat}>
          <AnalyticsStat
            title={'Purchases'}
            mainStat={{ label: fyString, value: analyticsData.totals.fy }}
            secondaryStat={{
              label: analyticsData.isLatestFY
                ? DateFormatUtils.M(DateTransformUtils.YMD_M(analyticsData.lastVoucherDate.fy), 'long')
                : DateFormatUtils.FY(analyticsData.refFy),
              value: analyticsData.totals.refAmount,
            }}
          />
        </span>
        <AnalyticsStat type={'number'} title={'Invoices'} mainStat={{ label: fyString, value: numInvoices }} />
        <AnalyticsStat
          type={'number'}
          title={'Suppliers'}
          mainStat={{ label: fyString, value: suppliersData.length }}
        />
      </span>

      <h2 className={styles.title}>Comparative Data</h2>
      <ComparativeCard analyticsData={analyticsData} />

      <h2 className={styles.title}>Purchases by Suppliers</h2>
      <DoughnutCard data={suppliersData} labelHeader={'Top Suppliers'} valueHeader={'Current Balance (₹)'} />

      <h2 className={styles.title}>Purchases by Categories</h2>
      <DoughnutCard data={productsData} labelHeader={'Top Categories'} valueHeader={'Current Balance (₹)'} />

      <h2 className={styles.title}>Monthly Purchase History</h2>
      <MonthlyChart data={analyticsData.aggregations.fy} />
    </Fragment>
  )
}
