import { useContext } from 'react'

import { StatementData, StatementHead, useBalanceSheetData, useIncomeData } from '../../../accounting/useStatementData'
import { RatioCard } from '../../../components/ratio/ratio-card/RatioCard'
import { AccountingContext } from '../../../layouts/AccountingLayout'
import { DateFyUtils } from '../../../utils/date'
import ratiosList from './ratios.json'

import styles from './ratio-list.module.scss'

export const RatiosList = () => {
  const { selectedFy } = useContext(AccountingContext)

  const startDate = DateFyUtils.START_YMD(selectedFy)
  const endDate = DateFyUtils.END_YMD(selectedFy)

  const { incomeRatios } = useIncomeData({ startDate: startDate, endDate: endDate })
  const { balanceSheetRatios } = useBalanceSheetData({ startDate: startDate, endDate: endDate })

  const ratiosData = { ...incomeRatios, ...balanceSheetRatios } as Record<StatementHead, StatementData>

  const allRatios = ratiosList.map((ratio) => {
    return { ...ratio, data: ratiosData[ratio.name as StatementHead] }
  })

  // console.debug({ allRatios })

  return (
    <div className={styles.list}>
      {allRatios.map((ratio) => (
        <div key={ratio.name} className={styles.card}>
          <RatioCard
            ratioName={ratio.name}
            title={ratio.title}
            description={ratio.shortDesc}
            value={ratio.data?.total || NaN}
            format={ratio.format as any}
          />
        </div>
      ))}
    </div>
  )
}
