import deepmerge from 'deepmerge'
import { RefObject } from 'react'
import { ChartProps } from 'react-chartjs-2'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

import { ShowPanLimits } from './plugins/ShowPanLimits'
import { useCartesianScale } from './useCartesianScale'
import { UseChartProps, useChart } from './useChart'

type TType = 'bar' | 'line'

export interface UseBarChartProps<TData, TLabel> extends UseChartProps<TType, TData, TLabel> {
  chartRef?: RefObject<ChartJSOrUndefined<TType, TData, TLabel>>
  visibleCount?: number
  showPanLimits?: boolean
  props: ChartProps<TType, TData, TLabel> & { type: TType }
}

export const useBarChart = <TData, TLabel>({
  chartRef,
  props,
  visibleCount = props.data.labels?.length,
  showPanLimits = true,
}: UseBarChartProps<TData, TLabel>) => {
  const scales = props.data.datasets.reduce((limits, dataset) => {
    const yAxisID = dataset.yAxisID || 'y'
    const suggestedMin = Math.min(limits[yAxisID]?.suggestedMin || Infinity, ...(dataset.data as unknown as number[]))
    const suggestedMax = Math.max(limits[yAxisID]?.suggestedMax || -Infinity, ...(dataset.data as unknown as number[]))

    return { ...limits, [yAxisID]: { yAxisID, suggestedMin, suggestedMax } }
  }, {} as { [yAxisID: string]: { yAxisID: string; suggestedMin: number; suggestedMax: number } })

  // console.debug('useBarChart: scales', scales)

  props = deepmerge(
    {
      type: 'bar',
      options: { scales, plugins: { showPanLimits: { enabled: showPanLimits } } },
      plugins: [ShowPanLimits],
    },
    props,
  )

  useCartesianScale({ chartRef, visibleCount })

  return { ...useChart({ props }) }
}
