import { useContext } from 'react'

import { InvoiceTracker } from '../../components/invoice/InvoiceTracker'
import { AccountingContext } from '../../layouts/AccountingLayout'
import { DateFormatUtils, DateFyUtils } from '../../utils/date'

export const PayableInvoices = () => {
  const { vouchers, selectedFy } = useContext(AccountingContext)

  const fyEndDate = DateFyUtils.END_YMD(selectedFy)

  const debtorVouchers = vouchers.filter((x) => x.master.parent?.name === 'Sundry Creditors' && x.date <= fyEndDate)
  // .map((x) => ({
  //   ...x,
  //   amount: -x.amount,
  //   billAllocations: x.billAllocations.map((b) => ({ ...b, amount: -b.amount })),
  // }))

  const salesVoucherTypes = ['Purchase', 'Credit Note']

  return (
    <InvoiceTracker
      vouchers={debtorVouchers}
      voucherTypes={salesVoucherTypes}
      chartVoucherTypes={['Purchase', 'Payment']}
      text={{
        section2: `Purchase Invoices (${DateFormatUtils.FY(selectedFy)})`,
        section3: `Previous Period Unpaid Invoices`,
        legendData1: 'Purchases',
        legendData2: 'Payments',
      }}
    />
  )
}
