import { Chart, ChartType, FontSpec, Plugin } from 'chart.js'
import { renderText, toFont } from 'chart.js/helpers'
import { AnyObject } from 'chart.js/types/basic'

declare module 'chart.js' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PluginOptionsByType<TType extends ChartType> {
    doughnutLabel: DoughnutLabelPluginOptions
  }
}

interface LabelSpec extends FontSpec {
  text: string
  color: string
}

interface DoughnutLabelPluginOptions extends AnyObject {
  labels: LabelSpec[]
  gap: number
}

export const DoughnutLabelPlugin: Plugin<'doughnut', DoughnutLabelPluginOptions> = {
  id: 'doughnutLabel',
  afterDraw(chart: Chart, args = {}, options: DoughnutLabelPluginOptions) {
    options.labels = [...(options.labels || [])].map((label) => ({ ...chart.options.font, ...label }))
    options.gap = options.gap || 4

    const h = options.labels.reduce((h, label) => h + label.size + options.gap, 0) - options.gap
    let top = (chart.height - h) / 2
    options.labels.forEach((label) => {
      renderText(chart.ctx, label.text, chart.width / 2, top, toFont({ ...label }), {
        color: label.color,
        textAlign: 'center',
        textBaseline: 'top',
      })
      top += label.size + options.gap
    })
  },
}
