import dayjs from 'dayjs'
import React, { Fragment, useContext } from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import { Button } from '../../components/common/button/Button'
import { Input } from '../../components/common/form/Input'
import { Topbar } from '../../components/topbar/Topbar'
import { useApiClient } from '../../hooks/useApiClient'
import { PageContext } from '../../layouts/DashboardLayout'
import { ClosingStockMethodLabels, ClosingStockMethods, Company } from '../../types/company'
import { DATETIME_FORMAT } from '../../utils/date'

import styles from './settings.module.scss'

export const Settings = () => {
  const { company, setCompany } = useContext(PageContext)
  const apiClient = useApiClient()

  const { register, handleSubmit, reset, formState } = useForm<Company>({
    defaultValues: { ...company, closingStockMethod: company.closingStockMethod || 'weightedAverage' },
  })

  const { mutate } = useMutation(['update', company._id], async (updatedCompany: Company) => {
    await apiClient.patch(`/companies/${updatedCompany._id}`, updatedCompany)
  })

  return (
    <Fragment>
      <Topbar breadcrumb={<Topbar.Breadcrumb items={['Settings']} />} />

      <div className={styles.body}>
        <Form
          onSubmit={handleSubmit(async (data) => {
            await mutate(data)
            reset(data)
            setCompany(data)
          })}
        >
          <h1>Company Details</h1>
          <div className={styles.list}>
            <span>
              <label>Company Name</label>
              <Input {...register('name')} disabled={true} />
            </span>
            <span>
              <label>Last Sync Date/Time</label>
              <Input
                {...register('lastSyncDateTime', {
                  value: dayjs(company.lastSyncDateTime).format(DATETIME_FORMAT),
                })}
                disabled={true}
              />
            </span>
            <span>
              <label>Last Transaction Date</label>
              <Input {...register('lastTransactionDate')} disabled={true} />
            </span>
            <span>
              <label>Tally API Key</label>
              <Input {...register('apiKey')} disabled={true} />
            </span>
            <span>
              <label>Closing Stock Calculation Method</label>
              {ClosingStockMethods.map((x) => (
                <Form.Check
                  key={x}
                  {...register('closingStockMethod')}
                  type={'radio'}
                  value={x}
                  label={ClosingStockMethodLabels[x]}
                />
              ))}
            </span>
          </div>
          <br />
          <div>
            <Button variant={'primary'} type={'submit'} disabled={!formState.isDirty}>
              Save
            </Button>
            <Button variant={'outline'} onClick={() => reset()} disabled={!formState.isDirty}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </Fragment>
  )
}
