import { ArcElement, Chart, ChartArea, FontSpec, LayoutItem, LayoutPosition, Plugin, layouts } from 'chart.js'
import { drawPoint, renderText, toFont } from 'chart.js/helpers'
import { AnyObject } from 'chart.js/types/basic'
import equal from 'react-fast-compare'

import { measureTexts } from '../utils'

interface DoughnutDatalabelPluginOptions extends AnyObject {
  label: TextSpec
  value: TextSpec
  anchorLength: number
  textboxSpacing: { x: number; y: number; gap: number }
  layoutPadding: number
}

interface TextSpec extends FontSpec {
  color: string
  formatter: (label: any) => string
}

const defaultTextSpec = (chart: Chart): TextSpec => ({
  family: chart.options.font?.family || 'Arial',
  size: chart.options.font?.size || 16,
  lineHeight: chart.options.font?.lineHeight || 1,
  weight: chart.options.font?.weight || '400',
  style: chart.options.font?.style || 'normal',
  color: (chart.options.color as string) || 'black',
  formatter: (x) => `${x}`,
})

class PaddingBox implements LayoutItem {
  bottom: number = 0
  fullSize: boolean = true
  height: number = 0
  left: number = 0
  position: LayoutPosition = 'center'
  right: number = 0
  top: number = 0
  weight: number = 0
  width: number = 0
  options: any = {}

  getPadding(): ChartArea {
    return {
      bottom: this.bottom,
      height: 0,
      top: this.top,
      left: this.left,
      width: 0,
      right: this.right,
    }
  }

  draw(chartArea: ChartArea): void {}

  isHorizontal(): boolean {
    return false
  }

  update(width: number, height: number, margins?: ChartArea): void {}
}

interface DoughnutDatalabelArgs {
  ctx: CanvasRenderingContext2D
  sx: number
  sy: number
  direction: { x: 1 | -1; y: 1 | -1 }
  label: string
  value: string
  doughnut: {
    x: number
    y: number
    innerRadius: number
    outerRadius: number
  }
  options: DoughnutDatalabelPluginOptions
}

class DoughnutDatalabel {
  ctx: CanvasRenderingContext2D
  sx: number
  sy: number
  anchorLength: number
  direction: DoughnutDatalabelArgs['direction']
  doughnut: DoughnutDatalabelArgs['doughnut']

  label: string
  value: string
  textbox: {
    labelSpec: DoughnutDatalabelPluginOptions['label']
    valueSpec: DoughnutDatalabelPluginOptions['value']
    direction: DoughnutDatalabelArgs['direction']['y']
    spacing: DoughnutDatalabelPluginOptions['textboxSpacing']
    width: number
    height: number
    top: number
    bottom: number
  }

  px: number = 0
  py: number = 0
  ex: number = 0
  ey: number = 0
  mandatoryPadding = 0
  boundingBox = { left: 0, right: 0, top: 0, bottom: 0 }

  constructor({ ctx, sx, sy, direction, doughnut, label, value, options }: DoughnutDatalabelArgs) {
    this.ctx = ctx
    this.sx = sx
    this.sy = sy
    this.anchorLength = options.anchorLength
    this.direction = direction
    this.doughnut = doughnut
    this.label = label
    this.value = value
    this.textbox = {
      labelSpec: options.label,
      valueSpec: options.value,
      spacing: options.textboxSpacing,
      direction: 1,
      width: 0,
      height: 0,
      top: 0,
      bottom: 0,
    }

    this.calculate()
  }

  calculate(px?: number, py?: number) {
    this.px = px || this.sx + this.direction.x * this.anchorLength
    this.py = py || this.sy + this.direction.y * this.anchorLength

    const leftBound = this.doughnut.x - this.doughnut.outerRadius
    const rightBound = this.doughnut.x + this.doughnut.outerRadius
    const topBound = this.doughnut.y - this.doughnut.outerRadius
    this.mandatoryPadding =
      5 +
      (this.direction.x > 0 && this.px < rightBound
        ? rightBound - this.px
        : this.direction.x < 0 && this.px > leftBound
        ? this.px - leftBound
        : 0)

    this.textbox.width =
      this.textbox.spacing.x +
      Math.max(
        measureTexts(this.ctx, [this.label], [{ ...this.textbox.labelSpec }]),
        measureTexts(this.ctx, [this.value], [{ ...this.textbox.valueSpec }]),
      )
    this.textbox.height =
      2 * this.textbox.spacing.y + this.textbox.labelSpec.size + this.textbox.valueSpec.size + this.textbox.spacing.gap

    this.textbox.direction = py ? this.textbox.direction : this.py - this.textbox.height < topBound ? -1 : 1

    this.ex = this.px + this.direction.x * (this.mandatoryPadding + this.textbox.width)
    this.ey = this.py

    this.textbox.top = Math.min(this.ey - this.textbox.direction * this.textbox.height, this.py)
    this.textbox.bottom = Math.max(this.ey - this.textbox.direction * this.textbox.height, this.py)

    this.boundingBox = {
      left: this.direction.x > 0 ? this.sx : this.ex,
      right: this.direction.x < 0 ? this.sx : this.ex,
      top: Math.min(this.textbox.top, this.sy),
      bottom: Math.max(this.textbox.bottom, this.py, this.sy),
    }
  }

  draw() {
    this.ctx.save()
    this.ctx.beginPath()

    const pointerRadius = (this.doughnut.outerRadius - this.doughnut.innerRadius) / 5 / 2
    drawPoint(this.ctx, { pointStyle: 'circle', borderWidth: 0, radius: pointerRadius }, this.sx, this.sy)

    this.ctx.moveTo(this.sx, this.sy)
    this.ctx.lineTo(this.px, this.py)
    this.ctx.lineTo(this.ex, this.ey)
    this.ctx.stroke()

    const textAlign = this.direction.x > 0 ? 'right' : 'left'
    let texts = [
      { text: this.label, spec: this.textbox.labelSpec },
      {
        text: this.value,
        spec: this.textbox.valueSpec,
      },
    ]
    let textBaseline: CanvasTextBaseline = 'bottom'
    if (this.textbox.direction < 0) {
      texts = texts.reverse()
      textBaseline = 'top'
    }
    renderText(
      this.ctx,
      texts[0].text,
      this.ex,
      this.ey - this.textbox.direction * this.textbox.spacing.y,
      toFont({ ...texts[0].spec }),
      { color: texts[0].spec.color, textBaseline, textAlign },
    )
    renderText(
      this.ctx,
      texts[1].text,
      this.ex,
      this.ey - this.textbox.direction * (this.textbox.spacing.y + texts[0].spec.size + this.textbox.spacing.gap),
      toFont({ ...texts[1].spec }),
      { color: texts[1].spec.color, textBaseline, textAlign },
    )

    this.ctx.restore()
  }

  moveVertical(delta: number) {
    // console.debug('DoughnutDatalabel: moveVertical: delta=%s', delta)
    delta = Math.ceil(delta)
    this.calculate(
      this.px + this.direction.x * (Math.abs(delta) > this.mandatoryPadding ? this.mandatoryPadding : Math.abs(delta)),
      this.py + delta,
    )
  }
}

const DoughnutDatalabels = {
  autoAdjust: (chartHeight: number, datalabels: DoughnutDatalabel[]) => {
    const leftLabels = datalabels.filter((d) => d.direction.x < 0)
    const rightLabels = datalabels.filter((d) => d.direction.x > 0)
    const threshold = -1

    ;[leftLabels, rightLabels].forEach(
      (
        labels,
        // labelsIdx
      ) => {
        if (labels.length === 0) {
          return
        }

        const sortedLabels = labels.sort((d1, d2) => d1.textbox.top - d2.textbox.top)
        const aggHeight = sortedLabels.reduce((s, d) => s + d.textbox.bottom - d.textbox.top, 0)

        // if sum of label heights is greater than chart height
        if (aggHeight > chartHeight) {
          //    stack the labels
          // console.debug('stack the labels', labelsIdx)
          let start = (chartHeight - aggHeight) / 2
          for (let i = 0; i < sortedLabels.length; i++) {
            const element = sortedLabels[i]
            element.moveVertical(start - element.textbox.top)
            start = element.textbox.bottom
          }
        } else {
          //    adjust the labels
          // console.debug('adjust the labels', labelsIdx)

          const first = sortedLabels[0]
          if (first.textbox.top < 0) {
            // move the first label down
            first.moveVertical(-first.textbox.top + 1)
          }

          const last = sortedLabels[sortedLabels.length - 1]
          if (last.textbox.bottom > chartHeight) {
            // move the last label up
            last.moveVertical(chartHeight - last.textbox.bottom - 1)
          }

          let flag = true
          let iteration = 0
          while (flag && iteration < sortedLabels.length) {
            let topEmptySpace = 0
            flag = false
            iteration += 1
            for (let i = 0; i < sortedLabels.length - 1; i++) {
              const prevLabel = sortedLabels[i - 1]
              const currLabel = sortedLabels[i]
              const nextLabel = sortedLabels[i + 1]

              const topSpace = currLabel.textbox.top - (prevLabel?.textbox.bottom || 0)
              const bottomSpace = nextLabel.textbox.top - currLabel.textbox.bottom

              // console.debug('adjusting middle label: topSpace=%s bottomSpace=%s', topSpace, bottomSpace, labelsIdx)

              if (topSpace < threshold) {
                // need to move the label down
                // console.debug('adjusting middle label down', labelsIdx)
                currLabel.moveVertical(-topSpace)
                flag = true
              } else {
                topEmptySpace += topSpace
                // console.debug('adjusting middle label: topEmptySpace=%s', topEmptySpace, labelsIdx)

                if (bottomSpace < threshold && topEmptySpace > 0) {
                  // need to move the label up
                  // console.debug('adjusting middle label up', labelsIdx)
                  currLabel.moveVertical(Math.max(-topEmptySpace, bottomSpace))
                  flag = true
                }
              }
            }
          }
        }
      },
    )
  },
  create: (chart: Chart, options: DoughnutDatalabelPluginOptions, final = false) => {
    const datalabels = chart.data.datasets.flatMap((dataset, datasetIdx) => {
      const elements = chart.getDatasetMeta(datasetIdx).data as unknown as ArcElement[]

      return elements
        .map((element, dataIdx) => {
          const label = (chart.data.labels && `${chart.data.labels[dataIdx]}`) || ''
          const rawValue = chart.data.datasets[datasetIdx].data[dataIdx] as number
          const value = options.value.formatter(rawValue)

          const { x, y } = element.tooltipPosition(final)
          const doughnut = element.getProps(['x', 'y', 'innerRadius', 'outerRadius'])

          return new DoughnutDatalabel({
            ctx: chart.ctx,
            sx: x,
            sy: y,
            label: label,
            value: value,
            doughnut: doughnut,
            options: options,
            direction: {
              x: x > chart.width / 2 ? 1 : -1,
              y: y > chart.height / 2 ? 1 : -1,
            },
          })
        })
        .filter((label, idx) => dataset.data[idx] !== 0)
    })

    DoughnutDatalabels.autoAdjust(chart.height, datalabels)

    return datalabels
  },
}

const normalizeOptions = (chart: Chart, options: DoughnutDatalabelPluginOptions) => {
  options.anchorLength = options.anchorLength || 16
  options.textboxSpacing = { ...{ x: 8, y: 8, gap: 2 }, ...options.textboxSpacing }
  options.label = {
    ...{
      ...defaultTextSpec(chart),
      color: '#9A9A9A',
      size: defaultTextSpec(chart).size * 0.8,
    },
    ...options.label,
  }
  options.layoutPadding = options.layoutPadding || 4
  options.value = { ...defaultTextSpec(chart), ...options.value }
}

export const DoughnutDatalabelPlugin: Plugin<'doughnut', DoughnutDatalabelPluginOptions> = {
  id: 'doughnutDatalabel',
  start: (chart, args, options) => {
    normalizeOptions(chart, options)

    const paddingBox = new PaddingBox()
    layouts.addBox(chart, paddingBox)

    chart.$doughnutDatalabel = {
      paddingBox,
      layoutSet: false,
      options: options,
      final: false,
      datalabels: [],
      data: chart.data.datasets.slice().map((x) => x.data),
    }
  },
  stop: (chart) => {
    layouts.removeBox(chart, chart.$doughnutDatalabel.paddingBox)
  },
  beforeRender: (chart) => {
    // console.debug('DoughnutDatalabelPlugin: beforeRender')
    if (chart.$doughnutDatalabel.layoutSet) {
      return
    }

    const options = chart.$doughnutDatalabel.options
    options.layoutPadding = options.layoutPadding || 0

    const datalabels = DoughnutDatalabels.create(chart, options, true)

    if (datalabels.length > 0) {
      const { x, y, outerRadius } = datalabels[0].doughnut

      const left = Math.floor(Math.min(...datalabels.map((x) => x.boundingBox.left)))
      const right = Math.ceil(Math.max(...datalabels.map((x) => x.boundingBox.right)))

      let paddingX = 0
      if (left < 0) {
        paddingX = Math.max(paddingX, x - outerRadius - left + options.layoutPadding)
      }
      if (right > chart.width) {
        paddingX = Math.max(paddingX, right - (x + outerRadius) + options.layoutPadding)
      }
      if (paddingX > 0) {
        chart.$doughnutDatalabel.paddingBox.left = paddingX
        chart.$doughnutDatalabel.paddingBox.right = paddingX
      }

      const top = Math.floor(Math.min(...datalabels.map((x) => x.boundingBox.top)))
      const bottom = Math.ceil(Math.max(...datalabels.map((x) => x.boundingBox.bottom)))

      let paddingY = 0
      if (top < 0) {
        paddingY = Math.max(paddingY, y - outerRadius - top + options.layoutPadding)
      }
      if (bottom > chart.height) {
        paddingY = Math.max(paddingY, bottom - (y + outerRadius) + options.layoutPadding)
      }
      if (paddingY > 0) {
        chart.$doughnutDatalabel.paddingBox.top = paddingY
        chart.$doughnutDatalabel.paddingBox.bottom = paddingY
      }

      chart.$doughnutDatalabel.layoutSet = true
      chart.$doughnutDatalabel.datalabels = datalabels

      // if (paddingX > 0 || paddingY > 0) {
      chart.update()
      // }
    }
  },
  beforeDatasetsUpdate: (chart) => {
    // console.debug('DoughnutDatalabelPlugin: beforeDatasetsUpdate')
    chart.$doughnutDatalabel.final = false

    if (
      !equal(
        chart.$doughnutDatalabel.data,
        chart.data.datasets.map((x) => x.data),
      )
    ) {
      chart.$doughnutDatalabel.layoutSet = false
      // chart.$doughnutDatalabel.paddingBox.top = 0
      // chart.$doughnutDatalabel.paddingBox.bottom = 0
      // chart.$doughnutDatalabel.paddingBox.left = 0
      // chart.$doughnutDatalabel.paddingBox.right = 0
      chart.$doughnutDatalabel.data = chart.data.datasets.slice().map((x) => x.data)
    }
  },
  afterDraw: (chart, args, options) => {
    // console.debug('DoughnutDatalabelPlugin: afterDraw')
    // const datalabels = chart.$doughnutDatalabel.final
    //   ? chart.$doughnutDatalabel.datalabels
    //   : DoughnutDatalabels.create(chart, options)
    normalizeOptions(chart, options)
    const datalabels = DoughnutDatalabels.create(chart, options)
    datalabels.forEach((datalabel) => datalabel.draw())
  },
  afterRender: (chart) => {
    chart.$doughnutDatalabel.final = true
  },
}

declare module 'chart.js' {
  interface Chart {
    $doughnutDatalabel: {
      final: boolean
      paddingBox: PaddingBox
      layoutSet: boolean
      options: DoughnutDatalabelPluginOptions
      datalabels: DoughnutDatalabel[]
      data: any[]
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PluginOptionsByType<TType = 'doughnut'> {
    doughnutDatalabel: DoughnutDatalabelPluginOptions
  }
}
