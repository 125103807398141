import { useContext } from 'react'

import { useIncomeData } from '../../accounting/useStatementData'
import { StatementTable, StatementTableRow } from '../../components/table/StatementTable'
import { AccountingContext } from '../../layouts/AccountingLayout'
import { PageContext } from '../../layouts/DashboardLayout'
import { ClosingStockMethodLabels } from '../../types/company'
import { DateFormatUtils, DateFyUtils } from '../../utils/date'

import styles from './statement.module.scss'

export const Income = () => {
  const { company } = useContext(PageContext)
  let { selectedFy, lastVoucherDate } = useContext(AccountingContext)

  const { incomeHeads: headsData, incomeRatios: ratiosData } = useIncomeData({
    startDate: DateFyUtils.START_YMD(selectedFy),
    endDate: Math.min(lastVoucherDate, DateFyUtils.END_YMD(selectedFy)),
  })

  const incomeRows = [
    { data: headsData['Sales Accounts'], displayName: ['Sales'], isPositive: true },
    { data: headsData['Direct Incomes'], displayName: ['Direct Incomes'], isPositive: true },
    { data: headsData['Purchase Accounts'], displayName: ['Purchases'], isPositive: false },
    { data: headsData['Direct Expenses'], displayName: ['Direct Expenses'], isPositive: false },
    {
      data: headsData['Change in Stock'],
      displayName: ['Change in Stock', `(${ClosingStockMethodLabels[company.closingStockMethod]})`],
      isPositive: false,
    },
    { data: headsData['Gross Profit'], displayName: ['Gross Profit/Loss'], isTotalsRow: true },
    { data: headsData['Indirect Incomes'], displayName: ['Indirect Incomes'], isPositive: true },
    { data: headsData['Indirect Expenses'], displayName: ['Indirect Expenses'], isPositive: false },
    { data: headsData['Net Profit'], displayName: ['Net Profit/Loss'], isTotalsRow: true },
  ] as StatementTableRow[]

  const ratioRows = [
    { data: ratiosData['grossProfit'], displayName: ['Gross Profit Ratio'] },
    { data: ratiosData['netProfit'], displayName: ['Net Profit Ratio'] },
    { data: ratiosData['indirectExpenseToSales'], displayName: ['Indirect Expenses to Sale'] },
  ] as StatementTableRow[]

  console.log({ incomeRows, ratioRows, headsData })

  const totalColumnHeading = DateFormatUtils.FY(selectedFy)

  return (
    <div className={styles.page}>
      <StatementTable
        rows={incomeRows}
        firstColumnHeading={'Particulars'}
        showTotalColumn={true}
        totalColumnHeading={totalColumnHeading}
      />

      <StatementTable
        rows={ratioRows}
        firstColumnHeading={'Ratios'}
        showTotalColumn={true}
        totalColumnHeading={totalColumnHeading}
        allowExpansion={false}
      />
    </div>
  )
}
