import dayjs from 'dayjs'

import { AnalyticsData, Invoice, InvoiceLinkedVoucher, SlimVoucher, Voucher } from '../types/accounting'
import { groupedAggregate, monthlyAggregations, sum } from './calc'
import { DateFyUtils, DateTransformUtils } from './date'

export const calculateAnalyticsData = (vouchers: Voucher[], fy: number): AnalyticsData => {
  const slimVouchers: SlimVoucher[] = vouchers.map((x) => ({
    _id: x._id,
    date: x.date,
    name: x.master.name,
    partyName: x.partyName,
    amount: x.amount,
  }))

  const lastVoucherDate = Math.max(...vouchers.map((x) => x.date))
  const latestFY = DateFyUtils.FY(lastVoucherDate)

  const refFy = fy === latestFY ? fy - 1 : latestFY
  const previousFy = fy === latestFY ? refFy : fy

  const fyVouchers = slimVouchers.filter((x) => DateFyUtils.IN_FY(x.date, fy))
  const refFyVouchers = slimVouchers.filter((x) => DateFyUtils.IN_FY(x.date, refFy))

  const currentFyVouchers = fy === latestFY ? fyVouchers : refFyVouchers
  const previousFyVouchers = fy !== latestFY ? fyVouchers : refFyVouchers

  const ytdVouchers = currentFyVouchers.filter((x) => x.date <= lastVoucherDate)

  const refLastVoucherDate = DateTransformUtils.SET_Y(lastVoucherDate, previousFy)
  const refYtdVouchers = previousFyVouchers.filter((x) => x.date <= refLastVoucherDate)

  const data: AnalyticsData = {
    fy: fy,
    refFy: refFy,
    isLatestFY: fy === latestFY,

    fyVouchers: fyVouchers,

    aggregations: {
      fy: monthlyAggregations(fyVouchers, 'date', 'amount', {
        start: DateFyUtils.START_YMD(fy),
        end: DateFyUtils.END_YMD(fy),
      }),
      refFy: monthlyAggregations(refFyVouchers, 'date', 'amount', {
        start: DateFyUtils.START_YMD(refFy),
        end: DateFyUtils.END_YMD(refFy),
      }),

      ytd: monthlyAggregations(ytdVouchers, 'date', 'amount', {
        start: DateFyUtils.START_YMD(fy),
        end: DateTransformUtils.YMD_M(lastVoucherDate),
      }),
      refYtd: monthlyAggregations(refYtdVouchers, 'date', 'amount'),
    },

    lastVoucherDate: {
      fy: lastVoucherDate,
      refFy: refLastVoucherDate,
    },

    totals: {
      fy: NaN,
      refAmount: NaN,
    },

    grouped: {
      partyName: groupedAggregate(fyVouchers, 'partyName', 'amount', 'amount', -1).map((x) => ({
        label: `${x.partyName}`,
        value: +x.amount,
      })),
      name: groupedAggregate(fyVouchers, 'name', 'amount', 'amount', -1).map((x) => ({
        label: `${x.name}`,
        value: +x.amount,
      })),
    },
  }

  data.totals.fy = Math.round(sum(data.aggregations.fy.map((x) => x.amount)))
  data.totals.refAmount = data.isLatestFY
    ? sum(
        data.aggregations.ytd
          .filter((x) => x.yearMonth === DateTransformUtils.YMD_YM(lastVoucherDate))
          .map((x) => x.amount),
      )
    : Math.round(sum(data.aggregations.refFy.map((x) => x.amount)))

  return data
}

export const calculateInvoices = (
  ledgerVouchers: Voucher[],
  voucherTypes: string[],
  refDate = +dayjs().format('YYYYMMDD'),
) => {
  const invoices: Invoice[] = []
  const adjustVouchers: InvoiceLinkedVoucher[] = []
  const billAllocationMap: Record<string, { invoice: number; adjustVouchers: InvoiceLinkedVoucher[] }> = {}

  for (let i = 0; i < ledgerVouchers.length; i++) {
    const voucher = ledgerVouchers[i]
    let adjustedAmount = 0
    let adjustedFlag = false

    for (let j = 0; j < voucher.billAllocations.length; j++) {
      const billAllocation = voucher.billAllocations[j]
      if (billAllocation.billType === 'New Ref') {
        billAllocationMap[billAllocation.name] = {
          ...billAllocationMap[billAllocation.name],
          invoice: invoices.length,
        }
      }
      // else if (billAllocation.billType === 'Agst Ref') {
      //   billAllocationMap[billAllocation.name] = billAllocationMap[billAllocation.name] || {}
      //   billAllocationMap[billAllocation.name] = {
      //     ...billAllocationMap[billAllocation.name],
      //     adjustVouchers: [
      //       ...(billAllocationMap[billAllocation.name]?.adjustVouchers || []),
      //       {
      //         adjusted: billAllocation.amount,
      //         amount: voucher.amount,
      //         date: voucher.date,
      //         voucherNumber: voucher.voucherNumber,
      //         voucherType: voucher.voucherType,
      //       },
      //     ],
      //   }
      //   adjustedAmount += billAllocation.amount
      //   adjustedFlag = true
      // }
    }

    if (voucher.amount > 0) {
      if (adjustedFlag && Math.abs(voucher.amount - adjustedAmount) < 1e-5) {
        continue
      } else if (adjustedFlag && Math.abs(voucher.amount - adjustedAmount) > 1e-5) {
        console.warn('IMPL NOTE: Sales voucher is split between Agst Ref and New Ref')
      }

      invoices.push({
        ageing: dayjs(`${refDate}`, 'YYYYMMDD').diff(dayjs(`${voucher.date}`, 'YYYYMMDD'), 'days'),
        amount: voucher.amount,
        date: voucher.date,
        outstanding: voucher.amount - adjustedAmount,
        voucherType: voucher.voucherType,
        voucherNumber: voucher.voucherNumber,
        linkedVouchers: [],
      })
    } else {
      if (Math.abs(voucher.amount - adjustedAmount) > 1e-5) {
        adjustVouchers.push({
          adjusted: voucher.amount - adjustedAmount,
          amount: voucher.amount,
          date: voucher.date,
          voucherNumber: voucher.voucherNumber,
          voucherType: voucher.voucherType,
          ledgerName: voucher.particulars?.find((x) => Math.sign(x.amount) !== Math.sign(voucher.amount))
            ?.ledgerName as string,
        })
      }
    }
  }

  const billAllocations = Object.entries(billAllocationMap)
  for (let i = 0; i < billAllocations.length; i++) {
    const billAllocation = billAllocations[i][1]

    if (billAllocation.invoice !== undefined && billAllocation.adjustVouchers?.length > 0) {
      const invoice = invoices[billAllocation.invoice]

      if (invoice) {
        invoice.linkedVouchers = billAllocation.adjustVouchers
        invoice.outstanding += sum(billAllocation.adjustVouchers.map((x) => x.adjusted))
      }
    }
  }

  if (invoices.length === 0) {
    invoices.push({
      date: 0,
      amount: 0,
      outstanding: 0,
      voucherType: '',
      voucherNumber: '',
      linkedVouchers: [],
      ageing: NaN,
    })
  }

  for (let i = 0; i < invoices.length - 1; i++) {
    const invoice = invoices[i]

    while (invoice.outstanding >= 1e-5 && adjustVouchers.length > 0) {
      const adjustVoucher = adjustVouchers.shift()

      if (adjustVoucher) {
        let adjustAmount = adjustVoucher.adjusted

        if (invoice.outstanding + adjustAmount < 1e-5) {
          adjustAmount = -invoice.outstanding
          adjustVouchers.unshift({ ...adjustVoucher, adjusted: adjustVoucher.adjusted + invoice.outstanding })
        }

        invoice.linkedVouchers.push({ ...adjustVoucher, adjusted: adjustAmount })
        invoice.outstanding += adjustAmount
      }
    }
  }

  while (adjustVouchers.length > 0) {
    const adjustVoucher = adjustVouchers.shift()
    const invoice = invoices[invoices.length - 1]

    if (invoice && adjustVoucher) {
      invoice.linkedVouchers.push(adjustVoucher)
      invoice.outstanding += adjustVoucher.adjusted
    }
  }

  return {
    invoices,
    adjustVouchers,
  }
}
