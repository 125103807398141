import { RefObject, useContext } from 'react'
import { ChartProps } from 'react-chartjs-2'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

import { SettingsContext } from '../../../app/Settings'
import { UseBarChartProps, useBarChart } from './useBarChart'
import { DefaultScaleOptions } from './useChart'
import { useLegend } from './useLegend'
import { LineBackgroundGradient, relativeAxisLimits } from './utils'

export interface UseBarLineChartProps<TData = any, TLabel = string>
  extends Pick<UseBarChartProps<TData, TLabel>, 'chartRef' | 'visibleCount' | 'showPanLimits'> {
  chartRef: RefObject<ChartJSOrUndefined<'bar' | 'line', TData, TLabel>>
  labels: string[]
  bar1: {
    title: string
    data: number[]
    color: string
  }
  bar2: {
    title: string
    data: number[]
    color: string
  }
  line?: {
    title: string
    data: number[]
    axis: 'left' | 'right'
    positiveColor: string
    negativeColor?: string
    fill?: boolean
    formatter?: () => string
  }
  defaultActive?: ('bar1' | 'bar2' | 'line')[]
}

export const useBarLineChart = ({
  chartRef,
  labels,
  bar1,
  bar2,
  line,
  defaultActive,
  ...barChartProps
}: UseBarLineChartProps) => {
  // console.debug('useBarLineChart')

  type TType = 'bar' | 'line'
  type TData = any
  type TLabel = string

  const { currency } = useContext(SettingsContext)

  const props: ChartProps<TType, TData, TLabel> = {
    type: 'bar',
    data: {
      labels: labels,
      datasets: [
        {
          label: bar1.title,
          data: bar1.data,
          backgroundColor: bar1.color,
          datalabels: {
            backgroundColor: bar1.color,
          },
        },
        {
          label: bar2.title,
          data: bar2.data,
          backgroundColor: bar2.color,
          datalabels: {
            backgroundColor: bar2.color,
          },
        },
        ...(line
          ? [
              {
                label: line.title,
                data: line.data,
                type: 'line' as 'line',
                yAxisID: line.axis === 'left' ? 'y' : 'y2',
                order: -1,
                borderColor: line.positiveColor,
                pointBorderColor: line.data.map((x) =>
                  x >= 0 ? line.positiveColor : line.negativeColor || line.positiveColor,
                ),
                pointBackgroundColor: '#FFFFFF',
                fill: line.fill ? 'start' : undefined,
                backgroundColor: line.fill ? LineBackgroundGradient : undefined,
                datalabels: {
                  backgroundColor: line.data.map((x) =>
                    x > 0 ? line.positiveColor : line.negativeColor || line.positiveColor,
                  ),
                  formatter: line.formatter,
                },
              },
            ]
          : []),
      ],
    },
    options: {
      layout: { padding: { right: -8 } },
      scales: {
        y: {
          afterDataLimits: relativeAxisLimits(1, 1.05),
          ticks: {
            ...DefaultScaleOptions.ticks,
            maxTicksLimit: 8,
            format: { style: 'currency', currency, maximumFractionDigits: 0 },
          },
        },
        ...(line?.axis === 'right' && {
          y2: {
            ...DefaultScaleOptions,
            position: 'right',
            afterDataLimits: relativeAxisLimits(1, 2.1),
            ticks: {
              ...DefaultScaleOptions.ticks,
              format: { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 2 },
            },
          },
        }),
      },
      elements: {},
      interaction: {
        mode: 'index',
      },
      plugins: {
        verticalLine: { enabled: !!line, borderColor: line && line.positiveColor },
        zoom: {
          pan: { enabled: true, mode: 'x' },
        },
        tooltip: {
          intersect: false,
          callbacks: {
            // label: (tooltipItem: TooltipItem<TType>): string => `${tooltipItem.parsed.y}`,
          },
        },
      },
    },
  }

  const { chartProps } = useBarChart<TData, TLabel>({ chartRef, props, ...barChartProps })

  const Legend = useLegend({
    chartRef,
    chartProps,
    showDatalabels: true,
    showActiveDatasets: true,
    defaultActiveDatasets: defaultActive?.map((x) => (x === 'bar1' ? 0 : x === 'bar2' ? 1 : x === 'line' ? 2 : NaN)),
  })

  return { chartProps, Legend }
}
