import React from 'react'
import { Dropdown as BsDropdown, DropdownProps } from 'react-bootstrap'

import { DropdownToggle } from './DropdownToggle'

const Dropdown = React.forwardRef<HTMLElement, DropdownProps>(({ children, ...props }, ref) => {
  return (
    <BsDropdown ref={ref} {...props}>
      {children}
    </BsDropdown>
  )
})

export default Object.assign(Dropdown, {
  Toggle: DropdownToggle,
  Menu: BsDropdown.Menu,
  Item: BsDropdown.Item,
  ItemText: BsDropdown.ItemText,
  Divider: BsDropdown.Divider,
  Header: BsDropdown.Header,
})
