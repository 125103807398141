import classNames from 'classnames'
import { Fragment, useContext, useRef } from 'react'
import { Card } from 'react-bootstrap'

import { useCurrencyFormat } from '../../hooks/useCurrencyFormat'
import { AccountingContext } from '../../layouts/AccountingLayout'
import { Voucher } from '../../types/accounting'
import { calculateInvoices } from '../../utils/analytics'
import { isNearZero, monthlyAggregations, sum } from '../../utils/calc'
import { DateFormatUtils, DateFyUtils } from '../../utils/date'
import { ChartComponent } from '../common/chart/ChartComponent'
import { useBarLineChart } from '../common/chart/useBarLineChart'
import { useDoughnutChart } from '../common/chart/useDoughnutChart'
import { InvoiceTable } from './InvoiceTable'

import styles from './invoice-tracker.module.scss'

export interface InvoiceTrackerProps {
  vouchers: Voucher[]
  voucherTypes: string[]
  chartVoucherTypes: [string, string]
  text: {
    section2: string
    section3?: string
    legendData1: string
    legendData2: string
  }
}

export const InvoiceTracker = ({ vouchers, voucherTypes, chartVoucherTypes, text }: InvoiceTrackerProps) => {
  const { selectedFy, lastVoucherDate } = useContext(AccountingContext)

  const { formatValueWithCurrency } = useCurrencyFormat()

  const ledgerInvoices = Object.entries(
    vouchers.reduce(
      (a, x) => ({ ...a, [x.master.name]: [...(a[x.master.name] || []), x] }),
      {} as Record<string, Voucher[]>,
    ),
  )
    .flatMap(([ledgerName, ledgerVouchers]) => {
      const { invoices } = calculateInvoices(
        ledgerVouchers.sort((a, b) => a.date - b.date),
        voucherTypes,
        lastVoucherDate,
      )

      return invoices.map((x) => ({ ...x, ledgerName })).filter((x) => x.date)
    })
    .map((invoice) => ({ ...invoice, payment: invoice.amount - invoice.outstanding }))

  // console.debug({ ledgerInvoices })

  const fyStartDate = DateFyUtils.START_YMD(selectedFy)
  const fyLedgerInvoices = ledgerInvoices.filter((x) => DateFyUtils.IN_FY(x.date, selectedFy))
  const previousInvoices = ledgerInvoices.filter((x) => x.date < fyStartDate && !isNearZero(x.outstanding))

  const monthlyData = {
    data1: monthlyAggregations(
      vouchers.filter((x) => x.voucherType === chartVoucherTypes[0] && DateFyUtils.IN_FY(x.date, selectedFy)),
      'date',
      'amount',
      { start: fyStartDate, end: lastVoucherDate },
    ),
    data2: monthlyAggregations(
      vouchers
        .filter((x) => x.voucherType === chartVoucherTypes[1] && DateFyUtils.IN_FY(x.date, selectedFy))
        .map((x) => ({ ...x, amount: -x.amount })),
      'date',
      'amount',
      { start: fyStartDate, end: lastVoucherDate },
    ),
  }

  const doughnutChartRef = useRef()
  const { chartProps: doughnutChartProps } = useDoughnutChart({
    labels: [text.legendData1, text.legendData2],
    values: [sum(monthlyData.data1.map((x) => x.amount)), sum(monthlyData.data2.map((x) => x.amount))],
    colors: ['#6896DA', '#F0AE4D'],
    showDatalabels: true,
    valueFormatter: formatValueWithCurrency,
  })

  const months = DateFyUtils.LIST_YM(selectedFy).map((x) => DateFormatUtils.YM(x))

  const monthlyChartRef = useRef()
  const { chartProps: monthlyChartProps, Legend } = useBarLineChart({
    chartRef: monthlyChartRef,
    labels: months,
    bar1: {
      title: text.legendData1,
      data: monthlyData.data1.map((x) => x.amount),
      color: '#6896DA',
    },
    bar2: {
      title: text.legendData2,
      data: monthlyData.data2.map((x) => x.amount),
      color: '#F0AE4D',
    },
  })

  return (
    <Fragment>
      <div className={styles.top}>
        <span className={styles.doughnut}>
          <h2 className={styles.title}>Paid v/s Unpaid Invoices</h2>
          <Card className={styles.card}>
            <span className={styles.chart}>
              <ChartComponent chartRef={doughnutChartRef} props={doughnutChartProps} />
            </span>
          </Card>
        </span>
        <span className={styles.bar}>
          <span className={classNames(styles.title, styles.legend)}>
            <h2>Monthly Payment Tracker</h2>
            <Legend />
          </span>
          <Card className={styles.chart}>
            <ChartComponent chartRef={monthlyChartRef} props={monthlyChartProps} />
          </Card>
        </span>
      </div>

      <h2 className={styles.title}>{text.section2}</h2>
      <InvoiceTable ledgerInvoices={fyLedgerInvoices} />

      {previousInvoices.length > 0 && (
        <Fragment>
          <h2 className={styles.title}>{text.section3}</h2>
          <InvoiceTable ledgerInvoices={previousInvoices} />
        </Fragment>
      )}
    </Fragment>
  )
}
