import axios from 'axios'
import { nanoid } from 'nanoid'

import { bufferToBase64UrlEncoded, createRandomString, sha256 } from '../utils/crypt'

const STORAGE_KEY_CODE_VERIFIER = 'code_verifier'
const STORAGE_KEY_CODE_CHALLENGE = 'code_challenge'

export interface AuthClientOpts {
  baseUrl: string
  googleClientId: string
}

export interface GoogleSignInConfig {
  clientId: string
  redirectUri: string
  codeVerifier: string
  codeChallenge: string
}

export interface GoogleSignInRedirectOpts {
  codeChallenge: string
}

export interface EmailCredentials {
  email: string
  password: string
}

interface Token {
  id_token: string
  access_token: string
}

export class AuthClient {
  public readonly id: string
  private readonly baseUrl: string
  private readonly googleClientId: string

  private _accessToken?: string
  private _idToken?: string

  constructor({ baseUrl, googleClientId }: AuthClientOpts) {
    this.id = nanoid()
    this.baseUrl = baseUrl
    this.googleClientId = googleClientId
  }

  get isAuthenticated(): boolean {
    return !!this._accessToken
  }

  get accessToken(): string | undefined {
    return this._accessToken
  }

  get idToken(): string | undefined {
    return this._idToken
  }

  private setToken(token: Token) {
    this._accessToken = token.access_token
    this._idToken = token.id_token
  }

  async refreshToken() {
    const { data } = await axios.post(`${this.baseUrl}/auth/token/refresh`, null, { withCredentials: true })
    this.setToken(data)
  }

  async initiateGoogleSignIn(): Promise<GoogleSignInConfig> {
    const codeVerifier = createRandomString()
    const codeChallengeBuffer = await sha256(codeVerifier)
    const codeChallenge = bufferToBase64UrlEncoded(codeChallengeBuffer)

    sessionStorage.setItem(STORAGE_KEY_CODE_VERIFIER, codeVerifier)
    sessionStorage.setItem(STORAGE_KEY_CODE_CHALLENGE, codeChallenge)

    return {
      codeVerifier,
      codeChallenge,
      clientId: this.googleClientId,
      redirectUri: `${this.baseUrl}/auth/google-sign-in/redirect`,
    }
  }

  async handleGoogleSignInRedirect({ codeChallenge }: GoogleSignInRedirectOpts) {
    const codeVerifier = sessionStorage.getItem(STORAGE_KEY_CODE_VERIFIER)
    const cachedCodeChallenge = sessionStorage.getItem(STORAGE_KEY_CODE_CHALLENGE)

    if (cachedCodeChallenge !== codeChallenge || !codeVerifier) {
      throw new Error('Login session has expired. Try again.')
    }

    const { data } = await axios.post(
      `${this.baseUrl}/auth/google-sign-in/token`,
      {
        code_verifier: codeVerifier,
        code_challenge: codeChallenge,
      },
      { withCredentials: true },
    )

    this.setToken(data)

    sessionStorage.removeItem(STORAGE_KEY_CODE_VERIFIER)
    sessionStorage.removeItem(STORAGE_KEY_CODE_CHALLENGE)
  }

  async loginWithEmailPassword(credentials: EmailCredentials) {
    const { data } = await axios.post(`${this.baseUrl}/auth/email/login`, credentials, { withCredentials: true })
    this.setToken(data)
  }

  async logout() {
    await axios.post(`${this.baseUrl}/auth/token/revoke`, null, { withCredentials: true })
    this._accessToken = undefined
    this._idToken = undefined
  }
}
