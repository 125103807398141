import { memo, useContext, useRef } from 'react'

import { SettingsContext } from '../../app/Settings'
import { MonthlyAggregate } from '../../types/accounting'
import { DateFormatUtils } from '../../utils/date'
import { ChartComponent } from '../common/chart/ChartComponent'
import { useBarChart } from '../common/chart/useBarChart'
import { relativeAxisLimits } from '../common/chart/utils'

import styles from './monthly-chart.module.scss'

export interface MonthlyChartProps {
  data: MonthlyAggregate[]
  positiveOnly?: boolean
  formatOptions?: Intl.NumberFormatOptions
}

export const MonthlyChart = memo(({ data, positiveOnly = true, formatOptions }: MonthlyChartProps) => {
  // console.debug('rendering MonthlyChart')
  const chartRef = useRef()
  const { currency } = useContext(SettingsContext)

  formatOptions = formatOptions || { style: 'currency', currency, maximumFractionDigits: 0 }

  const { chartProps } = useBarChart({
    chartRef: chartRef,
    props: {
      type: 'bar',
      data: {
        labels: data.map((x) => DateFormatUtils.YM(x.yearMonth, { spaceStyle: 'space', monthStyle: 'short' })),
        datasets: [{ data: data.map((x) => x.amount) }],
      },
      options: {
        scales: {
          y: {
            afterDataLimits: positiveOnly ? relativeAxisLimits(0, 1.05) : relativeAxisLimits(0.05, 1.05),
            ticks: {
              maxTicksLimit: 8,
              format: formatOptions,
            },
          },
        },
        interaction: {
          mode: 'index',
        },
        plugins: {
          tooltip: {
            intersect: false,
          },
        },
      },
    },
  })

  return (
    <div className={styles.monthlyChart}>
      <ChartComponent chartRef={chartRef} props={chartProps} />
    </div>
  )
})
