import { useContext } from 'react'
import { Card } from 'react-bootstrap'
import { Navigate, useLocation } from 'react-router-dom'
import { CellProps, UseTableOptions } from 'react-table'

import { StatementData, StatementHead, useBalanceSheetData, useIncomeData } from '../../../accounting/useStatementData'
import { MonthlyChart } from '../../../components/analytics/MonthlyChart'
import { Amount } from '../../../components/number/Amount'
import { Table } from '../../../components/table/Table'
import { useNumberFormat } from '../../../hooks/useNumberFormat'
import { AccountingContext } from '../../../layouts/AccountingLayout'
import { MonthlyAggregate } from '../../../types/accounting'
import { DateFormatUtils, DateFyUtils, DateTransformUtils } from '../../../utils/date'
import ratioList from './ratios.json'

import styles from './ratio-details.module.scss'

export const RatioDetails = () => {
  const location = useLocation()

  const ratioName = location.pathname.split('/').slice(-1)[0]
  const ratio = ratioList.find((x) => x.name === ratioName)

  const { selectedFy, lastVoucherDate } = useContext(AccountingContext)

  const startDate = DateFyUtils.START_YMD(selectedFy)
  const endDate = DateFyUtils.END_YMD(selectedFy)
  const lastMonth = DateTransformUtils.YMD_YM(lastVoucherDate)

  const { incomeRatios } = useIncomeData({ startDate: startDate, endDate: endDate })
  const { balanceSheetRatios } = useBalanceSheetData({ startDate: startDate, endDate: endDate })
  const ratiosData = { ...incomeRatios, ...balanceSheetRatios } as Record<StatementHead, StatementData>

  const startMonthString = DateFormatUtils.YM(DateTransformUtils.YMD_YM(startDate), {
    monthStyle: 'short',
    spaceStyle: 'dash',
  })
  const endMonthString = DateFormatUtils.YM(DateTransformUtils.YMD_YM(endDate), {
    monthStyle: 'short',
    spaceStyle: 'dash',
  })

  const ratioData = ratio?.name ? ratiosData[ratio.name as StatementHead] : ({} as StatementData)
  // console.debug({ ratioData })

  const { format } = useNumberFormat({ minimumFractionDigits: 2, maximumFractionDigits: 2 })

  const tableProps: UseTableOptions<typeof ratioData.monthly[number]> = {
    data: ratioData.monthly,
    columns: [
      {
        accessor: 'yearMonth',
        Header: 'Month',
        Cell: (props) => DateFormatUtils.YM(props.value, { spaceStyle: 'dash', monthStyle: 'short' }),
      },
      {
        id: 'numerator',
        accessor: (originalRow, index) => ratioData?.children && ratioData?.children[0]?.monthly[index].amount,
        Header:
          ratioData?.children &&
          ratioData.children[0]?.children
            ?.map((y) => y?.name)
            .filter((x) => !!x)
            .join(' + '),
        Cell: (props: CellProps<MonthlyAggregate, number>) =>
          props.row.values.yearMonth < lastMonth ? <Amount>{props.value}</Amount> : '',
      },
      {
        id: 'denominator',
        accessor: (originalRow, index) => ratioData?.children && ratioData?.children[1]?.monthly[index].amount,
        Header:
          ratioData?.children &&
          ratioData.children[1]?.children
            ?.map((y) => y?.name)
            .filter((x) => !!x)
            .join(' + '),
        Cell: (props: CellProps<MonthlyAggregate, number>) =>
          props.row.values.yearMonth < lastMonth ? <Amount>{props.value}</Amount> : '',
      },
      {
        accessor: 'amount',
        Header: ratio?.title,
        Cell: (props) =>
          props.row.values.yearMonth < lastMonth
            ? props.row.values.denominator !== 0
              ? format(props.value)
              : '-'
            : '',
      },
    ],
  }

  return ratio === undefined ? (
    <Navigate to={'/statement/ratio'} replace={true} />
  ) : (
    <div className={styles.page}>
      <Card className={styles.descCard}>
        <Card.Body>
          <Card.Title>What is {ratio.title}?</Card.Title>
          <Card.Text>{ratio.longDesc}</Card.Text>
        </Card.Body>
      </Card>
      <div className={styles.metrics}>
        <Card className={styles.total}>
          <Card.Body>
            <Card.Title>
              {startMonthString} to {endMonthString}
            </Card.Title>
            <Card.Text>{format(ratioData.total)}</Card.Text>
          </Card.Body>
        </Card>
        <Card className={styles.graph}>
          <MonthlyChart
            data={ratioData.monthly}
            formatOptions={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
            positiveOnly={false}
          />
        </Card>
      </div>
      <Table tableProps={tableProps} variant={'striped'} />
    </div>
  )
}
