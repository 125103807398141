import { FontSpec, TooltipItem } from 'chart.js'
import { ChartProps } from 'react-chartjs-2'

import { useNumberFormat } from '../../../hooks/useNumberFormat'
import { sum } from '../../../utils/calc'
import { DoughnutDatalabelPlugin } from './plugins/DoughnutDataLabel'
import { DoughnutLabelPlugin } from './plugins/DoughnutLabel'
import { useChart } from './useChart'
import { DefaultValueFormatter, ValueFormatter } from './utils'

interface TextSpec extends Partial<FontSpec> {
  text: string
  color: string
}

export interface UseDoughnutChartProps {
  labels: string[]
  values: number[]
  colors: string[]
  showDatalabels?: boolean
  centerLabels?: TextSpec[]
  valueFormatter?: ValueFormatter
}

export const useDoughnutChart = ({
  labels,
  values,
  colors,
  centerLabels = [],
  showDatalabels = false,
  valueFormatter = DefaultValueFormatter,
}: UseDoughnutChartProps) => {
  type TType = 'doughnut'

  const percentFormatter = useNumberFormat({ style: 'percent' })

  // console.debug('useGaugeChart: chartProps')
  const total = sum(values)
  const props: ChartProps<TType, number[], string> = {
    type: 'doughnut',
    data: {
      labels: labels,
      datasets: [
        {
          data: values,
          backgroundColor: colors,
        },
      ],
    },
    plugins: [DoughnutLabelPlugin, ...((showDatalabels && [DoughnutDatalabelPlugin]) || [])],
    options: {
      cutout: '80%',
      rotation: -135,
      layout: { padding: 0 },
      scales: {
        x: { display: false },
        y: { display: false },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: !showDatalabels,
          callbacks: {
            label: (tooltipItem: TooltipItem<TType>): string =>
              `${valueFormatter(tooltipItem.parsed)} (${percentFormatter.format(tooltipItem.parsed / total)})`,
            beforeLabel: (tooltipItem: TooltipItem<TType>): string => tooltipItem.label.trim(),
          },
        },
        doughnutLabel: {
          labels: centerLabels,
        },
        doughnutDatalabel: {
          label: { color: '#9A9A9A', size: 11 },
          value: { color: '#303030', formatter: valueFormatter },
        },
      },
    },
  }

  return { ...useChart({ props }) }
}
