import React, { Fragment, createContext, useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { Outlet, matchPath, useLocation } from 'react-router-dom'

import { NavBreadcrumbs } from '../AppRouter'
import { AuthContext } from '../auth/AuthContext'
import { Loader } from '../components/loader/Loader'
import { Topbar } from '../components/topbar/Topbar'
import { useApiClient } from '../hooks/useApiClient'
import { InventoryEntry, Master, Voucher } from '../types/accounting'
import { bufferToBase64UrlEncoded, sha256 } from '../utils/crypt'
import { DateFyUtils } from '../utils/date'
import { PageContext } from './DashboardLayout'

import styles from './dashboard-layout.module.scss'

interface AccountingContextInterface {
  vouchers: Voucher[]
  masters: Master[]
  inventoryEntries: InventoryEntry[]
  selectedFy: number
  firstVoucherDate: number
  lastVoucherDate: number
  isLatestFy: boolean
}

export const AccountingContext = createContext<AccountingContextInterface>({} as any)

export const AccountingLayout = () => {
  // console.debug('AccountingLayout: render')

  const location = useLocation()

  const { client: authClient } = useContext(AuthContext)
  const apiClient = useApiClient()

  const { company } = useContext(PageContext)

  const { isLoading, data, error } = useQuery(
    ['accounting', company._id],
    async () => {
      const [{ data: transactions }, { data: masters }, { data: inventoryEntries }] = await Promise.all([
        apiClient.get(`/companies/${company._id}/vouchers/transactions`),
        apiClient.get(`/companies/${company._id}/masters`),
        apiClient.get(`/companies/${company._id}/vouchers/inventory-entries`),
      ])

      const digestBuffer = await sha256(JSON.stringify(transactions))
      const digest = bufferToBase64UrlEncoded(digestBuffer)

      const dates = transactions.map((x: any) => x.date)
      const firstVoucherDate = Math.min(...dates)
      const lastVoucherDate = Math.max(...dates)
      const startYear = DateFyUtils.FY(firstVoucherDate)
      const endYear = DateFyUtils.FY(lastVoucherDate)

      const particularsMapping = Object.entries(
        (transactions as Voucher[]).reduce(
          (o, x) => ({
            ...o,
            [x.voucherGuid]: [...(o[x.voucherGuid] || []), { ledgerName: x.master.name, amount: x.amount }],
          }),
          {} as Record<string, { ledgerName: string; amount: number }[]>,
        ),
      )
        .map(([voucherGuid, particulars]) => ({
          voucherGuid: voucherGuid,
          particulars: particulars.sort((a, b) => a.amount - b.amount),
        }))
        .reduce(
          (o, x) => ({ ...o, [x.voucherGuid]: x.particulars }),
          {} as Record<string, { ledgerName: string; amount: number }[]>,
        )

      ;(transactions as Voucher[]).forEach((x) => {
        x.particulars = particularsMapping[x.voucherGuid]
      })

      setSelectedFy(endYear)
      return {
        vouchers: transactions,
        masters,
        inventoryEntries,
        digest,
        startYear,
        endYear,
        firstVoucherDate,
        lastVoucherDate,
      }
    },
    {
      enabled: !!company && authClient.isAuthenticated,
      cacheTime: 3600,
      staleTime: 3600,
      refetchOnMount: false,
    },
  )

  const [selectedFy, setSelectedFy] = useState<number>(data?.endYear as number)

  if (error) {
    throw error
  }

  const navBreadcrumb = NavBreadcrumbs.filter((x) => matchPath(x.path, location.pathname))[0]?.breadcrumb

  const handlePeriodSelect = (year: number) => {
    // console.debug('setting selected fy: %o', year)
    setSelectedFy(year)
  }

  return isLoading ? (
    <Loader position={'relative'} />
  ) : (
    <Fragment>
      <Topbar
        breadcrumb={<Topbar.Breadcrumb items={navBreadcrumb} />}
        lastSyncTime={<Topbar.LastSyncTime time={company?.lastSyncDateTime} />}
        periodSelector={
          <Topbar.PeriodSelector
            startYear={data?.startYear}
            endYear={data?.endYear}
            selected={data?.endYear}
            handleSelect={handlePeriodSelect}
          />
        }
      />
      <div className={styles.page}>
        <div className={styles.content}>
          <AccountingContext.Provider
            value={
              {
                vouchers: data?.vouchers,
                masters: data?.masters,
                inventoryEntries: data?.inventoryEntries,
                selectedFy: selectedFy,
                isLatestFy: DateFyUtils.FY(data?.lastVoucherDate) === selectedFy,
                firstVoucherDate: data?.firstVoucherDate,
                lastVoucherDate: data?.lastVoucherDate,
              } as AccountingContextInterface
            }
          >
            <Outlet />
          </AccountingContext.Provider>
        </div>
      </div>
    </Fragment>
  )
}
