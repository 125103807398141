import React, { ElementType, useEffect, useState } from 'react'

import { CurrencyFormatOptions, useCurrencyFormat } from '../../hooks/useCurrencyFormat'

import styles from './amount.module.scss'

export interface AmountProps {
  options?: CurrencyFormatOptions
  children: number
  as?: ElementType
}

export const Amount = ({ options, children, as = 'p' }: AmountProps) => {
  const ref = React.createRef<HTMLDivElement>()

  const [maxFractionDigits, setMaxFractionDigits] = useState(options?.maximumFractionDigits)

  const { formatValueWithCurrency } = useCurrencyFormat(options)
  const { formatValueWithCurrency: compactFormat } = useCurrencyFormat({
    ...options,
    notation: 'compact',
    maximumFractionDigits: maxFractionDigits,
  })

  useEffect(() => {
    // console.debug('Amount: useEffect: resetting maxFractionDigits')
    setMaxFractionDigits(options?.maximumFractionDigits)
  }, [children, options?.maximumFractionDigits])

  useEffect(() => {
    const e = ref.current
    if (e && e.scrollWidth > e.clientWidth) {
      // console.debug('Amount: useEffect', e.scrollWidth > e.clientWidth, e.textContent, maxFractionDigits)
      e.textContent = compactFormat(children)

      setTimeout(() => {
        if (e.scrollWidth > e.clientWidth && (maxFractionDigits || 0) > 0) {
          // console.debug('Amount: useEffect: reducing maxFractionDigits')
          setMaxFractionDigits((v) => (v ? v - 1 : 0))
        }
      }, 50)
    }
  }, [children, compactFormat, maxFractionDigits, ref])

  const Component = as

  return (
    <Component className={styles.amount} ref={ref}>
      {formatValueWithCurrency(children)}
    </Component>
  )
}
