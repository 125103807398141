import { ReactComponent } from '*.svg'
import { ReactElement } from 'react'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'

import { ReactComponent as AccountingIcon } from './assets/icons/accounting.svg'
import { ReactComponent as DashboardIcon } from './assets/icons/dashboard.svg'
import { ReactComponent as PayCollectIcon } from './assets/icons/pay-collect.svg'
import { ReactComponent as PercentIcon } from './assets/icons/percent.svg'
import { ReactComponent as StatementIcon } from './assets/icons/statement.svg'
import { AccountingLayout } from './layouts/AccountingLayout'
import { DashboardLayout } from './layouts/DashboardLayout'
import { PublicLayout } from './layouts/PublicLayout'
import { CashFlow } from './pages/analytics/CashFlow'
import { Expenses } from './pages/analytics/Expenses'
import { Purchases } from './pages/analytics/Purchases'
import { Revenue } from './pages/analytics/Revenue'
import { LoginPage } from './pages/login/LoginPage'
import { PayableAgeing } from './pages/payable/PayableAgeing'
import { PayableInvoices } from './pages/payable/PayableInvoices'
import { ReceivableAgeing } from './pages/receivable/ReceivableAgeing'
import { ReceivableInvoices } from './pages/receivable/ReceivableInvoices'
import { Ledgers } from './pages/reports/Ledgers'
import { Settings } from './pages/settings/Settings'
import { BalanceSheet } from './pages/statement/BalanceSheet'
import { Income } from './pages/statement/Income'
import { RatioDetails } from './pages/statement/ratio/RatioDetails'
import { RatiosList } from './pages/statement/ratio/RatiosList'
import { Summary } from './pages/summary/Summary'

export interface NavRoute extends RouteObject {
  title?: string
  icon?: ReactElement<typeof ReactComponent>
  children?: NavRoute[]
}

export const NavRoutes: NavRoute[] = [
  {
    element: <AccountingLayout />,
    children: [
      { path: 'summary', title: 'Summary', icon: <DashboardIcon />, element: <Summary />, index: true },
      {
        path: 'analytics',
        title: 'Analytics',
        icon: <AccountingIcon />,
        children: [
          { path: 'revenue', title: 'Revenue', element: <Revenue />, index: true },
          { path: 'purchases', title: 'Purchases', element: <Purchases /> },
          { path: 'expenses', title: 'Expenses', element: <Expenses /> },
          { path: 'liquidity', title: 'Cash & Bank', element: <CashFlow /> },
        ],
      },
      {
        path: 'receivable',
        title: 'Receivable',
        icon: <PayCollectIcon />,
        children: [
          { path: 'ageing', title: 'Ageing', element: <ReceivableAgeing />, index: true },
          { path: 'invoices', title: 'Invoice Tracker', element: <ReceivableInvoices /> },
        ],
      },
      {
        path: 'payable',
        title: 'Payable',
        icon: <PayCollectIcon />,
        children: [
          { path: 'ageing', title: 'Ageing', element: <PayableAgeing />, index: true },
          { path: 'invoices', title: 'Invoice Tracker', element: <PayableInvoices /> },
        ],
      },
      {
        path: 'statement',
        title: 'Financial Statement',
        icon: <StatementIcon />,
        children: [
          { path: 'income', title: 'Income', element: <Income />, index: true },
          { path: 'balance', title: 'Balance Sheet', element: <BalanceSheet /> },
          {
            path: 'ratio',
            title: 'Ratios',
            children: [
              { path: '', element: <RatiosList /> },
              { path: '*', element: <RatioDetails /> },
            ],
          },
        ],
      },
      {
        path: 'reports',
        title: 'Reports',
        icon: <StatementIcon />,
        children: [
          { path: 'ledgers', title: 'Ledgers', element: <Ledgers />, index: true },
          { path: 'vouchers', title: 'Vouchers' },
          { path: 'other', title: 'Other Reports' },
        ],
      },
    ],
  },
  { path: 'gst', title: 'GST', icon: <PercentIcon /> },
]

export const FlatNavRoutes = (function flattenNavRoutes(routes: NavRoute[]): NavRoute[] {
  const flattenRoutes = []
  for (const route of routes) {
    if (route.path) {
      flattenRoutes.push(route)
    } else if (route.children) {
      flattenRoutes.push(...flattenNavRoutes(route.children))
    }
  }
  return flattenRoutes
})(NavRoutes)

interface NavBreadcrumb {
  path: string
  breadcrumb: string[]
}

export const NavBreadcrumbs = (function calculateNavRouteMapping(
  routes,
  parentMapping: NavBreadcrumb,
): NavBreadcrumb[] {
  const mappings = []
  for (const route of routes) {
    const mapping = { ...parentMapping }
    if (route.title) {
      mapping.breadcrumb = [...(mapping.breadcrumb || []), route.title]
    }
    if (route.path) {
      mapping.path = (mapping.path || '') + '/' + route.path
      mappings.push(mapping)
    }
    if (route.children) {
      mappings.push(...calculateNavRouteMapping(route.children, mapping))
    }
  }
  return mappings
})(NavRoutes, {} as NavBreadcrumb)

export const AppRouter = () =>
  useRoutes([
    { element: <PublicLayout />, children: [{ path: 'login', element: <LoginPage /> }] },
    {
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Navigate to={FlatNavRoutes[0].path as string} /> },
        { path: '/settings', element: <Settings /> },
        ...NavRoutes,
      ],
    },
  ])
