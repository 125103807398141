import React, { createContext, useContext, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { FlatNavRoutes } from '../AppRouter'
import { AuthContext } from '../auth/AuthContext'
import { Loader } from '../components/loader/Loader'
import { Sidebar } from '../components/sidebar/Sidebar'
import { CompanySelector } from '../components/sidebar/company-selector/CompanySelector'
import { UserProfile } from '../components/sidebar/user-profile/UserProfile'
import { SESSION_STORAGE_RESTORE_PATH_KEY } from '../constants'
import { useApiClient } from '../hooks/useApiClient'
import { Company } from '../types/company'

import styles from './dashboard-layout.module.scss'

export interface PageContextInterface {
  user: {}
  company: Company
  setCompany: (company: Company) => void
}

export const PageContext = createContext<PageContextInterface>({} as PageContextInterface)

export const DashboardLayout = () => {
  const { client: authClient } = useContext(AuthContext)
  const apiClient = useApiClient()
  const location = useLocation()
  const navigate = useNavigate()
  // console.debug('DashboardLayout: render', location.pathname)

  const [currentCompany, setCurrentCompany] = useState<Company>()

  const { isLoading, data, error } = useQuery(
    'user',
    async () => {
      const [{ data: companies }, { data: user }] = await Promise.all([
        apiClient.get('/companies'),
        apiClient.get('/auth/token/user'),
      ])
      setCurrentCompany(companies.find((x: any) => x._id === '630091e280d32e9943e41c07'))
      return { companies: companies as Company[], user }
    },
    { enabled: authClient.isAuthenticated },
  )

  const { companies, user } = data || {}

  const { mutate: handleLogout } = useMutation('logout', async () => {
    await authClient.logout()
    sessionStorage.removeItem(SESSION_STORAGE_RESTORE_PATH_KEY)
    return <Navigate to={'/login'} />
  })

  if (!authClient.isAuthenticated) {
    sessionStorage.setItem(SESSION_STORAGE_RESTORE_PATH_KEY, location.pathname)
    return <Navigate to={'/login'} />
  }

  if (error) {
    throw error
  }

  const pageContext = {
    user: user,
    company: currentCompany || {},
    setCompany: setCurrentCompany,
  } as PageContextInterface

  return isLoading ? (
    <Loader />
  ) : (
    <PageContext.Provider value={pageContext}>
      <div className={styles.root}>
        <Sidebar
          header={
            <CompanySelector
              companies={companies || []}
              selected={currentCompany?._id}
              onSelect={(selectedCompany) => {
                const selected = companies?.find((x: Company) => x._id === selectedCompany._id)

                if (selected) {
                  setCurrentCompany(selected)
                }
              }}
            />
          }
          routes={FlatNavRoutes}
          footer={
            <UserProfile name={user.name} handleLogout={handleLogout} handleSettings={() => navigate('/settings')} />
          }
        />
        <div className={styles.right}>
          <Outlet />
        </div>
      </div>
    </PageContext.Provider>
  )
}
