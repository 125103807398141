import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartTypeRegistry,
  DoughnutController,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  LogarithmicScale,
  PointElement,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import Zoom from 'chartjs-plugin-zoom'
import deepmerge from 'deepmerge'
import { useContext } from 'react'
import { ChartProps } from 'react-chartjs-2'
import equal from 'react-fast-compare'

import { SettingsContext } from '../../../app/Settings'
import { useMemoCompare } from '../../../hooks/useMemoCompare'
import { Tooltip } from './plugins/Tooltip'
import TooltipVerticalLine from './plugins/VerticalLine'

import themeVariables from '../../../scss/_variables.module.scss'

ChartJS.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  // BubbleController,
  DoughnutController,
  LineController,
  // PieController,
  // PolarAreaController,
  // RadarController,
  // ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  // RadialLinearScale,
  // TimeScale,
  // TimeSeriesScale,
  // Decimation,
  Filler,
  Legend,
  // Title,
  Tooltip,
  // SubTitle,
  Zoom,
  TooltipVerticalLine,
  ChartDataLabels,
)

export const ChartFontFamily = themeVariables['--app-font-family']
export const ChartFontColor = themeVariables['--app-color-text']

export interface UseChartProps<TType extends keyof ChartTypeRegistry, TData, TLabel> {
  props: ChartProps<TType, TData, TLabel>
  memoize?: boolean
}

export const DefaultScaleOptions = {
  grid: {
    display: false,
    drawBorder: false,
    drawTicks: false,
    borderDash: [10, 10],
  },
  ticks: {
    padding: 16,
    font: { family: ChartFontFamily },
  },
}

export const DefaultChartOptions: Partial<ChartProps<'bar' | 'line' | 'doughnut', any, any>> = {
  options: {
    maintainAspectRatio: false,
    font: { family: ChartFontFamily },
    color: ChartFontColor,
    devicePixelRatio: ((context: any) => context.chart.platform.getDevicePixelRatio() * 3) as any,
    layout: {
      autoPadding: false,
      padding: { left: -8, bottom: -8, top: -8 },
    },
    datasets: {
      bar: {
        categoryPercentage: 0.4,
        barPercentage: 1,
        maxBarThickness: 24,
        backgroundColor: '#6896DA',
      },
      line: {
        borderWidth: 2,
        pointRadius: 3.5,
        pointBorderWidth: 1.5,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 1.5,
      },
      doughnut: {
        borderWidth: 0,
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        intersect: true,
        caretSize: 0,
        padding: { top: 8, right: 16, bottom: 8, left: 16 },
        titleFont: { family: ChartFontFamily, weight: '500', size: 12, lineHeight: 1.5 },
        bodyFont: { family: ChartFontFamily, size: 12, lineHeight: 1.5 },
        footerFont: { family: ChartFontFamily },
        backgroundColor: '#ffffff',
        titleColor: '#7C7979',
        bodyColor: ChartFontColor,
        footerColor: ChartFontColor,
        titleSpacing: 0,
        bodySpacing: 0,
        footerSpacing: 0,
        titleMarginBottom: 0,
        footerMarginTop: 0,
        shadowColor: 'rgb(0 0 0 / 14%)',
        shadowBlur: 50,
        shadowOffsetY: 11,
        displayColors: false,
        beforeLabelTextColor: '#7C7979',
      },
      datalabels: {
        display: false,
        clip: true,
        align: 'top',
        anchor: 'end',
        offset: 3,
        backgroundColor: '#707070',
        color: '#FFFFFF',
        borderRadius: 8,
        font: { size: 10, family: ChartFontFamily },
        padding: { top: 4, bottom: 4, left: 8, right: 8 },
        formatter: (v, ctx) => {
          return ctx.chart
            .getDatasetMeta(ctx.datasetIndex)
            .yScale?.getLabelForValue(ctx.dataset.data[ctx.dataIndex] as any)
        },
      },
    },
    scales: {
      x: deepmerge(DefaultScaleOptions, {
        ticks: {
          font: {
            weight: '600',
          },
        },
      }),
      y: deepmerge(DefaultScaleOptions, {
        grid: {
          display: true,
        },
      }),
    },
  },
}

export const useChart = <TType extends keyof ChartTypeRegistry, TData, TLabel>({
  props,
  memoize = true,
}: UseChartProps<TType, TData, TLabel>) => {
  // console.debug('useChart')
  const { locale } = useContext(SettingsContext)

  props = deepmerge(deepmerge(DefaultChartOptions, { options: { locale } }), props)
  const propsMemo = useMemoCompare(props, memoize ? equal : () => false)

  return { chartProps: propsMemo }
}
