import classNames from 'classnames'
import { Fragment, useRef } from 'react'
import { Card } from 'react-bootstrap'

import { useNumberFormat } from '../../hooks/useNumberFormat'
import { AnalyticsData } from '../../types/accounting'
import { cumulateMonthlyAggregations, sum } from '../../utils/calc'
import { DateFormatUtils, DateFyUtils, DateTransformUtils } from '../../utils/date'
import { ChartComponent } from '../common/chart/ChartComponent'
import { useComparativeChart } from '../common/chart/useComparativeChart'
import { Amount } from '../number/Amount'

import styles from './comparative-card.module.scss'

export interface ComparativeCardProps {
  analyticsData: AnalyticsData
}

const getYtdPeriod = (date: number) => {
  const startFyYM = DateTransformUtils.YMD_YM(DateFyUtils.START_YMD(DateFyUtils.FY(date)))
  return [
    DateFormatUtils.YM(startFyYM, { spaceStyle: 'dash', monthStyle: 'short' }),
    DateFormatUtils.YMD(date, { spaceStyle: 'dash', monthStyle: 'short' }),
  ]
}

export const ComparativeCard = ({ analyticsData }: ComparativeCardProps) => {
  const comparativeChartRef = useRef()

  const cumulativeFyData = cumulateMonthlyAggregations(analyticsData.aggregations.ytd)
  const cumulativeRefFyData = cumulateMonthlyAggregations(
    analyticsData.isLatestFY ? analyticsData.aggregations.refFy : analyticsData.aggregations.fy,
  )

  const { chartProps, ComparativeLegend } = useComparativeChart({
    chartRef: comparativeChartRef,
    refData: cumulativeRefFyData,
    data: cumulativeFyData,
    lastDate: analyticsData.lastVoucherDate.fy,
  })

  const ytd = {
    fy: {
      amount: Math.round(sum(analyticsData.aggregations.ytd.map((x) => x.amount))),
      period: getYtdPeriod(analyticsData.lastVoucherDate.fy),
    },
    refFy: {
      amount: Math.round(sum(analyticsData.aggregations.refYtd.map((x) => x.amount))),
      period: getYtdPeriod(analyticsData.lastVoucherDate.refFy),
    },
    factor: 0,
  }

  const { format: percentFormat } = useNumberFormat({
    style: 'percent',
    useGrouping: false,
    maximumFractionDigits: 2,
    signDisplay: 'never',
  })
  ytd.factor = (ytd.fy.amount - ytd.refFy.amount) / ytd.refFy.amount
  ytd.factor = ytd.factor === Infinity ? NaN : ytd.factor

  return (
    <Card className={styles.comparativeSection}>
      <span className={styles.statsSection}>
        <span className={styles.stat}>
          <span className={styles.label}>
            {ytd.fy.period[0]} to {ytd.fy.period[1]}
          </span>
          <span className={styles.value}>
            <Amount>{ytd.fy.amount}</Amount>
          </span>
        </span>
        <span className={styles.stat}>
          <span className={styles.label}>
            {ytd.refFy.period[0]} to {ytd.refFy.period[1]}
          </span>
          <span className={styles.value}>
            <Amount>{ytd.refFy.amount}</Amount>
          </span>
        </span>
        <span className={styles.compareText}>
          {!isNaN(ytd.factor) ? (
            <Fragment>
              {ytd.factor > 0 ? 'Up' : 'Down'}{' '}
              <span className={classNames(ytd.factor > 0 ? styles.positive : styles.negative)}>
                {percentFormat(ytd.factor)}
              </span>{' '}
              from the previous period
            </Fragment>
          ) : (
            <Fragment />
          )}
        </span>
      </span>
      <span className={styles.chartSection}>
        <span className={styles.chart}>
          <ChartComponent chartRef={comparativeChartRef} props={chartProps} />
        </span>
        <span>
          <ComparativeLegend />
        </span>
      </span>
    </Card>
  )
}
