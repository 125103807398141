import { Fragment, ReactNode } from 'react'

import { AuthContextProvider } from '../auth/AuthContext'
import { ConfigContextProvider } from './Config'
import { SettingsContextProvider } from './Settings'

interface AppContextProviderProps {
  children?: ReactNode
}

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const providers = [ConfigContextProvider, AuthContextProvider, SettingsContextProvider]

  return providers.reverse().reduce((combinedProvider, CurrentProvider) => {
    return <CurrentProvider>{combinedProvider}</CurrentProvider>
  }, <Fragment>{children}</Fragment>)
}
