import classNames from 'classnames'
import { Fragment } from 'react'
import { Card } from 'react-bootstrap'

import { DateFormatUtils } from '../../utils/date'
import { Amount } from '../number/Amount'

import styles from './analytics-stat.module.scss'

export interface AnalyticsStatProps {
  title: string
  mainStat: { label: string; value: number }
  secondaryStat?: { label: string; value: number }
  type?: 'amount' | 'number'
  variant?: 'standard' | 'cashFlow'
}

export const AnalyticsStat = ({
  title,
  mainStat,
  secondaryStat,
  type = 'amount',
  variant = 'standard',
}: AnalyticsStatProps) => {
  return (
    <Card className={classNames(styles.card, styles[variant])}>
      <span className={styles.title}>{title}</span>
      <span className={styles.stats}>
        <span className={classNames(styles.stat, styles.mainStat)}>
          {variant !== 'cashFlow' && <span className={styles.label}>{mainStat.label}</span>}
          <span className={styles.value}>
            {type === 'amount' ? (
              <Amount options={{ maximumFractionDigits: 0 }}>{mainStat.value}</Amount>
            ) : (
              mainStat.value
            )}
          </span>
        </span>
        {secondaryStat && (
          <Fragment>
            {variant !== 'cashFlow' && <span className={styles.separator} />}
            <span className={classNames(styles.stat, styles.secondaryStat)}>
              <span className={styles.label}>{secondaryStat.label}</span>
              <span className={styles.value}>
                {variant !== 'cashFlow' ? (
                  type === 'amount' ? (
                    <Amount options={{ maximumFractionDigits: 0 }}>{secondaryStat.value}</Amount>
                  ) : (
                    secondaryStat.value
                  )
                ) : (
                  DateFormatUtils.YMD(secondaryStat.value, { monthStyle: 'short', spaceStyle: 'space' })
                )}
              </span>
            </span>
          </Fragment>
        )}
      </span>
    </Card>
  )
}
