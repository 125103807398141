import { useContext } from 'react'

import { AccountingContext } from '../layouts/AccountingLayout'
import { PageContext } from '../layouts/DashboardLayout'
import { InventoryEntry, Master, MonthlyAggregate, Voucher } from '../types/accounting'
import { ClosingStockMethod } from '../types/company'
import { cumulateMonthlyAggregations, isNearZero, monthlyAggregations, monthlyGroup, sum } from '../utils/calc'
import { DateFyUtils, DateTransformUtils, DateUtils } from '../utils/date'

export interface UseStatementDataProps {
  startDate: number
  endDate: number
}

export const IncomeHeads = [
  // Default Heads
  'Sales Accounts',
  'Direct Incomes',
  'Purchase Accounts',
  'Direct Expenses',
  'Indirect Incomes',
  'Indirect Expenses',

  // Calculated Heads
  'Closing Stock',
  'Change in Stock',
  'Gross Profit',
  'Net Profit',

  // Ratios
  'grossProfit',
  'netProfit',
  'indirectExpenseToSales',
  'returnOnAssets',
] as const
export type IncomeHead = typeof IncomeHeads[number]

export const BalanceSheetHeads = [
  // Default Heads
  'Capital Account',
  'Loans (Liability)',
  'Current Liabilities',
  'Profit & Loss A/c',
  'Fixed Assets',
  'Investments',
  'Current Assets',

  // Calculated Heads
  'Opening Profit',
  'Change in Profit & Loss A/c',
  'P&L A/c',
  'Total Liabilities',
  'Total Assets',

  // Ratio Heads
  'Bank Accounts',
  'Cash-in-Hand',
  'Sundry Debtors',
  'Current Liabilities',

  // Ratios
  'quick',
  'current',
  'debt',
  'debtEquity',
  'debtTurnover',
] as const
export type BalanceSheetHead = typeof BalanceSheetHeads[number]

export const StatementHeads = [...IncomeHeads, ...BalanceSheetHeads] as const
export type StatementHead = IncomeHead | BalanceSheetHead

export interface StatementData {
  name: StatementHead
  total: number
  monthly: MonthlyAggregate[]
  transactions?: Voucher[]
  parent?: StatementData
  children?: StatementData[]
  openingBalance?: number
  isCumulative?: boolean
}

const calculateTotals = (data: StatementData[], startDate: number, endDate: number): MonthlyAggregate[] => {
  let monthly: MonthlyAggregate[] = []
  for (const headData of data) {
    const childrenMonthly = calculateTotals(headData.children || [], startDate, endDate)
    let headMonthly = monthlyAggregations(headData.transactions || [], 'date', 'amount', {
      start: startDate,
      end: endDate,
    })

    if (headData.isCumulative) {
      headMonthly = cumulateMonthlyAggregations(headMonthly, headData.openingBalance)
    }

    headData.monthly =
      headData.monthly || headMonthly.map((x, i) => ({ ...x, amount: x.amount + (childrenMonthly[i]?.amount || 0) }))
    headData.total = sum(headData.monthly.map((x) => x.amount))

    if (headData.isCumulative) {
      headData.total = headData.monthly[headData.monthly.length - 1]?.amount
    }

    for (let i = 0; i < headData.monthly.length; i++) {
      monthly[i] = { ...headData.monthly[i], amount: headData.monthly[i].amount + (monthly[i]?.amount || 0) }
    }
  }
  return monthly
}

const buildHeadsData = (masters: Master[], transactions: Voucher[], heads: StatementHead[], isCumulative: boolean) => {
  const filteredMasters = masters

  const ancestries: StatementHead[][] = []
  for (let i = 0; i < filteredMasters.length; i++) {
    let currentMaster = filteredMasters[i]
    const ancestry: StatementHead[] = [currentMaster.name as StatementHead]

    while (currentMaster.parentId !== currentMaster._id) {
      const masterIdx = masters.map((x) => x._id).indexOf(currentMaster.parentId)
      currentMaster = masters[masterIdx]

      if (currentMaster !== undefined) {
        ancestry.push(currentMaster.name as StatementHead)
      } else {
        break
      }
    }

    if (heads.indexOf(ancestry[ancestry.length - 1]) > -1) {
      ancestries.push(ancestry.reverse())
    }
  }

  const dataMap = {} as Record<StatementHead, StatementData>
  const buildTree = (currAncestry: string[], currData: StatementData[], isCumulative: boolean) => {
    const [head, ...children] = currAncestry

    if (head) {
      let headData = currData.find((x) => x.name === head) || ({} as StatementData)

      if (Object.keys(headData).length === 0) {
        headData.name = head as StatementHead
        headData.transactions = transactions.filter((x) => x.master.name === headData.name)
        headData.openingBalance =
          headData.transactions[0]?.master.openingBalance ||
          masters.find((x) => x.name === headData.name)?.openingBalance
        headData.isCumulative = isCumulative
        currData.push(headData)
        dataMap[headData.name as StatementHead] = headData
      }

      headData.children = headData.children || []
      buildTree(children || [], headData.children, isCumulative)
      headData.children.forEach((x) => (x.parent = headData))
    }
  }

  let data = [] as StatementData[]

  for (const ancestry of ancestries) {
    buildTree(ancestry, data, isCumulative)
  }

  return { data, dataMap }
}

const closingStockWeightedAverage = (
  inventoryEntries: InventoryEntry[],
  startDate: number,
  endDate: number,
  firstTransactionDate: number,
): [StatementData, StatementData] => {
  const itemEntriesMap = Object.entries(
    inventoryEntries
      .filter((x) => x.date <= endDate)
      .reduce(
        (items, entry) => ({ ...items, [entry.stockItemName]: [...(items[entry.stockItemName] || []), entry] }),
        {} as Record<string, InventoryEntry[]>,
      ),
  )

  const itemsClosingStock = []
  const debug = []
  for (const [itemName, itemEntries] of itemEntriesMap) {
    const purchaseEntries = itemEntries.filter(
      (x) =>
        x.voucherType === 'Purchase' ||
        x.voucherType === 'Debit Note' ||
        (x.voucherType === 'Journal' && x.master?.superParent?.name === 'Purchase Accounts'),
    )

    const fys = DateFyUtils.LIST_FY_BETWEEN_YMD(firstTransactionDate, endDate)

    let fyAvgPrices = {} as Record<number, number>
    let fyPurchaseQtys = {} as Record<number, number>
    let fyClosingQtys = {} as Record<number, number>

    let closingQty = 0
    for (const fy of fys) {
      const fyStartDate = DateFyUtils.START_YMD(fy)
      const fyEndDate = DateFyUtils.END_YMD(fy)

      const fyPurchaseEntries = purchaseEntries.filter((x) => x.date >= fyStartDate && x.date <= fyEndDate)
      const fyPurchaseAmount = sum(fyPurchaseEntries.map((x) => x.amount))
      const fyPurchaseQty = sum(fyPurchaseEntries.map((x) => x.qty))
      const fyFinalPurchaseQty = !isNearZero(fyPurchaseAmount) && isNearZero(fyPurchaseQty) ? 1 : fyPurchaseQty

      const fyEntries = itemEntries.filter((x) => x.date >= fyStartDate && x.date <= fyEndDate)
      const fyClosingQty = closingQty + sum(fyEntries.map((x) => x.qty))

      const prevFyAvgPrice = fyAvgPrices[fy - 1] || 0
      const prevFyClosingQty = fyClosingQtys[fy - 1] || 0

      fyAvgPrices[fy] = !isNearZero(fyFinalPurchaseQty + prevFyClosingQty)
        ? (fyPurchaseAmount + prevFyAvgPrice * prevFyClosingQty) / (fyFinalPurchaseQty + prevFyClosingQty)
        : 0
      fyPurchaseQtys[fy] = fyPurchaseQty
      fyClosingQtys[fy] = prevFyClosingQty + fyClosingQty
    }

    const monthlyEntries = monthlyGroup(itemEntries, 'date', {
      groupKey: 'entries',
      startDate: firstTransactionDate,
      endDate: endDate,
    })

    let purchaseAmount = 0
    let purchaseQty = 0
    closingQty = 0

    const monthlyClosingStock = []
    for (let i = 0; i < monthlyEntries.length; i++) {
      const fy = DateFyUtils.YM_FY(monthlyEntries[i].yearMonth)
      const monthStartDate = DateTransformUtils.YM_START_YMD(monthlyEntries[i].yearMonth)
      const monthEndDate = DateTransformUtils.YM_END_YMD(monthlyEntries[i].yearMonth)

      const monthEntries = monthlyEntries[i].entries
      const monthPurchaseEntries = monthEntries
        .filter(
          (x) =>
            x.voucherType === 'Purchase' ||
            x.voucherType === 'Debit Note' ||
            (x.voucherType === 'Journal' && x.master?.superParent?.name === 'Purchase Accounts'),
        )
        .filter((x) => x.date >= monthStartDate && x.date <= monthEndDate)

      purchaseAmount += sum(monthPurchaseEntries.map((x) => x.amount))
      purchaseQty += sum(monthPurchaseEntries.map((x) => x.qty))
      closingQty += sum(monthEntries.map((x) => x.qty))

      if (!isNearZero(purchaseAmount) && isNearZero(purchaseQty)) {
        purchaseQty += 1
        closingQty += 1
      }

      const prevFyAvgPrice = fyAvgPrices[fy - 1] || 0
      const prevFyClosingQty = fyClosingQtys[fy - 1] || 0

      const avgPrice = !isNearZero(purchaseQty + prevFyClosingQty)
        ? (purchaseAmount + prevFyAvgPrice * prevFyClosingQty) / (purchaseQty + prevFyClosingQty)
        : 0

      monthlyClosingStock.push({
        yearMonth: monthlyEntries[i].yearMonth,
        // purchaseAmount: purchaseAmount,
        // purchaseQty: purchaseQty,
        // closingQty: closingQty,
        // avgPrice: avgPrice,
        closingStock: avgPrice * closingQty,
        // monthEntries,
        // monthPurchaseEntries,
      })

      if (DateTransformUtils.YM_M(monthlyEntries[i].yearMonth) === 3) {
        purchaseAmount = 0
        purchaseQty = 0
      }
    }

    itemsClosingStock.push({ itemName: itemName, monthlyClosingStock: monthlyClosingStock })
    debug.push({
      itemName,
      purchaseEntries,
      fys,
      fyAvgPrices,
      fyPurchaseQtys,
      fyClosingQtys,
      // monthlyClosingQtys,
      monthlyClosingStock,
    })
  }

  const monthlyClosingStock = itemsClosingStock.reduce(
    (total, item) =>
      item.monthlyClosingStock.map((x, i) => ({
        yearMonth: x.yearMonth,
        amount: -x.closingStock + (total[i]?.amount || 0),
      })),
    [] as MonthlyAggregate[],
  )
  const monthlyChangeInStock = monthlyClosingStock.map((x, i) => ({
    ...x,
    amount: x.amount - (monthlyClosingStock[i - 1]?.amount || 0),
  }))

  // console.debug({ monthlyClosingStock, monthlyChangeInStock, itemsClosingStock, debug })

  const startYearMonth = DateTransformUtils.YMD_YM(startDate)
  const monthly = monthlyClosingStock.filter((x) => x.yearMonth >= startYearMonth)
  const total = monthly[monthly.length - 1]?.amount || 0
  return [
    { name: 'Closing Stock', monthly: monthly.map((x) => ({ ...x, amount: -x.amount })), total: -total },
    {
      name: 'Change in Stock',
      monthly: monthlyChangeInStock.filter((x) => x.yearMonth >= startYearMonth),
      total: sum(monthlyChangeInStock.filter((x) => x.yearMonth >= startYearMonth).map((x) => x.amount)),
    },
  ]
}

const closingStockManual = (masters: Master[], startDate: number, endDate: number): [StatementData, StatementData] => {
  const startYearMonth = DateTransformUtils.YMD_YM(startDate)

  const closingStockEntries = masters
    .filter((x) => (x.closingStockEntries?.length || 0) > 0)
    .flatMap((x) => x.closingStockEntries || [])

  const monthlyClosingStock = cumulateMonthlyAggregations(
    monthlyAggregations(
      closingStockEntries.filter((x) => x.date <= endDate).map((x) => ({ ...x, amount: -x.amount })),
      'date',
      'amount',
      { start: startDate, end: endDate },
    ),
  )
  const totalClosingStock = monthlyClosingStock[monthlyClosingStock.length - 1]?.amount || 0

  const monthlyChangeInStock = monthlyClosingStock
    .map((x, i) => ({
      ...x,
      amount: x.amount - (monthlyClosingStock[i - 1]?.amount || 0),
    }))
    .filter((x) => x.yearMonth >= startYearMonth)
  const totalChangeInStock = sum(monthlyChangeInStock.map((x) => x.amount))

  // console.debug({ closingStockEntries, monthlyClosingStock, totalClosingStock })

  return [
    {
      name: 'Closing Stock',
      monthly: monthlyClosingStock
        .filter((x) => x.yearMonth >= startYearMonth)
        .map((x) => ({ ...x, amount: -x.amount })),
      total: -totalClosingStock,
    },
    { name: 'Change in Stock', monthly: monthlyChangeInStock, total: totalChangeInStock },
  ]
}

const aggregateHeads = (
  name: StatementHead,
  headsDataMap: Record<StatementHead, StatementData>,
  ...heads: StatementHead[]
): StatementData => {
  const monthly = heads.reduce((headsMonthly, b) => {
    const headMonthly = ((headsDataMap[b] && headsDataMap[b].monthly) || []).map((x, i) => ({
      ...x,
      amount: x.amount + (headsMonthly[i]?.amount || 0),
    }))

    return headMonthly.length === 0 ? headsMonthly : headMonthly
  }, [] as MonthlyAggregate[])
  const total = sum(monthly.map((x) => x.amount))
  return { name: name, monthly: monthly, total: total }
}

const calculateRatioValues = (
  name: StatementHead,
  headsData: Record<StatementHead, StatementData>,
  numerator: StatementHead[],
  denominator: StatementHead[],
): StatementData => {
  const numeratorData = aggregateHeads('numerator' as StatementHead, headsData, ...numerator)
  numeratorData.children = numerator.map((x) => headsData[x] || { name: x })

  const denominatorData = aggregateHeads('denominator' as StatementHead, headsData, ...denominator)
  denominatorData.children = denominator.map((x) => headsData[x] || { name: x })

  const monthly = numeratorData.monthly.map((x, i) => ({
    ...x,
    amount: x?.amount / denominatorData.monthly[i]?.amount,
  }))
  const total = numeratorData.total / denominatorData.total

  return { name: name as StatementHead, monthly: monthly, total: total, children: [numeratorData, denominatorData] }
}

const calculateIncomeData = (
  transactions: Voucher[],
  masters: Master[],
  inventoryEntries: InventoryEntry[],
  closingStockMethod: ClosingStockMethod,
  startDate: number,
  endDate: number,
  firstVoucherDate: number,
): Record<IncomeHead, StatementData> => {
  const filteredTransactions = transactions.filter((x) => x.date >= startDate && x.date <= endDate)
  const { data, dataMap } = buildHeadsData(
    masters,
    filteredTransactions,
    IncomeHeads as unknown as StatementHead[],
    false,
  )
  calculateTotals(data, startDate, endDate)

  const [closingStock, changeInStock] =
    closingStockMethod === 'weightedAverage'
      ? closingStockWeightedAverage(inventoryEntries, startDate, endDate, firstVoucherDate)
      : closingStockManual(masters, startDate, endDate)

  dataMap['Closing Stock'] = closingStock
  data.push(dataMap['Closing Stock'])

  dataMap['Change in Stock'] = changeInStock
  data.push(dataMap['Change in Stock'])

  dataMap['Gross Profit'] = aggregateHeads(
    'Gross Profit',
    dataMap,
    'Sales Accounts',
    'Direct Incomes',
    'Purchase Accounts',
    'Direct Expenses',
    'Change in Stock',
  )
  data.push(dataMap['Gross Profit'])

  dataMap['Net Profit'] = aggregateHeads('Net Profit', dataMap, 'Gross Profit', 'Indirect Incomes', 'Indirect Expenses')
  data.push(dataMap['Net Profit'])

  // console.debug({ data, dataMap, startDate, endDate })

  return dataMap
}

const cleanStatementData = (data: StatementData, startDate: number, endDate: number, isCumulative: boolean) => {
  const startYearMonth = DateTransformUtils.YMD_YM(startDate)
  const endYearMonth = DateTransformUtils.YMD_YM(endDate)
  data.monthly = data.monthly.filter((x) => x.yearMonth >= startYearMonth && x.yearMonth <= endYearMonth)
  data.children?.forEach((x) => cleanStatementData(x, startDate, endDate, isCumulative))
  data.children = data.children?.filter((x) => x.monthly.length > 0)

  if (data.monthly.every((x) => isNearZero(x.amount)) && data.children?.every((x) => x.monthly.length === 0)) {
    data.monthly = []
  }

  data.total = isCumulative
    ? data.monthly[data.monthly.length - 1]?.amount || 0
    : sum(data.monthly.map((x) => x.amount))
}

export const useIncomeData = ({ startDate, endDate }: UseStatementDataProps) => {
  const { company } = useContext(PageContext)
  const { masters, vouchers, inventoryEntries, firstVoucherDate } = useContext(AccountingContext)

  endDate = DateUtils.IS_YM_END_YMD(endDate)
    ? endDate
    : DateTransformUtils.YM_END_YMD(DateTransformUtils.YMD_SUBTRACT_M(endDate))

  const dataMap = calculateIncomeData(
    vouchers,
    masters,
    inventoryEntries,
    company.closingStockMethod,
    startDate,
    endDate,
    firstVoucherDate,
  ) as Record<StatementHead, StatementData>

  const ratiosData = {
    grossProfit: calculateRatioValues('grossProfit', dataMap, ['Gross Profit'], ['Sales Accounts']),
    netProfit: calculateRatioValues('netProfit', dataMap, ['Net Profit'], ['Sales Accounts']),
    indirectExpenseToSales: calculateRatioValues(
      'indirectExpenseToSales',
      dataMap,
      ['Indirect Expenses'],
      ['Sales Accounts'],
    ),
    returnOnAssets: calculateRatioValues('returnOnAssets', dataMap, ['Net Profit'], ['Sales Accounts']),
  }

  for (const head of IncomeHeads) {
    dataMap[head] = dataMap[head] || {
      name: head,
      total: 0,
      monthly: monthlyAggregations([] as any[], 'date', 'amount', { start: startDate, end: endDate }),
    }
  }

  return { incomeHeads: dataMap, incomeRatios: ratiosData }
}

export const useBalanceSheetData = ({ startDate, endDate }: UseStatementDataProps) => {
  const { company } = useContext(PageContext)
  const { masters, vouchers, inventoryEntries, firstVoucherDate } = useContext(AccountingContext)

  endDate = DateUtils.IS_YM_END_YMD(endDate)
    ? endDate
    : DateTransformUtils.YM_END_YMD(DateTransformUtils.YMD_SUBTRACT_M(endDate))

  const incomeData = calculateIncomeData(
    vouchers,
    masters,
    inventoryEntries,
    company.closingStockMethod,
    firstVoucherDate,
    endDate,
    firstVoucherDate,
  )

  const filteredTransactions = vouchers.filter((x) => x.date <= endDate)
  const { data, dataMap: currData } = buildHeadsData(
    masters,
    filteredTransactions,
    BalanceSheetHeads as unknown as StatementHead[],
    true,
  )
  currData['Current Assets'].children = currData['Current Assets'].children || []
  currData['Current Assets'].children.splice(0, 0, incomeData['Closing Stock'])
  calculateTotals(data, firstVoucherDate, endDate)

  currData['Net Profit'] = incomeData['Net Profit']

  currData['Change in Profit & Loss A/c'] = {
    ...currData['Profit & Loss A/c'],
    name: 'Profit & Loss A/c',
    monthly: (currData['Profit & Loss A/c']?.monthly || []).map((x, i, self) => ({
      ...x,
      amount: x.amount - (self[i - 1]?.amount || 0),
    })),
  }
  currData['P&L A/c'] = aggregateHeads('P&L A/c', currData, 'Change in Profit & Loss A/c', 'Net Profit')
  currData['P&L A/c'].monthly = cumulateMonthlyAggregations(currData['P&L A/c'].monthly)
  currData['Opening Profit'] = {
    name: 'Opening Profit',
    monthly: currData['P&L A/c'].monthly.map((x, i) => ({
      ...x,
      amount: x.amount - currData['Net Profit'].monthly[i].amount,
    })),
    total: 0,
  }
  currData['P&L A/c'].children = [currData['Opening Profit'], currData['Net Profit']]

  for (const headName of BalanceSheetHeads) {
    if (currData[headName]) {
      cleanStatementData(currData[headName], startDate, endDate, true)
    }
  }

  currData['Total Liabilities'] = aggregateHeads(
    'Total Liabilities',
    currData,
    'Capital Account',
    'Loans (Liability)',
    'Current Liabilities',
    'P&L A/c',
  )

  currData['Total Assets'] = aggregateHeads('Total Assets', currData, 'Fixed Assets', 'Investments', 'Current Assets')

  const ratiosData = {
    quick: calculateRatioValues(
      'quick',
      currData,
      ['Bank Accounts', 'Cash-in-Hand', 'Sundry Debtors'],
      ['Current Liabilities'],
    ),
    current: calculateRatioValues('current', currData, ['Current Assets'], ['Current Liabilities']),
    debt: calculateRatioValues(
      'debt',
      currData,
      ['Loans (Liability)'],
      ['Fixed Assets', 'Investments', 'Current Assets'],
    ),
    debtEquity: calculateRatioValues('debtEquity', currData, ['Loans (Liability)'], ['Capital Account', 'P&L A/c']),
    debtTurnover: calculateRatioValues('debtTurnover', currData, [], []),
  }

  for (const head of StatementHeads) {
    currData[head] = currData[head] || { name: head }
    currData[head].total = currData[head].total || 0
    currData[head].monthly =
      currData[head].monthly?.length > 0
        ? currData[head].monthly
        : monthlyAggregations([] as any[], 'date', 'amount', { start: startDate, end: endDate })
  }

  // console.debug({ headsData: currData, ratiosData: ratiosData })

  return { balanceSheetHeads: currData, balanceSheetRatios: ratiosData }
}
