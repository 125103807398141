import { Master, Voucher } from '../types/accounting'
import { cumulateMonthlyAggregations, monthlyAggregations, sum } from '../utils/calc'

export type AccountingHead = 'revenue' | 'purchases' | 'expenses' | 'liquidity' | 'accountReceivable' | 'accountPayable'

const transactionFilters: Record<AccountingHead, (transactions: Voucher[]) => Voucher[]> = {
  revenue: (transactions) =>
    transactions.filter(
      (x) => x.master.superParent?.name === 'Sales Accounts' || x.master.superParent?.name === 'Direct Incomes',
    ),

  purchases: (transactions) =>
    transactions
      .filter((x) => x.master.superParent?.name === 'Purchase Accounts')
      .map((x) => ({ ...x, amount: -x.amount })),

  expenses: (transactions) =>
    transactions
      .filter(
        (x) => x.master.superParent?.name === 'Direct Expenses' || x.master.superParent?.name === 'Indirect Expenses',
      )
      .map((x) => ({ ...x, amount: -x.amount })),

  liquidity: (transactions) =>
    transactions
      .filter((x) => x.master.parent?.name === 'Cash-in-Hand' || x.master.parent?.name === 'Bank Accounts')
      .map((x) => ({ ...x, amount: -x.amount })),

  accountReceivable: (transactions) =>
    transactions.filter((x) => x.master.parent?.name === 'Sundry Debtors').map((x) => ({ ...x, amount: -x.amount })),

  accountPayable: (transactions) => transactions.filter((x) => x.master.parent?.name === 'Sundry Creditors'),
} as Record<AccountingHead, (transactions: Voucher[]) => Voucher[]>

export const calculateHeadTotals = (
  head: AccountingHead,
  transactions: Voucher[],
  masters: Master[],
  startDate: number,
  endDate: number,
) => {
  const transactionFilter = transactionFilters[head] || ((x: Voucher[]) => x)
  const filteredTransactions = transactionFilter(transactions).filter((x) => x.date >= startDate && x.date <= endDate)

  const totals = calculateTransactionsTotals(filteredTransactions, startDate, endDate)

  if (head === 'liquidity') {
    const parentIds = masters.filter((x) => x.name === 'Cash-in-Hand' || x.name === 'Bank Accounts').map((x) => x._id)
    const liquidityMasters = masters.filter((x) => parentIds.indexOf(x.parentId) > -1)

    const openingBalance = sum(liquidityMasters.map((x) => -(x.openingBalance || 0)))
    const prevTransactions = transactionFilter(transactions).filter((x) => x.date < startDate)

    const closingBalance = openingBalance + sum(prevTransactions.map((x) => x.amount))

    totals.monthly = cumulateMonthlyAggregations(totals.monthly, closingBalance)
    totals.total = totals.monthly[totals.monthly.length - 1]?.amount || 0
  }

  return totals
}

export const calculateTransactionsTotals = (transactions: Voucher[], startDate: number, endDate: number) => {
  let monthly = monthlyAggregations(transactions, 'date', 'amount', { start: startDate, end: endDate })
  let total = sum(monthly.map((x) => x.amount))

  return { total: total, monthly: monthly, startDate: startDate, endDate: endDate }
}
