import classNames from 'classnames'
import React from 'react'
import { default as BsDropdownToggle } from 'react-bootstrap/DropdownToggle'

import { ReactComponent as ChevronIcon } from '../../../assets/images/chevron.svg'
import { Button, ButtonProps } from '../button/Button'

import styles from './dropdown.module.scss'

export interface DropdownToggleProps extends Omit<ButtonProps, 'as'> {
  as?: React.ElementType
  split?: boolean
  childBsPrefix?: string
}

export const DropdownToggle = React.forwardRef<HTMLElement, DropdownToggleProps>(
  ({ className, children, as = Button, ...props }, ref) => {
    return (
      <BsDropdownToggle
        ref={ref}
        as={as}
        {...props}
        className={classNames(className, styles.dropdownToggle, props.variant && styles[props.variant])}
      >
        <span className={styles.content}>{children}</span>
        <ChevronIcon className={styles.icon} />
      </BsDropdownToggle>
    )
  },
)
