import classNames from 'classnames'
import { Fragment, ReactNode, forwardRef, useMemo } from 'react'
import { FloatingLabel, FormControl, FormControlProps } from 'react-bootstrap'

import { ReactComponent as ShowIcon } from '../../../assets/icons/show.svg'
import { ReactComponent as CancelIcon } from '../../../assets/images/cancel.svg'
import { useForwardedRef } from '../../../hooks/useForwardedRef'
import { Button } from '../button/Button'

import styles from './input.module.scss'

export const InputTypes = ['simple', 'floating'] as const
export const InputIcons = ['clear', 'show'] as const

export interface InputProps extends FormControlProps {
  variant?: typeof InputTypes[number]
  icon?: typeof InputIcons[number]
  error?: string
  autoComplete?: string
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ variant = 'simple', icon, error, placeholder = '', className, ...formControlProps }: InputProps, ref) => {
    const safeRef = useForwardedRef(ref)

    const clearInput = () => {
      if (safeRef.current) {
        safeRef.current.value = ''
      }
    }

    const showInput = () => {
      if (safeRef.current) {
        safeRef.current.type = 'text'
      }
    }

    const hideInput = () => {
      if (safeRef.current) {
        safeRef.current.type = formControlProps.type || 'password'
      }
    }

    const ControlWrapper = useMemo(
      () =>
        variant === 'floating'
          ? ({ children }: { children?: ReactNode }) => (
              <FloatingLabel className={styles.floating} label={placeholder}>
                {children}
              </FloatingLabel>
            )
          : Fragment,
      [placeholder, variant],
    )

    return (
      <div className={className}>
        <div className={classNames(styles.wrapper, error && styles.error)}>
          <ControlWrapper>
            <FormControl ref={safeRef} className={styles.formControl} placeholder={placeholder} {...formControlProps} />
            {icon === 'clear' && (
              <Button className={styles.btn} variant={'transparent'} onClick={() => clearInput()} tabIndex={-1}>
                <CancelIcon className={styles.icon} />
              </Button>
            )}
            {icon === 'show' && (
              <Button
                className={styles.btn}
                variant={'transparent'}
                onMouseEnter={() => showInput()}
                onMouseLeave={() => hideInput()}
                tabIndex={-1}
              >
                <ShowIcon className={styles.icon} />
              </Button>
            )}
          </ControlWrapper>
        </div>
        <div className={styles.message}>{error || '\u00a0'}</div>
      </div>
    )
  },
)
