import { Tooltip as ChartTooltip, ChartType, TooltipCallbacks, TooltipPositionerFunction } from 'chart.js'
import { each, getRtlAdapter, toFont, toPadding } from 'chart.js/helpers'
import { Color } from 'chart.js/types/color'

export const Tooltip = ChartTooltip

Tooltip.positioners.followCursorY = (items, eventPosition) => {
  return {
    x: items.length ? items.map((x) => x.element.x).reduce((a, b) => a + b, 0) / items.length : eventPosition.x,
    y: eventPosition.y,
  }
}

Tooltip.afterInit = (chart, args, options) => {
  if (options) {
    ;(chart as any).tooltip = new (ChartTooltip as any)._element({ chart, options })
    customizeTooltip(chart.tooltip)
  }
}

const customizeTooltip = (tooltipElement: any) => {
  // add shadow to tooltip
  tooltipElement.drawBackground = (pt: any, ctx: CanvasRenderingContext2D, tooltipSize: any, options: any) => {
    ctx.save()
    ctx.shadowColor = options.shadowColor as string
    ctx.shadowBlur = options.shadowBlur as number
    ctx.shadowOffsetY = options.shadowOffsetY as number
    ;(ChartTooltip as any)._element.prototype.drawBackground(pt, ctx, tooltipSize, options)
    ctx.restore()
  }

  const getAlignedX = (tooltip: any, align: any, options: any) => {
    const padding = toPadding(options.padding)
    return align === 'center'
      ? tooltip.x + tooltip.width / 2
      : align === 'right'
      ? tooltip.x + tooltip.width - padding.right
      : tooltip.x + padding.left
  }

  tooltipElement.drawBody = function (pt: any, ctx: CanvasRenderingContext2D, options: any) {
    const { body } = this
    const { bodySpacing, bodyAlign, displayColors, boxHeight, boxWidth, boxPadding } = options
    const bodyFont = toFont(options.bodyFont)
    let bodyLineHeight = bodyFont.lineHeight as number
    let xLinePadding = 0

    const rtlHelper = getRtlAdapter(options.rtl, this.x, this.width)

    const fillLineOfText = function (line: any) {
      const splitIdx = line.search(': ')
      if (splitIdx > -1) {
        ctx.save()
        let text = line.substring(0, splitIdx + 2)
        ctx.fillStyle = options.beforeLabelTextColor || ctx.fillStyle
        ctx.font = toFont(options.bodyFont).string
        ctx.fillText(text, rtlHelper.x(pt.x + xLinePadding), pt.y + bodyLineHeight / 2)
        const offset = ctx.measureText(text).width
        ctx.restore()

        ctx.save()
        text = line.substring(splitIdx + 2)
        ctx.font = toFont(options.bodyFont).string
        ctx.fillText(text, rtlHelper.x(pt.x + xLinePadding + offset), pt.y + bodyLineHeight / 2)
        ctx.restore()
      } else {
        ctx.fillText(line, rtlHelper.x(pt.x + xLinePadding), pt.y + bodyLineHeight / 2)
      }
      pt.y += bodyLineHeight + bodySpacing
    }

    const bodyAlignForCalculation = rtlHelper.textAlign(bodyAlign)
    let bodyItem, textColor, lines, i, j, ilen, jlen

    ctx.textAlign = bodyAlign
    ctx.textBaseline = 'middle'
    ctx.font = bodyFont.string

    pt.x = getAlignedX(this, bodyAlignForCalculation, options)

    // Before body lines
    ctx.fillStyle = options.bodyColor
    each(this.beforeBody, fillLineOfText)

    xLinePadding =
      displayColors && bodyAlignForCalculation !== 'right'
        ? bodyAlign === 'center'
          ? boxWidth / 2 + boxPadding
          : boxWidth + 2 + boxPadding
        : 0

    // Draw body lines now
    for (i = 0, ilen = body.length; i < ilen; ++i) {
      bodyItem = body[i]
      textColor = this.labelTextColors[i]

      ctx.fillStyle = options.beforeLabelTextColor || textColor
      each(bodyItem.before, fillLineOfText)

      ctx.fillStyle = textColor

      lines = bodyItem.lines
      // Draw Legend-like boxes if needed
      if (displayColors && lines.length) {
        this._drawColorBox(ctx, pt, i, rtlHelper, options)
        bodyLineHeight = Math.max(bodyFont.lineHeight as number, boxHeight)
      }

      for (j = 0, jlen = lines.length; j < jlen; ++j) {
        fillLineOfText(lines[j])
        // Reset for any lines that don't include colorbox
        bodyLineHeight = bodyFont.lineHeight as number
      }

      each(bodyItem.after, fillLineOfText)
    }

    // Reset back to 0 for after body
    xLinePadding = 0
    bodyLineHeight = bodyFont.lineHeight as number

    // After body lines
    each(this.afterBody, fillLineOfText)
    pt.y -= bodySpacing // Remove last body spacing
  }
}

declare module 'chart.js' {
  // noinspection JSUnusedGlobalSymbols
  interface TooltipOptions {
    shadowColor: string
    shadowBlur: number
    shadowOffsetY: number
    beforeLabelTextColor: Color
  }

  interface TooltipPositionerMap {
    followCursorY: TooltipPositionerFunction<ChartType>
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ChartDatasetProperties<TType extends ChartType, TData> {
    /**
     * Per dataset datalabels plugin options.
     * @since 0.1.0
     */
    tooltip?: {
      callbacks?: Partial<
        Pick<
          TooltipCallbacks<TType>,
          'beforeLabel' | 'label' | 'labelColor' | 'labelTextColor' | 'labelPointStyle' | 'afterLabel'
        >
      >
    }
  }
}
