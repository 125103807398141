import { Fragment, useContext, useRef } from 'react'

import { useCurrencyFormat } from '../../hooks/useCurrencyFormat'
import { useNumberFormat } from '../../hooks/useNumberFormat'
import { AccountingContext } from '../../layouts/AccountingLayout'
import { PageContext } from '../../layouts/DashboardLayout'
import { Voucher } from '../../types/accounting'
import { calculateInvoices } from '../../utils/analytics'
import { sum } from '../../utils/calc'
import { DateFyUtils } from '../../utils/date'
import { ChartComponent } from '../common/chart/ChartComponent'
import { DoughnutLegend } from '../common/chart/doughnut-legend/DoughnutLegend'
import { useDoughnutChart } from '../common/chart/useDoughnutChart'
import { Amount } from '../number/Amount'
import { AgeingTable } from './AgeingTable'

import styles from './ageing.module.scss'

export interface AgeingProps {
  ledgers: {
    name: string
    openingBalance: number
    vouchers: Voucher[]
  }[]
  voucherTypes: string[]
  text: {
    section1: string
    section2: string
    section3?: string
    legendTitle: string
    totalAmount: string
    adjustedAmount: string
  }
}

export const Ageing = ({ ledgers, voucherTypes, text }: AgeingProps) => {
  const { company } = useContext(PageContext)
  const { isLatestFy, selectedFy, lastVoucherDate, firstVoucherDate } = useContext(AccountingContext)

  const ledgerInvoices = ledgers
    .map((ledger) => ({
      ...ledger,
      ...calculateInvoices(
        [
          ...(ledger.openingBalance !== undefined
            ? [
                {
                  amount: ledger.openingBalance,
                  voucherType: 'Opening Balance',
                  date: firstVoucherDate,
                  billAllocations: [] as Voucher['billAllocations'],
                } as Voucher,
              ]
            : []),
          ...ledger.vouchers,
        ],
        voucherTypes,
        /*+dayjs(company?.lastSyncDateTime).format('YYYYMMDD') ||*/ isLatestFy
          ? lastVoucherDate
          : DateFyUtils.END_YMD(selectedFy),
      ),
      totalAmount: sum(ledger.vouchers.filter((x) => x.amount >= 0).map((x) => x.amount)),
      adjustedAmount: sum(ledger.vouchers.filter((x) => x.amount < 0).map((x) => x.amount)),
    }))
    .map((ledger) => ({
      ...ledger,
      outstanding: ledger.totalAmount + ledger.adjustedAmount,
      ageing: [
        sum(ledger.invoices.filter((x) => x.ageing <= 30).map((x) => x.outstanding)),
        sum(ledger.invoices.filter((x) => x.ageing > 30 && x.ageing <= 60).map((x) => x.outstanding)),
        sum(ledger.invoices.filter((x) => x.ageing > 60 && x.ageing <= 90).map((x) => x.outstanding)),
        sum(ledger.invoices.filter((x) => x.ageing > 90).map((x) => x.outstanding)),
      ],
    }))
    .filter((ledger) => Math.abs(ledger.outstanding) > 1e-5)

  const positiveLedgerInvoices = ledgerInvoices.filter((x) => x.outstanding > 0)
  const negativeLedgerInvoices = ledgerInvoices.filter((x) => x.outstanding < 0)

  const avgAgeing = positiveLedgerInvoices
    .map((x) =>
      x.invoices.reduce(
        (a, y) => (!isNaN(y.ageing) ? [a[0] + y.ageing * y.outstanding, a[1] + y.outstanding] : [0, 0]),
        [0, 0],
      ),
    )
    .reduce((a, b) => [a[0] + b[0], a[1] + b[1]], [0, 0])

  const ageing = positiveLedgerInvoices.reduce(
    (a, b) => [a[0] + b.ageing[0], a[1] + b.ageing[1], a[2] + b.ageing[2], a[3] + b.ageing[3]],
    [0, 0, 0, 0],
  )

  // console.debug({ ledgerInvoices, avgAgeing, ageing })

  const chartRef = useRef()

  const { formatValueWithCurrency } = useCurrencyFormat()

  const { format: formatDays } = useNumberFormat({ maximumFractionDigits: 0 })

  const { chartProps } = useDoughnutChart({
    labels: ['0-30 Days', '31-60 Days', '61-90 Days', 'Over 90 Days'],
    values: ageing,
    colors: ['#4EC9D1', '#F0AE4D', '#406BAD', '#3792C5'],
    valueFormatter: formatValueWithCurrency,
    centerLabels: [
      { text: formatDays(avgAgeing[0] / avgAgeing[1]), size: 26, weight: '700', color: '#2557A3' },
      { text: 'days', size: 18, color: '#2557A3' },
    ],
    showDatalabels: true,
  })

  return (
    <Fragment>
      <h2 className={styles.title}>{text.section1}</h2>
      <div className={styles.chartWrap}>
        <span className={styles.chart}>
          <ChartComponent chartRef={chartRef} props={chartProps} />
        </span>
        <span className={styles.legend}>
          <DoughnutLegend title={text.legendTitle} chartRef={chartRef} displayTotal={true} ValueFormatter={Amount} />
        </span>
      </div>

      <h2 className={styles.title}>{text.section2}</h2>
      <AgeingTable text={text} ledgerInvoices={positiveLedgerInvoices} />

      {negativeLedgerInvoices.length > 0 && (
        <Fragment>
          <h2 className={styles.title}>{text.section3}</h2>
          <AgeingTable text={text} ledgerInvoices={negativeLedgerInvoices} />
        </Fragment>
      )}
    </Fragment>
  )
}
