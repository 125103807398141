import { Fragment } from 'react'

import styles from './error.module.scss'

export interface ErrorComponentProps {
  title: string
  summary: string
  error?: any
  stack?: string
}

export function ErrorComponent({ title, summary, error, stack }: ErrorComponentProps) {
  return (
    <div className={styles.wrap}>
      <h1>{title}</h1>
      <h2>{summary}</h2>
      {process.env.NODE_ENV === 'development' && error && (
        <div className={styles.errorWrap}>
          <pre className={styles.error}>
            {error.name && <p>Error: {error.name}</p>}
            {error.message && <p>Message: {error.message}</p>}
            {error && (
              <Fragment>
                <p>Error Details:</p>
                <span>
                  {JSON.stringify(error, null, 2)
                    .split('\n')
                    .map((line, index) => (
                      <p key={index}>{line}</p>
                    ))}
                </span>
              </Fragment>
            )}
            {stack && (
              <Fragment>
                <p>Stack:</p>
                <span>{stack && stack.split('\n').map((line, index) => <p key={index}>{line}</p>)}</span>
              </Fragment>
            )}
          </pre>
        </div>
      )}
    </div>
  )
}
