import { useRef } from 'react'
import { Card, NavLink } from 'react-bootstrap'
import { Chart } from 'react-chartjs-2'
import { LinkContainer } from 'react-router-bootstrap'

import { ReactComponent as ProfitIcon } from '../../assets/images/profit.svg'
import { useNumberFormat } from '../../hooks/useNumberFormat'
import { DateFormatUtils } from '../../utils/date'
import { Button } from '../common/button/Button'
import { useGaugeChart } from '../common/chart/useGaugeChart'
import { Amount } from '../number/Amount'

import styles from './summary-profit-card.module.scss'

interface SummaryProfitCardProps {
  profit: number
  profitPercent: number
  linkTo: string
  title: string
  period: string
  compare: {
    changePercent: number
    currMonth: number
    refMonth: number
  }
}

export const SummaryProfitCard = ({
  profit,
  profitPercent,
  linkTo,
  title,
  period,
  compare,
}: SummaryProfitCardProps) => {
  const chartRef = useRef()

  const { chartProps } = useGaugeChart({
    chartRef,
    value: profitPercent / 100,
    total: 1,
    color: '#29A32E',
  })

  const { format } = useNumberFormat({
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    signDisplay: 'never',
  })

  const changeString = compare.changePercent > 0 ? 'Up' : 'Down'
  const changePercent = format(compare.changePercent)
  const currMonth = DateFormatUtils.YM(compare.currMonth, { monthStyle: 'short', spaceStyle: 'dash' })
  const refMonth = DateFormatUtils.YM(compare.refMonth, { monthStyle: 'short', spaceStyle: 'dash' })

  return (
    <Card className={styles.card}>
      <Card.Body className={styles.cardBody}>
        <span className={styles.chart}>
          <Chart ref={chartRef} {...chartProps} />
        </span>
        <span className={styles.detailWrap}>
          <span className={styles.label}>{period}</span>
          <span className={styles.amount}>
            <Amount options={{ maximumFractionDigits: 2 }}>{profit}</Amount>
          </span>
        </span>
        <span className={styles.titleWrap}>
          <span className={styles.title}>{title}</span>
          <span className={styles.compareWrap}>
            {compare.changePercent > 0 && <ProfitIcon className={styles.icon} />}
            <span>{`${changeString} ${changePercent} in ${currMonth} vs ${refMonth}`}</span>
          </span>
          <LinkContainer to={linkTo}>
            <NavLink as={Button} variant={'outline'} className={styles.link}>
              See Details
            </NavLink>
          </LinkContainer>
        </span>
      </Card.Body>
    </Card>
  )
}
