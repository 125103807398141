import { useRef } from 'react'
import { Card } from 'react-bootstrap'

import { useCurrencyFormat } from '../../hooks/useCurrencyFormat'
import { GenericData } from '../../types/accounting'
import { ChartComponent } from '../common/chart/ChartComponent'
import { DoughnutTableLegend } from '../common/chart/doughnut-table-legend/DoughnutTableLegend'
import { useDoughnutChart } from '../common/chart/useDoughnutChart'

import styles from './doughnut-card.module.scss'

export interface DoughnutCardProps {
  data: GenericData[]
  labelHeader: string
  valueHeader: string
  maxItems?: number
}

export const DoughnutCard = ({ data, labelHeader, valueHeader, maxItems = 5 }: DoughnutCardProps) => {
  const chartRef = useRef()
  const { formatValueWithCurrency } = useCurrencyFormat({ maximumFractionDigits: 0 })

  const { chartProps } = useDoughnutChart({
    labels: data.slice(0, maxItems).map((x) => x.label),
    values: data.slice(0, maxItems).map((x) => x.value),
    colors: ['#406BAD', '#E4A54A', '#368FC0', '#4EC9D1', '#039E09'],
    valueFormatter: formatValueWithCurrency,
  })

  return (
    <Card className={styles.doughnutCard}>
      <span className={styles.chart}>{<ChartComponent chartRef={chartRef} props={chartProps} />}</span>
      <span className={styles.tableSection}>
        <span className={styles.table}>
          <DoughnutTableLegend chartRef={chartRef} labelHeader={labelHeader} valueHeader={valueHeader} />
        </span>
      </span>
    </Card>
  )
}
