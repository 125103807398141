import classNames from 'classnames'
import React from 'react'
import { Button as BsButton, ButtonProps as BsButtonProps } from 'react-bootstrap'

import styles from './button.module.scss'

export interface ButtonProps extends BsButtonProps {
  variant?: 'transparent' | 'primary' | 'outline'
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant = 'transparent', ...props }, ref) => {
    return (
      <BsButton
        ref={ref}
        {...props}
        variant={variant}
        className={classNames(className, styles.btn, variant && styles[variant])}
      />
    )
  },
)
