import { useMemo } from 'react'

import { useNumberFormat } from './useNumberFormat'

export const usePercentFormat = (
  options?: Pick<Intl.NumberFormatOptions, 'minimumFractionDigits' | 'maximumFractionDigits'>,
) => {
  const { minimumFractionDigits = 0, maximumFractionDigits = 1 } = options || {}
  options = useMemo(
    () => ({ style: 'percent', ...options, minimumFractionDigits, maximumFractionDigits }),
    [maximumFractionDigits, minimumFractionDigits, options],
  )
  return useNumberFormat(options)
}
