import { RefObject, useContext } from 'react'
import { ChartProps } from 'react-chartjs-2'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

import { SettingsContext } from '../../../app/Settings'
import { MonthlyAggregate } from '../../../types/accounting'
import { DateFormatUtils, DateFyUtils, DateTransformUtils } from '../../../utils/date'
import { useBarChart } from './useBarChart'
import { DefaultScaleOptions } from './useChart'
import { useLegend } from './useLegend'
import { LineBackgroundGradient, relativeAxisLimits } from './utils'

export interface UseComparativeChartProps<TData = any, TLabel = any> {
  chartRef: RefObject<ChartJSOrUndefined<'line', TData, TLabel>>
  refData: MonthlyAggregate[]
  data: MonthlyAggregate[]
  lastDate?: number
}

export const useComparativeChart = ({ chartRef, refData, data, lastDate }: UseComparativeChartProps) => {
  type TType = 'line' | 'bar'
  type TData = any
  type TLabel = string

  const { currency } = useContext(SettingsContext)

  // console.debug('useComparativeChart: chartProps')

  let fyAggregates_ = data

  const lastIdx = lastDate && DateFyUtils.FY_M(lastDate) - 1

  if (lastDate) {
    fyAggregates_ = fyAggregates_.filter((x) => x.yearMonth <= DateTransformUtils.YMD_YM(lastDate))
  }

  const months = DateFyUtils.LIST_YM(DateFyUtils.FY()).map((ym) => DateFormatUtils.M(DateTransformUtils.YM_M(ym)))

  const oldFy = DateFyUtils.FY(refData[0]?.yearMonth * 100)
  const newFy = DateFyUtils.FY(data[0]?.yearMonth * 100)

  const props: ChartProps<TType, TData, TLabel> = {
    type: 'line',
    data: {
      labels: months,
      datasets: [
        {
          label: DateFormatUtils.FY(oldFy),
          data: refData,
          borderColor: '#CB644452',
          borderWidth: 1,
          pointRadius: refData.length === 1 ? 1 : 0,
          pointBackgroundColor: '#CB644452',
          pointHoverRadius: 2,
          fill: 'start',
          backgroundColor: LineBackgroundGradient,
        },
        {
          label: DateFormatUtils.FY(newFy),
          data: fyAggregates_,
          borderColor: '#29A32E78',
          borderWidth: 1,
          pointRadius: fyAggregates_.length === 1 ? 4 : 0,
          pointBackgroundColor: '#29A32E78',
          pointHoverRadius: 2,
          fill: 'start',
          backgroundColor: LineBackgroundGradient,
          datalabels: {
            display: (ctx) => {
              return !!lastDate && ctx.dataIndex === lastIdx
            },
            formatter: () =>
              lastDate !== undefined ? DateFormatUtils.YMD(lastDate, { spaceStyle: 'space', monthStyle: 'short' }) : '',
            backgroundColor: '#303030',
            borderRadius: 4,
            offset: 24,
            clip: false,
          },
        },
      ],
    },
    options: {
      layout: {
        padding: {
          right: 20,
        },
      },
      parsing: {
        xAxisKey: 'yearMonth',
        yAxisKey: 'amount',
      },
      scales: {
        y: {
          offset: true,
          grid: {
            display: false,
          },
          ticks: {
            ...DefaultScaleOptions.ticks,
            format: { style: 'currency', currency, maximumFractionDigits: 0 },
          },
          afterDataLimits: relativeAxisLimits(0, 1.1),
        },
        x: {
          offset: true,
          ticks: {
            font: { weight: '600' },
          },
        },
      },
      plugins: {
        tooltip: {
          mode: 'index',
          intersect: false,
          callbacks: {
            title: () => '',
            label: (item) => {
              const { yearMonth } = item.datasetIndex === 0 ? refData[item.dataIndex] : data[item.dataIndex]
              const label = DateFormatUtils.YM(yearMonth, { spaceStyle: 'space', monthStyle: 'short' })
              const value = item.formattedValue
              return `${label}: ${value}`
            },
          },
        },
        verticalLine: {
          enabled: !!lastDate,
          borderColor: '#303030',
          borderWidth: 2,
          mode: 'data',
          datasetIdx: 1,
          dataIdx: lastIdx,
          offset: 24,
        },
      },
    },
  }

  const { chartProps } = useBarChart<TData, TLabel>({ chartRef, props })
  const ComparativeLegend = useLegend({ chartRef, chartProps })

  return { chartProps, ComparativeLegend }
}
