export const ClosingStockMethods = ['manual', 'weightedAverage'] as const
export const ClosingStockMethodLabels = {
  manual: 'Manual Entries',
  weightedAverage: 'Weighted Average',
}
export type ClosingStockMethod = typeof ClosingStockMethods[number]

export interface Company {
  _id: string
  name: string

  apiKey: string
  lastSyncDateTime: string
  lastTransactionDate: number

  closingStockMethod: ClosingStockMethod
}
