import { useContext } from 'react'

import { useBalanceSheetData } from '../../accounting/useStatementData'
import { StatementTable, StatementTableRow } from '../../components/table/StatementTable'
import { AccountingContext } from '../../layouts/AccountingLayout'
import { DateFyUtils } from '../../utils/date'

import styles from './statement.module.scss'

export const BalanceSheet = () => {
  let { selectedFy, lastVoucherDate } = useContext(AccountingContext)

  const { balanceSheetHeads: headsData, balanceSheetRatios: ratiosData } = useBalanceSheetData({
    startDate: DateFyUtils.START_YMD(selectedFy),
    endDate: Math.min(lastVoucherDate, DateFyUtils.END_YMD(selectedFy)),
  })

  const liabilitiesRows = [
    { data: headsData['Capital Account'], displayName: ['Capital Account'] },
    { data: headsData['Loans (Liability)'], displayName: ['Loans (Liability)'] },
    { data: headsData['Current Liabilities'], displayName: ['Current Liabilities'] },
    { data: headsData['P&L A/c'], displayName: ['P&L A/c'] },
    { data: headsData['Total Liabilities'], displayName: ['Total'], isTotalsRow: true },
  ] as StatementTableRow[]

  const assetsRows = [
    { data: headsData['Fixed Assets'], displayName: ['Fixed Assets'] },
    { data: headsData['Investments'], displayName: ['Investments'] },
    { data: headsData['Current Assets'], displayName: ['Current Assets'] },
    { data: headsData['Total Assets'], displayName: ['Total'], isTotalsRow: true },
  ] as StatementTableRow[]

  const ratioRows = [
    { data: ratiosData['quick'], displayName: ['Quick Ratio'] },
    { data: ratiosData['current'], displayName: ['Current Ratio'] },
    { data: ratiosData['debt'], displayName: ['Debt Ratio'] },
    { data: ratiosData['debtEquity'], displayName: ['Debt to Equity Ratio'] },
    // { data: ratiosData['debtTurnover'], displayName: ['Debt Turnover Ratio'] },
  ] as StatementTableRow[]

  // console.debug({ liabilitiesRows, assetsRows, ratioRows })

  return (
    <div className={styles.page}>
      <StatementTable rows={liabilitiesRows} firstColumnHeading={'Liabilities'} showTotalColumn={false} />
      <StatementTable rows={assetsRows} firstColumnHeading={'Assets'} showTotalColumn={false} />
      <StatementTable rows={ratioRows} firstColumnHeading={'Ratios'} showTotalColumn={false} allowExpansion={false} />
    </div>
  )
}
