import classNames from 'classnames'

import styles from './Loader.module.scss'

export interface LoaderProps {
  position?: 'absolute' | 'relative'
}

export function Loader({ position = 'absolute' }: LoaderProps) {
  return (
    <div className={classNames(styles.loaderWrap, position === 'relative' && styles.relative)}>
      <div className={styles.loader}>
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}
