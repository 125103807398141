import { useCurrencyFormat } from '../../hooks/useCurrencyFormat'
import { AgeingInvoice } from '../../types/accounting'
import { sum } from '../../utils/calc'
import { DateFormatUtils } from '../../utils/date'
import { Amount } from '../number/Amount'
import { Table, TableProps } from '../table/Table'

import styles from './ageing.module.scss'

interface AgeingTableProps {
  ledgerInvoices: AgeingInvoice[]
  text: {
    section1: string
    section2: string
    section3?: string
    legendTitle: string
    totalAmount: string
    adjustedAmount: string
  }
}

export const AgeingTable = ({ ledgerInvoices, text }: AgeingTableProps) => {
  const { currencySymbol, formatValue } = useCurrencyFormat()

  return (
    <Table
      tableProps={
        {
          data: ledgerInvoices,
          columns: [
            { Header: 'Client Name', accessor: 'name', Cell: ({ value }) => <span>{value}</span> },
            {
              Header: '0-30 Days',
              id: '0-30',
              accessor: 'ageing',
              Cell: ({ value }) => (value[0] ? <Amount>{value[0]}</Amount> : '-'),
              Footer: (info) => <Amount>{sum(info.rows.map((x) => x.original.ageing[0]))}</Amount>,
            },
            {
              Header: '31-60 Days',
              id: '31-60',
              accessor: 'ageing',
              Cell: ({ value }) => (value[1] ? <Amount>{value[1]}</Amount> : '-'),
              Footer: (info) => <Amount>{sum(info.rows.map((x) => x.original.ageing[1]))}</Amount>,
            },
            {
              Header: '61-90 Days',
              id: '61-90',
              accessor: 'ageing',
              Cell: ({ value }) => (value[2] ? <Amount>{value[2]}</Amount> : '-'),
              Footer: (info) => <Amount>{sum(info.rows.map((x) => x.original.ageing[2]))}</Amount>,
            },
            {
              Header: 'Over 90 Days',
              id: '90+',
              accessor: 'ageing',
              Cell: ({ value }) => (value[3] ? <Amount>{value[3]}</Amount> : '-'),
              Footer: (info) => <Amount>{sum(info.rows.map((x) => x.original.ageing[3]))}</Amount>,
            },
            {
              Header: 'Total',
              accessor: 'outstanding',
              Cell: ({ value }) => <Amount>{value}</Amount>,
              Footer: (info) => <Amount>{sum(info.rows.map((x) => x.original.outstanding))}</Amount>,
            },
            {
              Header: 'Remarks',
            },
          ],
        } as TableProps<typeof ledgerInvoices[0]>['tableProps']
      }
      showFooter={true}
      rowExpansion={'subComponent'}
      SubComponent={({ row }) => {
        const client = row.original

        return (
          <div className={styles.clientDetails}>
            <table className={styles.statsTable}>
              <tbody>
                <tr className={styles.stat}>
                  <td>{text.totalAmount}</td>
                  <td className={styles.value}>
                    <Amount>{client.totalAmount}</Amount>
                  </td>
                </tr>
                <tr className={styles.stat}>
                  <td>{text.adjustedAmount}</td>
                  <td>
                    <Amount>{-client.adjustedAmount}</Amount>
                  </td>
                </tr>
                <tr className={styles.stat}>
                  <td>Outstanding</td>
                  <td>
                    <Amount>{client.outstanding}</Amount>
                  </td>
                </tr>
              </tbody>
            </table>

            <h2>{client.name} Outstanding Transactions</h2>
            <Table
              variant={'subTable'}
              showFooter={true}
              tableProps={
                {
                  data: client.invoices.filter((x) => x.outstanding !== 0),
                  columns: [
                    { Header: 'S.No.', accessor: (row, index) => index + 1, Footer: 'Total' },
                    {
                      Header: 'Document Date',
                      accessor: 'date',
                      Cell: ({ value }) =>
                        value !== 0 ? DateFormatUtils.YMD(value, { monthStyle: 'short', spaceStyle: 'space' }) : '-',
                    },
                    { Header: 'Transaction Type', accessor: 'voucherType' },
                    { Header: 'Document No.', accessor: 'voucherNumber' },
                    {
                      Header: 'Ageing',
                      accessor: 'ageing',
                      Cell: ({ value }: { value: number }) =>
                        !isNaN(value) ? `${value} ${value === 1 ? 'day' : 'days'}` : '-',
                    },
                    {
                      Header: `Transaction Amt. (${currencySymbol})`,
                      accessor: (row) => formatValue(Math.abs(row.amount)),
                      Footer: ({ data }) => formatValue(sum(data.map((x) => x.amount))),
                    },
                    {
                      Header: `Outstanding Amt. (${currencySymbol})`,
                      accessor: (row) => formatValue((row as any).outstanding),
                      Footer: ({ data }) => formatValue(sum(data.map((x) => (x as any).outstanding))),
                    },
                  ],
                } as TableProps<typeof client.invoices[0]>['tableProps']
              }
              // rowExpansion={'subComponent'}
              // SubComponent={({ row: subRow }) => <InvoiceDetails invoice={subRow.original} />}
            />
          </div>
        )
      }}
    />
  )
}
