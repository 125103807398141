import { ChartType, ScaleChartOptions } from 'chart.js'
import { RefObject, useEffect } from 'react'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

export interface UseCartesianScaleProps<TType extends ChartType, TData, TLabel> {
  chartRef?: RefObject<ChartJSOrUndefined<TType, TData, TLabel>>
  visibleCount?: number
}

export const useCartesianScale = <TType extends ChartType, TData, TLabel>({
  chartRef,
  visibleCount,
}: UseCartesianScaleProps<TType, TData, TLabel>) => {
  useEffect(() => {
    const { data, options } = chartRef?.current || {}
    const { scales } = (options as ScaleChartOptions<TType>) || {}
    // console.debug('useCartesianScale: useEffect', { x: scales?.x, labels: data?.labels, visibleCount })

    if (scales?.x && data?.labels && visibleCount !== undefined && visibleCount > 0) {
      // console.debug('useCartesianScale: useEffect: updating x scale')

      const maxCount = visibleCount > data.labels.length ? data.labels.length : visibleCount
      const minX = data.labels[data.labels.length - maxCount]

      scales.x.min = `${minX}`
      chartRef?.current?.update()
    }
  })
}
