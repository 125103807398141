import { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as ChevronIcon } from '../../assets/images/chevron.svg'

import styles from './topbar.module.scss'

export interface TopbarBreadcrumbProps {
  items: { label: string; link: string }[] | string[]
}

export const TopbarBreadcrumb = ({ items }: TopbarBreadcrumbProps) => {
  const [lastItem, ...parentItems] = items.slice().reverse()

  return (
    <span>
      <span className={styles.breadcrumb}>
        {parentItems.map((item, i) => (
          <Fragment key={i}>
            {typeof item !== 'string' ? (
              <Link to={item.link || '#'}>
                <span>{item.label}</span>
              </Link>
            ) : (
              <span>{item}</span>
            )}
            <ChevronIcon className={styles.separator} />
          </Fragment>
        ))}
      </span>
      {typeof lastItem !== 'string' ? (
        <Link to={lastItem.link || '#'}>
          <span className={styles.title}>{lastItem.label}</span>
        </Link>
      ) : (
        <span className={styles.title}>{lastItem}</span>
      )}
    </span>
  )
}
