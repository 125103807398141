import { AxiosError } from 'axios'
import { createContext, useContext, useState } from 'react'
import { useQuery } from 'react-query'

import { ConfigContext } from '../app/Config'
import { Loader } from '../components/loader/Loader'
import { AuthClient } from './AuthClient'

export interface AuthState {
  client: AuthClient
}

export const AuthContext = createContext<AuthState>({} as AuthState)

export const AuthContextProvider = ({ children }: any) => {
  const config = useContext(ConfigContext)

  const [client] = useState(new AuthClient({ baseUrl: config.apiURL, googleClientId: config.googleClientId }))

  const { isLoading } = useQuery<unknown, AxiosError>('token', () => client.refreshToken(), {
    staleTime: 1000 * 60 * 60,
    refetchOnReconnect: false,
    refetchOnMount: false,
    enabled: !client.isAuthenticated,
  })

  if (isLoading) {
    return <Loader />
  } else {
    return <AuthContext.Provider value={{ client }}>{children}</AuthContext.Provider>
  }
}
