import { RefObject, useEffect, useState } from 'react'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import equal from 'react-fast-compare'

export interface UseDoughnutLegendProps {
  chartRef: RefObject<ChartJSOrUndefined<'doughnut', number[], string>>
}

export const useDoughnutLegend = ({ chartRef }: UseDoughnutLegendProps) => {
  const [total, setTotal] = useState<number>()
  const [legendItems, setLegendItems] = useState<DoughnutLegendItem[]>([])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const labels = chartRef.current.data.labels
    const dataset = chartRef.current.data.datasets[0]

    const _total = dataset.data.reduce((a, b) => a + b, 0)
    setTotal(_total)

    if (labels) {
      const items = labels.map((label, dataIdx) => ({
        label,
        value: dataset.data[dataIdx],
        color: (dataset.backgroundColor && ((dataset.backgroundColor as string[])[dataIdx] as string)) || '',
      }))
      // console.debug('DoughnutLegend: setting legend items: items=%o', items)

      if (!equal(legendItems, items)) {
        setLegendItems(items)
      }
    }
  })

  return { legendItems, total }
}

export interface DoughnutLegendItem {
  label: string
  value: number
  color: string
}
