import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { CSSProperties, Fragment, ReactElement } from 'react'
import { Table as BsTable } from 'react-bootstrap'
import { Row, TableInstance, UseExpandedRowProps, UseTableOptions, useExpanded, useTable } from 'react-table'

import styles from './table.module.scss'

export interface TableProps<T extends object = {}> {
  tableProps: UseTableOptions<T>
  variant?: 'normal' | 'striped' | 'subTable' | 'fullWidth'
  hover?: boolean
  rowExpansion?: 'subRows' | 'subComponent'
  SubComponent?: (args: { row: Row<T> }) => ReactElement
  showFooter?: boolean
}

export const Table = <T extends object = {}>({
  tableProps,
  variant = 'normal',
  hover,
  rowExpansion,
  SubComponent,
  showFooter = false,
}: TableProps<T>) => {
  const plugins = []

  if (!!rowExpansion) {
    plugins.push(useExpanded)
  }

  const table = useTable<T>(tableProps, ...plugins) as TableInstance<T>

  return (
    <div className={classNames(styles.panel, styles[variant])}>
      <BsTable
        {...table.getTableProps}
        hover={hover}
        className={classNames(styles.table)}
        responsive={true}
        striped={variant === 'striped'}
        borderless={variant === 'striped'}
      >
        <thead>
          {table.headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((header) => (
                <th {...header.getHeaderProps([{ className: header.className }])}>{header.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...table.getTableProps()} className={styles.tbody}>
          {table.rows.map((row) => {
            table.prepareRow(row)
            const { getToggleRowExpandedProps, isExpanded } = row as Row<T> & UseExpandedRowProps<T>

            return (
              <Fragment key={row.getRowProps().key}>
                <tr
                  {...row.getRowProps([
                    {
                      className: classNames(
                        rowExpansion && (row.original as any).canExpand !== false && isExpanded && styles.expanded,
                      ),
                    },
                  ])}
                  {...(rowExpansion && (row.original as any).canExpand !== false && getToggleRowExpandedProps())}
                >
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps([{ className: cell.column.className, style: cell.column.styles }])}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
                {rowExpansion === 'subComponent' && !!SubComponent && (
                  <tr>
                    <td
                      className={classNames([styles.subRow, isExpanded && styles.expanded])}
                      colSpan={table.visibleColumns.length}
                    >
                      <AnimatePresence>
                        {isExpanded && (
                          <motion.div
                            initial={{ height: 0, overflow: 'hidden' }}
                            animate={{ height: 'auto', overflow: 'hidden' }}
                            exit={{ height: 0, overflow: 'hidden' }}
                            transition={{ ease: 'easeInOut' }}
                          >
                            <SubComponent row={row} />
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </td>
                  </tr>
                )}
              </Fragment>
            )
          })}
        </tbody>
        {showFooter && (
          <tfoot>
            {table.footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </BsTable>
    </div>
  )
}

declare module 'react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface UseTableColumnOptions<D extends object> {
    className?: string
    classNames?: string[]
    styles?: CSSProperties
  }
}
