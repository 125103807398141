import React, { Fragment, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { useNumberFormat } from '../../../hooks/useNumberFormat'

import styles from './ratio-card.module.scss'

export interface RatioCardProps {
  ratioName?: string
  title: string
  description: string
  value: number
  format?: 'decimal' | 'percent' | 'factor'
}

export const RatioCard = ({ ratioName, title, description, value, format = 'decimal' }: RatioCardProps) => {
  const { format: decimalFormat } = useNumberFormat({ maximumFractionDigits: 2 })
  const { format: numberFormat } = useNumberFormat({ maximumFractionDigits: 1 })

  const formattedValue =
    Math.abs(value) !== Infinity && !isNaN(value)
      ? format === 'decimal'
        ? decimalFormat(value)
        : format === 'percent'
        ? numberFormat(value * 100)
        : numberFormat(value) + 'x'
      : '-'

  const ref = React.createRef<HTMLDivElement>()
  useEffect(() => {
    const e = ref.current

    if (e) {
      e.style.removeProperty('font-size')
      const style = window.getComputedStyle(e)
      const fontSize = +style.fontSize.split('px')[0]
      e.style.fontSize = `${(fontSize * e.clientWidth) / e.scrollWidth}px`
    }
  }, [ref])

  return (
    <Card className={styles.card}>
      <div className={styles.text}>
        <Link to={ratioName ? ratioName : ''} className={styles.title}>
          <div>{title}</div>
        </Link>
        <div className={styles.desc}>{description}</div>
      </div>
      <div ref={ref} className={styles.value}>
        {formattedValue !== '-' ? (
          <Fragment>
            {formattedValue}
            {format === 'percent' && <span className={styles.percent}>%</span>}
          </Fragment>
        ) : (
          <span className={styles.empty}>-</span>
        )}
      </div>
    </Card>
  )
}
