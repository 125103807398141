import React, { useState } from 'react'

import { Company } from '../../../types/company'
import Dropdown from '../../common/dropdown/Dropdown'
import { Logo } from '../../logo/Logo'

import styles from './company-selector.module.scss'

export interface CompanySelectorProps {
  selected?: string
  companies: Company[]
  onSelect?: (selectedCompany: Company) => void
  show?: boolean
}

export const CompanySelector = ({ selected, companies, onSelect, show }: CompanySelectorProps) => {
  let selectedCompany = companies.filter((company) => company._id === selected)[0]

  if (!selectedCompany) {
    selected = companies[0]._id
    selectedCompany = companies[0]
  }

  const [selectedState, setSelectedState] = useState(selectedCompany)

  return (
    <Dropdown show={show}>
      <Dropdown.Toggle variant={'transparent'}>
        <span className={styles.selected}>
          <Logo className={styles.appLogo} />
          <span className={styles.selectedDetails}>
            <span className={styles.appName}>Counts</span>
            <span className={styles.companyName}>{selectedState.name}</span>
          </span>
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu variant={'fit'} className={styles.menu}>
        {companies
          .filter((company) => company._id !== selected)
          .map((company) => (
            <Dropdown.Item
              key={company._id}
              className={styles.menuItem}
              onClick={() => {
                setSelectedState(company)
                onSelect && onSelect(company)
              }}
            >
              {company.name}
            </Dropdown.Item>
          ))}
        <Dropdown.Item key={'all-companies'} className={styles.menuItem}>
          All Companies
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
