import { RefObject } from 'react'
import { Table as BsTable } from 'react-bootstrap'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

import { Amount } from '../../../number/Amount'
import { UseDoughnutLegendProps, useDoughnutLegend } from '../doughnut-legend/useDoughnutLegend'

import styles from './doughnut-table-legend.module.scss'

export interface DoughnutTableLegendProps extends UseDoughnutLegendProps {
  chartRef: RefObject<ChartJSOrUndefined<'doughnut', number[], string>>
  labelHeader: string
  valueHeader: string
}

export const DoughnutTableLegend = ({ chartRef, labelHeader, valueHeader }: DoughnutTableLegendProps) => {
  const { legendItems } = useDoughnutLegend({ chartRef })

  return (
    <div className={styles.table}>
      <BsTable borderless={true}>
        <thead className={styles.header}>
          <tr>
            <th>{labelHeader}</th>
            <th>{valueHeader}</th>
          </tr>
        </thead>
        <tbody>
          {legendItems.map((legendItem, i) => (
            <tr key={i}>
              <td className={styles.labelCol}>
                <div className={styles.color} style={{ backgroundColor: legendItem.color }} />
                <div>{legendItem.label}</div>
              </td>
              <td>
                <Amount>{legendItem.value}</Amount>
              </td>
            </tr>
          ))}
        </tbody>
      </BsTable>
    </div>
  )
}
