import { Fragment, useContext, useMemo } from 'react'

import { AnalyticsStat } from '../../components/analytics/AnalyticsStat'
import { ComparativeCard } from '../../components/analytics/ComparativeCard'
import { DoughnutCard } from '../../components/analytics/DoughnutCard'
import { MonthlyChart } from '../../components/analytics/MonthlyChart'
import { AccountingContext } from '../../layouts/AccountingLayout'
import { Voucher } from '../../types/accounting'
import { calculateAnalyticsData } from '../../utils/analytics'
import { DateFormatUtils, DateTransformUtils } from '../../utils/date'

import styles from './analytics.module.scss'

export const Expenses = () => {
  // console.debug('rendering expenses page')

  const { vouchers, selectedFy } = useContext(AccountingContext)

  const expenseVouchers: Voucher[] = useMemo(
    () =>
      vouchers
        .filter(
          (x: Voucher) =>
            x.master.superParent?.name === 'Direct Expenses' || x.master.superParent?.name === 'Indirect Expenses',
        )
        .map((x: Voucher) => ({ ...x, amount: -x.amount })),
    [vouchers],
  )

  const analyticsData = calculateAnalyticsData(expenseVouchers, selectedFy)

  const fyString = DateFormatUtils.FY(analyticsData.fy)

  const vendorsData = analyticsData.grouped.partyName.map((x) => ({ ...x, label: x.label || 'Other Liabilities' }))
  const productsData = analyticsData.grouped.name

  return (
    <Fragment>
      <span className={styles.stats}>
        <span className={styles.mainStat}>
          <AnalyticsStat
            title={'Expenses'}
            mainStat={{ label: fyString, value: analyticsData.totals.fy }}
            secondaryStat={{
              label: analyticsData.isLatestFY
                ? DateFormatUtils.M(DateTransformUtils.YMD_M(analyticsData.lastVoucherDate.fy), 'long')
                : DateFormatUtils.FY(analyticsData.refFy),
              value: analyticsData.totals.refAmount,
            }}
          />
        </span>
      </span>

      <h2 className={styles.title}>Comparative Data</h2>
      <ComparativeCard analyticsData={analyticsData} />

      <h2 className={styles.title}>Expenses by Vendors</h2>
      <DoughnutCard data={vendorsData} labelHeader={'Top Vendors'} valueHeader={'Current Balance (₹)'} />

      <h2 className={styles.title}>Expenses by Categories</h2>
      <DoughnutCard data={productsData} labelHeader={'Top Categories'} valueHeader={'Current Balance (₹)'} />

      <h2 className={styles.title}>Monthly Expense History</h2>
      <MonthlyChart data={analyticsData.aggregations.fy} />
    </Fragment>
  )
}
