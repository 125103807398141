import classNames from 'classnames'

import { LedgerInvoice } from '../../types/accounting'
import { DateFormatUtils } from '../../utils/date'
import { Amount } from '../number/Amount'
import { Table, TableProps } from '../table/Table'
import { InvoiceDetails } from './InvoiceDetails'

import styles from './invoice-tracker.module.scss'

interface InvoiceTableProps {
  ledgerInvoices: LedgerInvoice[]
}

export const InvoiceTable = ({ ledgerInvoices }: InvoiceTableProps) => {
  const data = ledgerInvoices
    .slice()
    .map((invoice) => ({ ...invoice, payment: invoice.amount - invoice.outstanding }))
    .sort(
      (a, b) =>
        (Math.abs(a.payment) < 1e-5 ? (Math.abs(b.payment) < 1e-5 ? 0 : -1) : Math.abs(b.payment) < 1e-5 ? 1 : 0) ||
        (a.payment < a.amount ? (b.payment < b.amount ? 0 : -1) : b.payment < b.amount ? 1 : 0) ||
        (a.payment > a.amount ? (b.payment > b.amount ? 0 : -1) : b.payment > b.amount ? 1 : 0) ||
        b.date - a.date - 1,
    )

  return (
    <Table
      variant={'normal'}
      tableProps={
        {
          data: data,
          columns: [
            {
              Header: 'Invoice Date',
              accessor: 'date',
              Cell: ({ value }) => DateFormatUtils.YMD(value, { monthStyle: 'short', spaceStyle: 'dash' }),
            },
            { Header: 'Invoice No.', accessor: 'voucherNumber' },
            { Header: 'Party Name', accessor: 'ledgerName' },
            {
              Header: 'Invoice Total',
              accessor: (row) => row.amount,
              Cell: ({ value }: { value: number }) => <Amount>{value}</Amount>,
            },
            {
              Header: 'Payment Made',
              accessor: 'payment',
              Cell: ({ value }) => <Amount>{value}</Amount>,
            },
            {
              Header: 'Status',
              accessor: (row) => {
                const paymentMade = row.amount - row.outstanding
                const text =
                  Math.abs(row.outstanding) < 1e-5
                    ? ['FULLY PAID', 'fullyPaid']
                    : Math.abs(paymentMade) < 1e-5
                    ? ['UNPAID', 'unpaid']
                    : row.outstanding < 0
                    ? ['OVERPAID', 'overpaid']
                    : ['PARTIALLY PAID', 'partiallyPaid']

                return <span className={classNames(styles.status, styles[text[1]])}>{text[0]}</span>
              },
            },
          ],
        } as TableProps<typeof data[0]>['tableProps']
      }
      rowExpansion={'subComponent'}
      SubComponent={({ row: subRow }) => <InvoiceDetails invoice={subRow.original} />}
    />
  )
}
