import { useContext } from 'react'

import { Ageing } from '../../components/invoice/Ageing'
import { AccountingContext } from '../../layouts/AccountingLayout'
import { Voucher } from '../../types/accounting'
import { DateFyUtils } from '../../utils/date'

export const PayableAgeing = () => {
  const { vouchers, selectedFy } = useContext(AccountingContext)

  const selectedFyEndDate = DateFyUtils.END_YMD(selectedFy)

  const ledgers = Object.entries(
    vouchers
      .filter((x) => x.master.parent?.name === 'Sundry Creditors' && x.date <= selectedFyEndDate)
      // .map((x) => ({
      //   ...x,
      //   amount: -x.amount,
      //   billAllocations: x.billAllocations.map((b) => ({ ...b, amount: -b.amount })),
      // }))
      .reduce((a, x) => ({ ...a, [x.master.name]: [...(a[x.master.name] || []), x] }), {} as Record<string, Voucher[]>),
  ).map(([ledgerName, ledgerVouchers]) => ({
    name: ledgerName,
    openingBalance: ledgerVouchers[0]?.master.openingBalance || 0,
    vouchers: ledgerVouchers.sort((a, b) => a.date - b.date),
  }))

  return (
    <Ageing
      ledgers={ledgers}
      voucherTypes={['Purchase', 'Credit Note']}
      text={{
        section1: 'Creditor Days',
        section2: 'Account Payable Ageing',
        section3: 'Advance to Supplier Ageing',
        legendTitle: 'Total Payable',
        totalAmount: 'Payable',
        adjustedAmount: 'Paid',
      }}
    />
  )
}
