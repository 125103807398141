import React, { Fragment, useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { AuthContext } from '../auth/AuthContext'
import { SESSION_STORAGE_RESTORE_PATH_KEY } from '../constants'

export const PublicLayout = () => {
  const {
    client: { isAuthenticated },
  } = useContext(AuthContext)

  if (isAuthenticated) {
    const restorePath = sessionStorage.getItem(SESSION_STORAGE_RESTORE_PATH_KEY) || '/'
    // sessionStorage.removeItem(SESSION_STORAGE_RESTORE_PATH_KEY)
    return <Navigate to={restorePath} />
  }

  return (
    <Fragment>
      <Outlet />
    </Fragment>
  )
}
