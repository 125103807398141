import { Color } from '@kurkle/color'
import { ActiveElement, FontSpec, Scale, Scriptable, ScriptableContext } from 'chart.js'
import { toFontString } from 'chart.js/helpers'
import { Context } from 'chartjs-plugin-datalabels/types/context'

export const relativeAxisLimits = (minRatio: number = 1, maxRatio: number = 1) => {
  return (axis: Scale) => {
    let { min, max } = axis.getMinMax(false)
    min = Math.abs(min) === Infinity ? 0 : min
    max = Math.abs(max) === Infinity ? 0 : max
    axis.min = min * minRatio
    axis.max = max * maxRatio
  }
}

const checkActiveDataset = (
  activeElements: ActiveElement[],
  datasetIndex: number,
  inverse = false,
  showWithTooltip = false,
  tooltipActiveElements: ActiveElement[] = [],
) => {
  const condition = activeElements.some((activeElement) =>
    inverse ? activeElement.datasetIndex !== datasetIndex : activeElement.datasetIndex === datasetIndex,
  )

  return showWithTooltip
    ? condition
    : condition &&
        !tooltipActiveElements.some((activeElement) =>
          inverse ? activeElement.datasetIndex !== datasetIndex : activeElement.datasetIndex === datasetIndex,
        )
}

export const isDatasetActive = (ctx: Context) => {
  return checkActiveDataset(
    ctx.chart.getActiveElements(),
    ctx.datasetIndex,
    false,
    false,
    ctx.chart.tooltip?.getActiveElements(),
  )
}

export const isOtherDatasetActive = (ctx: Context) => {
  return checkActiveDataset(
    ctx.chart.getActiveElements(),
    ctx.datasetIndex,
    true,
    true,
    ctx.chart.tooltip?.getActiveElements(),
  )
}

interface ExtendedFontSpec extends FontSpec {
  color: string
}

export const measureTexts = (ctx: CanvasRenderingContext2D, texts: string[], specs: ExtendedFontSpec[] = []) => {
  ctx.save()
  let textWidth = 0

  for (let i = 0; i < texts.length; i++) {
    const text = texts[i]
    const spec = specs[i]

    if (spec) {
      ctx.font = toFontString(spec as any)!
    }

    const textSize = ctx.measureText(text)
    textWidth += textSize.width
    if (i < texts.length - 1) {
      textWidth += 0
    }
  }

  ctx.restore()
  return textWidth
}

export const drawTexts = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  texts: string[],
  specs: ExtendedFontSpec[] = [],
) => {
  // texts = texts.map((x) => ({ ...x }))
  ctx.save()
  const textWidth = measureTexts(ctx, texts, specs)

  x = x - textWidth / 2

  for (let i = 0; i < texts.length; i++) {
    const text = texts[i]
    const spec = specs[i]

    if (spec) {
      ctx.font = toFontString(spec as any)!
    }

    const textSize = ctx.measureText(text)

    if (spec) {
      ctx.fillStyle = spec.color
    }

    ctx.fillText(text, x, y)
    x += textSize.width
    if (i < texts.length - 1) {
      x += 0
    }
  }

  ctx.restore()
}

export type ValueFormatter = (v: number, l?: string) => string
export const DefaultValueFormatter: ValueFormatter = (v) => `${v}`

export const LineBackgroundGradient: Scriptable<CanvasGradient, ScriptableContext<'line'>> = (
  ctx: any,
  options: any,
) => {
  const datasetMeta = ctx.chart.getDatasetMeta(ctx.datasetIndex)

  const maxDatapoint = Math.max(...datasetMeta._parsed.map((e: any) => e.y))
  const maxDatapointY = datasetMeta.vScale?.getPixelForValue(maxDatapoint)

  const { bottom } = ctx.chart.chartArea || {}

  const colorStop1 = new Color(options.borderColor as string).alpha(0.15).rgbString()
  const colorStop2 = new Color(options.borderColor as string).alpha(0).rgbString()

  if (maxDatapointY && bottom) {
    const gradient = ctx.chart.ctx.createLinearGradient(0, maxDatapointY, 0, bottom)
    gradient.addColorStop(0.29, colorStop1)
    gradient.addColorStop(1, colorStop2)

    return gradient
  } else {
    return undefined
  }
}
