import { RefObject, useMemo } from 'react'
import { ChartProps } from 'react-chartjs-2'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

import { useCurrencyFormat } from '../../../hooks/useCurrencyFormat'
import { usePercentFormat } from '../../../hooks/usePercentFormat'
import { DateFormatUtils } from '../../../utils/date'
import { UseBarChartProps, useBarChart } from './useBarChart'
import { DefaultScaleOptions } from './useChart'
import { useLegend } from './useLegend'
import { LineBackgroundGradient, relativeAxisLimits } from './utils'

const REVENUE_BAR_COLOR = '#6896DA'
const EXPENSE_BAR_COLOR = '#B3C0D3'
const PROFIT_POSITIVE_COLOR = '#29A32E'
const PROFIT_NEGATIVE_COLOR = 'darkred'

export interface UseRevenueChartProps<TData = any, TLabel = string>
  extends Pick<UseBarChartProps<TData, TLabel>, 'chartRef' | 'visibleCount' | 'showPanLimits'> {
  chartRef: RefObject<ChartJSOrUndefined<'bar' | 'line', TData, TLabel>>
  data: any[]
}

export const useRevenueChart = ({ chartRef, data, ...barChartProps }: UseRevenueChartProps) => {
  // console.debug('useRevenueChart')

  type TType = 'bar' | 'line'
  type TData = any
  type TLabel = string

  const { formatValue, formatOptions } = useCurrencyFormat({ maximumFractionDigits: 0 })

  const percentOptions = useMemo(() => ({ style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 2 }), [])
  let { format: profitFormatter } = usePercentFormat(percentOptions)

  // console.debug('useRevenueChart: chartProps')
  const props: ChartProps<TType, TData, TLabel> = {
    type: 'bar',
    data: {
      labels: data.map((x: any) => DateFormatUtils.YM(x.month, { spaceStyle: 'space', monthStyle: 'short' })),
      datasets: [
        {
          label: 'Revenue',
          data: data.filter((x) => x.revenue !== undefined).map((x: any) => x.revenue),
          backgroundColor: REVENUE_BAR_COLOR,
          datalabels: {
            backgroundColor: REVENUE_BAR_COLOR,
          },
        },
        {
          label: 'Total Expense',
          data: data.filter((x) => x.expense !== undefined).map((x: any) => x.expense),
          backgroundColor: EXPENSE_BAR_COLOR,
          datalabels: {
            backgroundColor: EXPENSE_BAR_COLOR,
          },
        },
        {
          label: 'Net Profit',
          data: data.filter((x) => x.profitPercent !== undefined).map((x: any) => x.profitPercent / 100),
          type: 'line',
          yAxisID: 'y2',
          order: -1,
          borderColor: PROFIT_POSITIVE_COLOR,
          pointBorderColor: data.map((x: any) =>
            x.profitPercent >= 0 ? PROFIT_POSITIVE_COLOR : PROFIT_NEGATIVE_COLOR,
          ),
          pointBackgroundColor: '#FFFFFF',
          fill: 'start',
          backgroundColor: LineBackgroundGradient,
          datalabels: {
            backgroundColor: data.map((x: any) =>
              x.profitPercent >= 0 ? PROFIT_POSITIVE_COLOR : PROFIT_NEGATIVE_COLOR,
            ),
            formatter: profitFormatter,
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                return `${tooltipItem.dataset.label}: ${formatValue(data[tooltipItem.dataIndex].profit)} (${
                  tooltipItem.formattedValue
                })`
              },
            },
          },
        },
      ],
    },
    options: {
      layout: { padding: { right: -8 } },
      scales: {
        y: {
          afterDataLimits: relativeAxisLimits(1, 1.05),
          ticks: { ...DefaultScaleOptions.ticks, format: formatOptions },
        },
        y2: {
          ...DefaultScaleOptions,
          position: 'right',
          afterDataLimits: (axis) => {
            const { min, max } = axis.getMinMax(false)
            const shift = Math.max(max, max - min) * 2
            axis.max += shift
          },
          ticks: { ...DefaultScaleOptions.ticks, format: percentOptions },
        },
      },
      elements: {},
      interaction: {
        mode: 'index',
      },
      plugins: {
        verticalLine: { enabled: true, borderColor: PROFIT_POSITIVE_COLOR },
        zoom: {
          pan: { enabled: true, mode: 'x' },
        },
        tooltip: {
          intersect: false,
          callbacks: {
            // label: (tooltipItem: TooltipItem<TType>): string => `${tooltipItem.parsed.y}`,
          },
        },
        datalabels: {
          display: (ctx) => {
            return !!ctx.dataset.data[ctx.dataIndex]
          },
        },
      },
    },
  }

  const { chartProps } = useBarChart<TData, TLabel>({ chartRef, props, ...barChartProps })

  const RevenueLegend = useLegend({
    chartRef,
    chartProps,
    showDatalabels: true,
    showActiveDatasets: true,
    defaultActiveDatasets: [2],
  })

  return { chartProps, RevenueLegend }
}
