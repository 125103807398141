import classNames from 'classnames'
import { createRef, memo, useEffect, useMemo } from 'react'
import { Table as BsTable } from 'react-bootstrap'
import { Row, TableOptions, UseExpandedOptions, UseExpandedRowProps, useExpanded, useTable } from 'react-table'

import { StatementData } from '../../accounting/useStatementData'
import { ReactComponent as ExpandIcon } from '../../assets/icons/expand.svg'
import { useNumberFormat } from '../../hooks/useNumberFormat'
import { isNearZero } from '../../utils/calc'
import { DateFormatUtils } from '../../utils/date'

import styles from './table.module.scss'

export interface StatementTableRow {
  displayName: string[]
  data: StatementData
  isPositive?: boolean
  isTotalsRow?: boolean
}

interface StatementTableProps {
  rows: StatementTableRow[]
  firstColumnHeading: string
  showTotalColumn?: boolean
  totalColumnHeading?: string
  allowExpansion?: boolean
}

const DataCell = ({
  row,
  format,
  value,
}: {
  row: Row<StatementTableRow>
  format: (x: number) => string
  value: number
}) => {
  const _row = row as typeof row & UseExpandedRowProps<StatementTableRow>

  return (
    <span style={{ marginRight: `${1.5 * _row.depth}rem` }}>
      {!isNearZero(value) && !isNaN(value) ? format(value) : ''}
    </span>
  )
}

export const StatementTable = memo(
  ({
    rows,
    firstColumnHeading,
    showTotalColumn = false,
    totalColumnHeading,
    allowExpansion = true,
  }: StatementTableProps) => {
    const numberFormatOptions = useMemo(() => ({ minimumFractionDigits: 2, maximumFractionDigits: 2 }), [])
    const { format } = useNumberFormat(numberFormatOptions)

    const ref = createRef<HTMLTableHeaderCellElement>()

    useEffect(() => {
      ref.current?.scrollIntoView({ block: 'end', inline: 'start' })
    })

    const showIndicator = rows.some((x) => x.isPositive !== undefined)

    const columns: TableOptions<typeof rows[number]>['columns'] = useMemo(() => {
      const yearMonths = rows[0]?.data.monthly.map((x) => x.yearMonth) || []
      return [
        {
          Header: firstColumnHeading,
          accessor: 'displayName',
          Cell: ({ row }) => {
            const _row = row as typeof row & UseExpandedRowProps<StatementTableRow>
            return (
              <span className={styles.particularName} style={{ marginLeft: `${1.5 * _row.depth}rem` }}>
                {showIndicator && (
                  <span className={styles.indicator}>
                    {row.original.isPositive !== undefined && (row.original.isPositive ? '+' : '-')}
                  </span>
                )}
                <span className={styles.textWrap}>
                  <span>{(row.original.displayName && row.original.displayName[0]) || row.original.data?.name}</span>
                  {row.original.displayName?.slice(1).map((x, i) => (
                    <span key={i} className={styles.secondaryText}>
                      {x}
                    </span>
                  ))}
                </span>
                {_row.canExpand && <ExpandIcon {..._row.getToggleRowExpandedProps()} />}
              </span>
            )
          },
          className: styles.firstCol,
        },
        ...yearMonths.map(
          (yearMonth, i) =>
            ({
              Header: DateFormatUtils.YM(yearMonth, { monthStyle: 'long', spaceStyle: 'dash' }),
              accessor: (row) => row.data?.monthly && row.data?.monthly[i]?.amount,
              Cell: ({ row }: { row: Row<StatementTableRow> }) =>
                DataCell({ row, format, value: row.original.data?.monthly && row.original.data?.monthly[i]?.amount }),
              classNames: [
                styles.dataCol,
                ...(!showTotalColumn && i === yearMonths.length - 1 ? [styles.lastCol] : []),
              ],
            } as typeof columns[number]),
        ),
        ...(showTotalColumn
          ? [
              {
                Header: totalColumnHeading,
                accessor: (row) => row.data?.total,
                Cell: ({ row }: { row: Row<StatementTableRow> }) =>
                  DataCell({ row, format, value: row.original.data?.total }),
                classNames: [styles.lastCol, styles.dataCol],
              } as typeof columns[number],
            ]
          : []),
      ]
    }, [firstColumnHeading, format, rows, showIndicator, showTotalColumn, totalColumnHeading])

    const plugins = []

    if (allowExpansion) {
      plugins.push(useExpanded)
    }

    const tableOptions: TableOptions<StatementTableRow> & UseExpandedOptions<StatementTableRow> = {
      data: rows,
      columns: columns,
      autoResetExpanded: false,
      getSubRows: (originalRow) => originalRow.data?.children?.map((x) => ({ data: x })) as StatementTableRow[],
    }

    const table = useTable<StatementTableRow>(tableOptions, ...plugins)

    return (
      <div className={classNames(styles.panel, styles.statement)}>
        <BsTable responsive={true} {...table.getTableProps()} className={classNames(styles.table)} borderless={true}>
          <thead>
            {table.headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((header, i) => {
                  const cellProps = i === headerGroup.headers.length - 2 ? { ref: ref } : {}

                  return (
                    <th
                      {...header.getHeaderProps()}
                      className={classNames(header.className, ...(header.classNames || []))}
                      {...cellProps}
                    >
                      {header.render('Header')}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody {...table.getTableBodyProps()} className={styles.tbody}>
            {table.rows.map((row, i) => {
              table.prepareRow(row)
              const _row = row as typeof row & UseExpandedRowProps<StatementTableRow>
              const nextRow = table.rows[i + 1] as typeof _row

              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className={classNames(
                        cell.column.className,
                        ...(cell.column.classNames || []),
                        _row.depth === 0 && styles.topRow,
                        row.original.isTotalsRow === true && styles.totalsRow,
                        nextRow?.depth === 0 && styles.lastSubRow,
                      )}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </BsTable>
      </div>
    )
  },
)
