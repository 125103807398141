import classNames from 'classnames'
import { Card } from 'react-bootstrap'
import { useQuery } from 'react-query'

import { useApiClient } from '../../hooks/useApiClient'
import { DateFormatUtils } from '../../utils/date'
import { Amount } from '../number/Amount'
import { LiquidityCardTitle } from './LiquidityCardTitle'

import styles from './liquidity-card.module.scss'

export interface LiquidityCardProps {
  type: 'cash' | 'bank' | 'filler'
  amount?: number
  date?: number
  name?: string
  accountNumber?: string
  ifsc?: string
  openingBalance?: number
}

export const LiquidityCard = ({ type, accountNumber, amount, date, name, ifsc }: LiquidityCardProps) => {
  const apiClient = useApiClient()

  const { data: bankDetails } = useQuery(
    ifsc || 'ifsc',
    async () => {
      const { data } = await apiClient.get(`/banks`, { params: { ifsc } })

      return data
    },
    { enabled: type === 'bank' && ifsc !== undefined },
  )

  if (type === 'cash') {
    name = 'Cash in Hand'
  }

  return type === 'filler' ? (
    <div className={styles.filler} />
  ) : (
    <Card className={classNames(styles.filler, styles.card)}>
      <LiquidityCardTitle name={bankDetails?.name || name} />

      <span className={styles.acc}>
        <span className={styles.accNum}>{type === 'bank' && `A/c No. ${accountNumber}`}</span>
        <span className={styles.amount}>
          {amount && <Amount options={{ maximumFractionDigits: 0 }}>{amount}</Amount>}
        </span>
      </span>
      <span className={styles.date}>
        Last Transaction Date: {date ? DateFormatUtils.YMD(date, { spaceStyle: 'space', monthStyle: 'short' }) : ''}
      </span>
    </Card>
  )
}
