import axios from 'axios'
import Nope from 'nope-validator'
import { ReactNode, createContext } from 'react'
import { useQuery } from 'react-query'

import { Loader } from '../components/loader/Loader'

export interface Config {
  apiURL: string
  googleClientId: string
}

const ConfigSchema = Nope.object().shape({
  apiURL: Nope.string().required().url(),
  googleClientId: Nope.string().required(),
})

export const ConfigContext = createContext<Config>({} as Config)

export interface ConfigContextProviderProps {
  children?: ReactNode
}

export const ConfigContextProvider = ({ children }: ConfigContextProviderProps) => {
  const configFile = process.env.NODE_ENV === 'production' ? 'config.json' : `config.${process.env.NODE_ENV}.json`

  const { isLoading, data, error } = useQuery<Config>(
    'config',
    async () => {
      const { data } = await axios.get<Config>('/' + configFile) //.then(({ data }) => new Config(data))

      data.apiURL = data.apiURL || new URL('/api', window.location.href).href

      const error = ConfigSchema.validate(data)

      if (error) {
        throw error
      }

      return data
    },
    {
      staleTime: Infinity,
      retry: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )

  if (error) {
    throw error
  }

  if (isLoading) {
    return <Loader />
  } else {
    return <ConfigContext.Provider value={data as Config}>{children}</ConfigContext.Provider>
  }
}
