import { CartesianScaleOptions, Chart, ChartType, Plugin, Scale } from 'chart.js'
import { color, renderText, toFont } from 'chart.js/helpers'

declare module 'chart.js' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PluginOptionsByType<TType extends ChartType> {
    showPanLimits: ShowPanLimitsOptions
  }
}

interface ShowPanLimitsOptions {
  enabled: true
}

export const ShowPanLimits = {
  id: 'showPanLimits',
  defaults: {
    enabled: false,
  },
  afterDraw: (chart: Chart<'bar' | 'line'>, obj, options) => {
    // console.debug('ShowPanLimits: afterDraw', options.enabled)

    if (options.enabled) {
      const x = chart.scales.x as Scale<CartesianScaleOptions>
      const labels = x.getLabels()

      if (x.min > 0) {
        renderText(
          chart.ctx,
          `${labels[0]} <<`,
          x.left,
          x.top + (x.options.ticks.padding || 0),
          toFont({ ...x.options.ticks.font }),
          {
            maxWidth: x.left,
            textAlign: 'right',
            textBaseline: 'top',
            color: color(x.options.ticks.color as string)
              .alpha(0.7)
              .rgbString(),
          },
        )
      }

      if (x.max < labels.length - 1) {
        renderText(
          chart.ctx,
          `>> ${labels[labels.length - 1]}`,
          x.right,
          x.top + (x.options.ticks.padding || 0),
          toFont({ ...x.options.ticks.font }),
          {
            maxWidth: chart.width - x.right,
            textAlign: 'left',
            textBaseline: 'top',
            color: color(x.options.ticks.color as string)
              .alpha(0.7)
              .rgbString(),
          },
        )
      }
    }
  },
} as Plugin<any, ShowPanLimitsOptions>
