import classNames from 'classnames'
import { Fragment, useEffect, useState } from 'react'
import { Placeholder } from 'react-bootstrap'

import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg'
import { DateFormatUtils, DateFyUtils } from '../../../utils/date'
import { Button } from '../../common/button/Button'
import Dropdown from '../../common/dropdown/Dropdown'

import styles from './period-selector.module.scss'

export interface PeriodToggleProps {
  startYear?: number
  endYear?: number
  show?: boolean
  selected?: number
  handleSelect?: (year: number) => void
}

interface FYProps {
  year: number
}

const currentYear = DateFyUtils.FY()

const FY = ({ year }: FYProps) => {
  return (
    <Fragment>
      <span>{DateFormatUtils.FY(year)}</span>
      {year === currentYear && <span className={styles.annotationText}>(Current Year)</span>}
      {year === currentYear - 1 && <span className={styles.annotationText}>(Last Year)</span>}
    </Fragment>
  )
}

export const PeriodSelector = ({
  startYear = NaN,
  endYear = NaN,
  show,
  selected = endYear,
  handleSelect,
}: PeriodToggleProps) => {
  const [selectedYear, setSelectedYear] = useState(selected)

  useEffect(() => {
    if (isNaN(selectedYear)) {
      setSelectedYear(selected)
    }
  }, [selectedYear, selected])

  if (endYear < startYear) {
    console.error('end year should not be less start year')
    return null
  }

  const yearOffsets = Array.from(Array(endYear - startYear + 1 || 0).keys())

  return (
    <Dropdown
      onSelect={(year) => {
        year && setSelectedYear(+year)
        year && handleSelect && handleSelect(+year)
      }}
      show={show}
    >
      <Dropdown.Toggle as={Button} variant={'outline'} className={classNames(styles.toggle)}>
        <CalendarIcon className={styles.icon} />
        <span className={styles.selectedContent}>
          {isNaN(selectedYear) ? (
            <Placeholder animation={'glow'}>
              <Placeholder className={styles.placeholder} />
            </Placeholder>
          ) : (
            <FY year={selectedYear} />
          )}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu
        popperConfig={{ modifiers: [{ name: 'offset', options: { offset: [0, 0] } }] }}
        className={styles.menu}
      >
        {yearOffsets.map((offset) => {
          const year = endYear - offset

          return (
            <Fragment key={offset}>
              <Dropdown.Item as={Button} className={classNames(styles.item)} eventKey={year}>
                <FY year={year} />
              </Dropdown.Item>
              {offset !== yearOffsets.length - 1 && <Dropdown.Divider className={styles.divider} />}
            </Fragment>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}
