import { Fragment } from 'react'

import { useCurrencyFormat } from '../../hooks/useCurrencyFormat'
import { Invoice } from '../../types/accounting'
import { DateFormatUtils } from '../../utils/date'
import { Table } from '../table/Table'

import styles from './invoice-details.module.scss'

export interface InvoiceDetailsProps {
  invoice: Invoice
}

export const InvoiceDetails = ({ invoice }: InvoiceDetailsProps) => {
  const { currencySymbol, formatValue } = useCurrencyFormat()

  return (
    <div className={styles.adjustedTable}>
      {invoice.linkedVouchers?.length > 0 ? (
        <Fragment>
          <h5>Adjusted Transactions</h5>
          <Table
            variant={'subTable'}
            tableProps={{
              data: invoice.linkedVouchers,
              columns: [
                { Header: 'S.No.', accessor: (r: any, index: number) => index + 1 },
                {
                  Header: 'Document Date',
                  accessor: 'date',
                  Cell: ({ value }: any) => DateFormatUtils.YMD(value, { monthStyle: 'short', spaceStyle: 'space' }),
                },
                { Header: 'Ledger', accessor: 'ledgerName' },
                { Header: 'Transaction Type', accessor: 'voucherType' },
                { Header: 'Document No,', accessor: 'voucherNumber' },
                {
                  Header: `Transaction Amt. (${currencySymbol})`,
                  accessor: (row: any) => formatValue(row.amount),
                },
                {
                  Header: `Adjusted Amt. (${currencySymbol})`,
                  accessor: (row: any) => formatValue(row.adjusted),
                },
              ],
            }}
          />
        </Fragment>
      ) : (
        <span>No Adjusted Transactions</span>
      )}
    </div>
  )
}
