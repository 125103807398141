import dayjs from 'dayjs'

import { DATETIME_FORMAT } from '../../utils/date'

import styles from './topbar.module.scss'

export interface TopbarLastSyncTimeProps {
  time: string
}

export const TopbarLastSyncTime = ({ time }: TopbarLastSyncTimeProps) => {
  const lastSyncDate = dayjs(time)
  const formattedLastSyncDate = `${lastSyncDate.fromNow()} (${lastSyncDate.format(DATETIME_FORMAT)})`

  return (
    <span className={styles.lastSync}>
      <span className={styles.prompt}>Last Sync on: </span>
      <span className={styles.value}>{formattedLastSyncDate}</span>
    </span>
  )
}
