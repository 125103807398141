import classNames from 'classnames'
import dayjs from 'dayjs'
import { Fragment, useContext, useRef } from 'react'
import equal from 'react-fast-compare'

import { AnalyticsStat } from '../../components/analytics/AnalyticsStat'
import { LiquidityCard, LiquidityCardProps } from '../../components/analytics/LiquidityCard'
import { MonthlyChart } from '../../components/analytics/MonthlyChart'
import { ChartComponent } from '../../components/common/chart/ChartComponent'
import { useBarLineChart } from '../../components/common/chart/useBarLineChart'
import { useMemoCompare } from '../../hooks/useMemoCompare'
import { AccountingContext } from '../../layouts/AccountingLayout'
import { PageContext } from '../../layouts/DashboardLayout'
import { Voucher } from '../../types/accounting'
import { monthlyAggregations, sum } from '../../utils/calc'
import { DateFormatUtils, DateFyUtils, DateTransformUtils } from '../../utils/date'

import styles from './cash-flow.module.scss'

export const CashFlow = () => {
  // console.debug('rendering cash flow page')

  const { company } = useContext(PageContext)
  const { vouchers, selectedFy } = useContext(AccountingContext)
  const fyStart = DateFyUtils.START_YMD(selectedFy)
  const fyEnd = DateFyUtils.END_YMD(selectedFy)

  const cashVouchers = vouchers.filter((x) => x.master.parent?.name === 'Cash-in-Hand' && x.date <= fyEnd)

  const cashStat: LiquidityCardProps = {
    type: 'cash',
    amount: sum(cashVouchers.map((x) => -x.amount)) - (cashVouchers[0].master.openingBalance || 0),
    date: Math.max(...cashVouchers.map((x) => x.date)),
    openingBalance: cashVouchers[0].master.openingBalance || 0,
  }

  const allBankVouchers = vouchers.filter((x) => x.master.parent?.name === 'Bank Accounts' && x.date <= fyEnd)

  const bankStats: LiquidityCardProps[] = Object.values(
    allBankVouchers.reduce(
      (a, x) => ({ ...a, [x.master._id]: [...(a[x.master._id] || []), x] }),
      {} as Record<string, Voucher[]>,
    ) as Record<string, Voucher[]>,
  ).map((bankVouchers) => ({
    type: 'bank',
    amount: sum(bankVouchers.map((x) => -x.amount)) - (bankVouchers[0].master.openingBalance || 0),
    date: Math.max(...bankVouchers.map((x) => x.date)),
    name: bankVouchers[0].master.name,
    accountNumber: bankVouchers[0].master.bankAccount?.accountNumber,
    ifsc: bankVouchers[0].master.bankAccount?.ifscCode,
    openingBalance: bankVouchers[0].master.openingBalance || 0,
  }))

  const totalAmount = (cashStat.amount || 0) + sum(bankStats.map((x) => x.amount || 0))
  const lastTransactionDate = Math.max(cashStat.date || 0, ...bankStats.map((x) => x.date || 0))

  // liquidity history -> include closing balance
  const liquidityVouchers = [...cashVouchers, ...allBankVouchers]

  let fyOpeningBalance = 0
  let ledgerOpeningBalances = {} as Record<string, number>

  for (let i = 0; i < liquidityVouchers.length; i++) {
    const voucher = liquidityVouchers[i]

    if (voucher.date < fyStart) {
      fyOpeningBalance += -voucher.amount
      ledgerOpeningBalances[voucher.master._id] = -(voucher.master.openingBalance || 0)
    }
  }
  fyOpeningBalance += -(
    (cashStat.openingBalance || 0) + sum(Object.values(bankStats.map((x) => x.openingBalance || 0)))
  )

  const fyVouchers = liquidityVouchers.filter((x) => x.date >= fyStart && x.date <= fyEnd)
  const cashFlowHistory = monthlyAggregations(fyVouchers, 'date', 'amount', { start: fyStart, end: fyEnd })

  let lastOpeningBalance = fyOpeningBalance
  for (let i = 0; i < cashFlowHistory.length; i++) {
    const monthlyLiquidity = cashFlowHistory[i]

    if (monthlyLiquidity.yearMonth <= DateTransformUtils.YMD_YM(lastTransactionDate)) {
      cashFlowHistory[i].amount = lastOpeningBalance - cashFlowHistory[i].amount
      lastOpeningBalance = cashFlowHistory[i].amount
    }
  }

  //cash flow history ->without closing balance
  const monthlyCashInflow = monthlyAggregations(
    fyVouchers.filter((x) => x.amount < 0),
    'date',
    'amount',
    { start: fyStart, end: fyEnd },
  )

  const monthlyCashOutflow = monthlyAggregations(
    fyVouchers.filter((x) => x.amount > 0),
    'date',
    'amount',
    { start: fyStart, end: fyEnd },
  )

  const monthlyNetCashFlow = monthlyCashInflow.map((x, i) => ({
    ...x,
    amount: x.amount + monthlyCashOutflow[i].amount,
  }))

  const chartRef = useRef()
  const { chartProps, Legend: CashflowLegend } = useBarLineChart({
    chartRef: chartRef,
    labels: monthlyCashInflow.map((x) => DateFormatUtils.M(DateTransformUtils.YM_M(x.yearMonth))),
    bar1: {
      title: 'Cash Inflow',
      data: monthlyCashInflow
        .filter((x) => x.yearMonth <= DateTransformUtils.YMD_YM(lastTransactionDate))
        .map((x) => -x.amount),
      color: '#6896DA',
    },
    bar2: {
      title: 'Cash Outflow',
      data: monthlyCashOutflow
        .filter((x) => x.yearMonth <= DateTransformUtils.YMD_YM(lastTransactionDate))
        .map((x) => -x.amount),
      color: '#D17152',
    },
    line: {
      title: 'Net Cash Flow',
      axis: 'left',
      data: monthlyNetCashFlow
        .filter((x) => x.yearMonth <= DateTransformUtils.YMD_YM(lastTransactionDate))
        .map((x) => -x.amount),
      positiveColor: '#29A32E',
    },
  })

  const cashFlowHistoryMemo = useMemoCompare(cashFlowHistory, equal)

  return (
    <Fragment>
      <span className={styles.mainStat}>
        <AnalyticsStat
          title={'Cash & Bank Total'}
          mainStat={{ label: '', value: totalAmount }}
          secondaryStat={{
            label: 'Balance as on',
            value: +dayjs(company.lastSyncDateTime).format('YYYYMMDD'),
          }}
          variant={'cashFlow'}
        />
      </span>

      <div className={styles.cards}>
        <LiquidityCard {...cashStat} />
        {bankStats.map((bankStat, i) => (
          <LiquidityCard key={i} {...bankStat} />
        ))}
        {Array.from(Array(3 - ((1 + bankStats.length) % 3 || 3)).keys()).map((x, i) => (
          <LiquidityCard key={i} type={'filler'} />
        ))}
      </div>

      <h2 className={styles.title}>Liquidity History</h2>
      <MonthlyChart data={cashFlowHistoryMemo} positiveOnly={false} />

      <div className={classNames(styles.title, styles.legendTitle)}>
        <h2>Cash Flow History</h2>
        <CashflowLegend />
      </div>
      <div className={styles.cashFlowChart}>
        <ChartComponent chartRef={chartRef} props={chartProps} />
      </div>
    </Fragment>
  )
}
