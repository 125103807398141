import { useContext } from 'react'

import { InvoiceTracker } from '../../components/invoice/InvoiceTracker'
import { AccountingContext } from '../../layouts/AccountingLayout'
import { DateFormatUtils, DateFyUtils } from '../../utils/date'

export const ReceivableInvoices = () => {
  const { vouchers, selectedFy } = useContext(AccountingContext)

  const fyEndDate = DateFyUtils.END_YMD(selectedFy)

  const debtorVouchers = vouchers
    .filter((x) => x.master.parent?.name === 'Sundry Debtors' && x.date <= fyEndDate)
    .map((x) => ({
      ...x,
      amount: -x.amount,
      billAllocations: x.billAllocations.map((b) => ({ ...b, amount: -b.amount })),
    }))

  const salesVoucherTypes = ['Sales', 'Debit Note']

  return (
    <InvoiceTracker
      vouchers={debtorVouchers}
      voucherTypes={salesVoucherTypes}
      chartVoucherTypes={['Sales', 'Receipt']}
      text={{
        section2: `Sales Invoices (${DateFormatUtils.FY(selectedFy)})`,
        section3: `Previous Period Unpaid Invoices`,
        legendData1: 'Sales',
        legendData2: 'Receipts',
      }}
    />
  )
}
