import { getHoverColor } from 'chart.js/helpers'
import { RefObject, useEffect, useMemo } from 'react'
import { ChartProps } from 'react-chartjs-2'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

import { usePercentFormat } from '../../../hooks/usePercentFormat'
import { DoughnutLabelPlugin } from './plugins/DoughnutLabel'
import { useChart } from './useChart'

export interface UseGaugeChartProps {
  chartRef: RefObject<ChartJSOrUndefined<'doughnut', number[], string>>
  value: number
  total: number
  color: string
}

export const useGaugeChart = ({ chartRef, value, total, color }: UseGaugeChartProps) => {
  const formatOptions = useMemo(() => ({ minimumFractionDigits: 0, maximumFractionDigits: 0 }), [])
  const { format } = usePercentFormat(formatOptions)

  const props: ChartProps<'doughnut', number[], string> = {
    type: 'doughnut',
    data: {
      datasets: [
        {
          data: [value > 0 ? value : 0, total > value ? total - value : 0],
          backgroundColor: [color, '#F2F1F6'],
          hoverBackgroundColor: [getHoverColor(color), '#F2F1F6'],
        },
      ],
    },
    plugins: [DoughnutLabelPlugin],
    options: {
      cutout: '80%',
      rotation: -90,
      layout: { padding: 0 },
      scales: {
        x: { display: false },
        y: { display: false },
      },
      plugins: {
        tooltip: { enabled: false },
        doughnutLabel: { labels: [{ color, text: format(value / total), size: 20, weight: '700' }] },
      },
    },
  }

  useEffect(() => {
    if (chartRef.current) {
      //     console.debug('useGaugeChart: updating data')
      if (chartRef.current.options.plugins?.doughnutLabel?.labels) {
        chartRef.current.options.plugins.doughnutLabel.labels[0].size = chartRef.current.height * 0.25
      }
      chartRef.current.update()
    }
  })

  return { ...useChart({ props }) }
}
