import { ChartType, Plugin } from 'chart.js'

interface VerticalLineOptions {
  enabled: boolean

  borderColor: string
  borderWidth: number

  mode: 'data' | 'tooltip'

  datasetIdx: number
  dataIdx: number
  offset: number
}

export const DefaultVerticalLineOptions: VerticalLineOptions = {
  enabled: false,
  borderColor: 'rgba(0, 0, 0, 0.1)',
  borderWidth: 2,
  mode: 'tooltip',
  datasetIdx: 0,
  dataIdx: 0,
  offset: 0,
}

export default {
  id: 'verticalLine',
  beforeDraw(chart) {
    const activeElements = chart.tooltip?.getActiveElements() || []
    const pluginOptions = chart.options?.plugins?.verticalLine || DefaultVerticalLineOptions
    pluginOptions.offset = pluginOptions.offset || 0

    if (pluginOptions.enabled) {
      const { top, bottom, left, right } = chart.chartArea
      let x = NaN
      let y = NaN

      if (pluginOptions.mode === 'tooltip' && activeElements.length > 0) {
        x = activeElements.map((x) => x.element.x).reduce((a, b) => a + b, 0) / activeElements.length
        y = top
      }

      if (
        pluginOptions.mode === 'data' &&
        pluginOptions.datasetIdx !== undefined &&
        pluginOptions.dataIdx !== undefined
      ) {
        const el = chart.getDatasetMeta(pluginOptions.datasetIdx).data[pluginOptions.dataIdx] || {}
        x = el.x
        y = el.y - pluginOptions.offset
      }

      if (x > left && x < right) {
        chart.ctx.save()
        chart.ctx.beginPath()
        chart.ctx.moveTo(x, bottom)
        chart.ctx.lineTo(x, y)
        chart.ctx.lineWidth = pluginOptions.borderWidth || DefaultVerticalLineOptions.borderWidth
        chart.ctx.strokeStyle = pluginOptions.borderColor || DefaultVerticalLineOptions.borderColor
        chart.ctx.setLineDash([4, 4])
        chart.ctx.stroke()
        chart.ctx.restore()
      }
    }
  },
  defaults: DefaultVerticalLineOptions,
} as Plugin

declare module 'chart.js' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PluginOptionsByType<TType extends ChartType> {
    verticalLine: VerticalLineOptions
  }
}
