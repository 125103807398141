import { ReactElement } from 'react'

import { TopbarBreadcrumb, TopbarBreadcrumbProps } from './TopbarBreadcrumb'
import { TopbarLastSyncTime, TopbarLastSyncTimeProps } from './TopbarLastSyncTime'
import { PeriodSelector, PeriodToggleProps } from './period-selector/PeriodSelector'

import styles from './topbar.module.scss'

export interface TopbarProps {
  breadcrumb: ReactElement<TopbarBreadcrumbProps, 'TopbarBreadcrumb'>
  lastSyncTime?: ReactElement<TopbarLastSyncTimeProps, 'TopbarLastSyncTime'>
  periodSelector?: ReactElement<PeriodToggleProps, 'PeriodSelector'>
}

export const Topbar = ({ breadcrumb, lastSyncTime, periodSelector }: TopbarProps) => {
  return (
    <header className={styles.topbar}>
      <span className={styles.pageInfo}>
        {breadcrumb}
        {lastSyncTime && lastSyncTime}
      </span>

      <span className={styles.buttons}>
        {periodSelector && periodSelector}

        {/*<Button variant={'primary'} className={classNames(styles.btn, styles.download)}>*/}
        {/*  <DownloadIcon className={styles.icon} />*/}
        {/*  <span className={styles.text}>DOWNLOAD</span>*/}
        {/*</Button>*/}

        {/*<Button className={styles.notification}>*/}
        {/*  <NotificationIcon />*/}
        {/*</Button>*/}
      </span>
    </header>
  )
}

Topbar.Breadcrumb = TopbarBreadcrumb
Topbar.LastSyncTime = TopbarLastSyncTime
Topbar.PeriodSelector = PeriodSelector
