import dayjs from 'dayjs'
import React, { useContext, useMemo, useRef } from 'react'

import { AccountingHead, calculateHeadTotals } from '../../accounting/accounting-data'
import { useIncomeData } from '../../accounting/useStatementData'
import { ChartComponent } from '../../components/common/chart/ChartComponent'
import { useRevenueChart } from '../../components/common/chart/useRevenueChart'
import { SummaryCard } from '../../components/summary-card/SummaryCard'
import { SummaryProfitCard } from '../../components/summary-profit-card/SummaryProfitCard'
import { AccountingContext } from '../../layouts/AccountingLayout'
import { PageContext } from '../../layouts/DashboardLayout'
import { isNearZero } from '../../utils/calc'
import { DateFormatUtils, DateFyUtils, DateTransformUtils } from '../../utils/date'

import styles from './Summary.module.scss'

export const Summary = () => {
  // console.debug('rendering summary')
  const { company } = useContext(PageContext)
  const { vouchers, masters, selectedFy, lastVoucherDate, isLatestFy, firstVoucherDate } = useContext(AccountingContext)

  const latestMonth = DateTransformUtils.YMD_YM(lastVoucherDate)
  const refMonth = isLatestFy
    ? DateTransformUtils.YMD_YM(DateTransformUtils.YMD_SUBTRACT_M(lastVoucherDate))
    : DateTransformUtils.YMD_YM(DateTransformUtils.SET_Y(lastVoucherDate, selectedFy))

  const fyString =
    DateFormatUtils.YM(DateTransformUtils.YMD_YM(DateFyUtils.START_YMD(selectedFy)), {
      monthStyle: 'short',
      spaceStyle: 'dash',
    }) +
    ' to ' +
    DateFormatUtils.YM(isLatestFy ? latestMonth : DateTransformUtils.YMD_YM(DateFyUtils.END_YMD(selectedFy)), {
      monthStyle: 'short',
      spaceStyle: 'dash',
    })
  const latestMonthString = DateFormatUtils.YM(latestMonth, { monthStyle: 'short', spaceStyle: 'dash' })
  const refMonthString = DateFormatUtils.YM(refMonth, { monthStyle: 'short', spaceStyle: 'dash' })

  const fyStartDate = DateFyUtils.START_YMD(selectedFy)
  const fyEndDate = DateFyUtils.END_YMD(selectedFy)

  const asOnDate = isLatestFy ? lastVoucherDate : fyEndDate
  const asOnDateString = `As on ${DateFormatUtils.YMD(asOnDate, { spaceStyle: 'dash', monthStyle: 'short' })}`

  const asOnSync = isLatestFy ? +dayjs(company.lastSyncDateTime).format('YYYYMMDD') : fyEndDate
  const asOnSyncString = `As on ${DateFormatUtils.YMD(asOnSync, { spaceStyle: 'dash', monthStyle: 'short' })}`

  const latestMonthStartDate = DateTransformUtils.YM_START_YMD(latestMonth)
  const latestMonthEndDate = DateTransformUtils.YM_END_YMD(latestMonth)
  const refMonthStartDate = DateTransformUtils.YM_START_YMD(refMonth)
  const refMonthEndDate = DateTransformUtils.YM_END_YMD(refMonth)

  const heads = [
    { head: 'revenue', title: 'Revenue', linkTo: '/analytics/revenue' },
    { head: 'purchases', title: 'Purchases', linkTo: '/analytics/purchases' },
    { head: 'expenses', title: 'Expenses', linkTo: '/analytics/expenses' },
    { head: 'liquidity', title: 'Cash & Bank', linkTo: '/analytics/liquidity' },
    { head: 'accountReceivable', title: 'Receivable', linkTo: '/receivable/ageing' },
    { head: 'accountPayable', title: 'Payable', linkTo: '/payable/ageing' },
  ] as { head: AccountingHead; title: string; linkTo: string }[]

  const metrics = heads.slice(0, 4).map(({ head, title, linkTo }) => {
    const fyData = calculateHeadTotals(head, vouchers, masters, fyStartDate, fyEndDate)
    const latestMonthData = calculateHeadTotals(head, vouchers, masters, latestMonthStartDate, latestMonthEndDate)
    const refMonthData = calculateHeadTotals(head, vouchers, masters, refMonthStartDate, refMonthEndDate)

    return {
      title: title,
      linkTo: linkTo,
      data: [
        { period: head === 'liquidity' ? asOnSyncString : fyString, value: fyData.total },
        { period: latestMonthString, value: latestMonthData.total },
        { period: refMonthString, value: refMonthData.total },
      ],
      fyData,
      refMonthData,
    }
  })

  const smallMetrics = heads.slice(4).map(({ head, title, linkTo }) => {
    const asOnData = calculateHeadTotals(head, vouchers, masters, firstVoucherDate, asOnDate)
    return {
      title: title,
      linkTo: linkTo,
      data: [{ period: asOnDateString, value: asOnData.total }],
      asOnData,
    }
  })

  // console.debug({ metrics })
  const lastMonth = DateTransformUtils.YMD_YM(
    isLatestFy ? DateTransformUtils.YMD_SUBTRACT_M(lastVoucherDate) : fyEndDate,
  )
  const lastMonthEndDate = DateTransformUtils.YM_END_YMD(lastMonth)

  const profitCurrMonth = DateTransformUtils.YMD_YM(DateTransformUtils.YMD_SUBTRACT_M(lastVoucherDate))
  const profitRefMonth = DateTransformUtils.YMD_YM(
    isLatestFy
      ? DateTransformUtils.YMD_SUBTRACT_M(DateTransformUtils.YMD_SUBTRACT_M(lastVoucherDate))
      : DateTransformUtils.SET_Y(DateTransformUtils.YMD_SUBTRACT_M(lastVoucherDate), selectedFy),
  )

  const salesData = calculateHeadTotals('revenue', vouchers, masters, fyStartDate, lastMonthEndDate)
  const {
    incomeHeads: { 'Net Profit': profitData },
  } = useIncomeData({ startDate: fyStartDate, endDate: lastMonthEndDate })

  const {
    incomeHeads: { 'Net Profit': currMonthProfitData },
  } = useIncomeData({
    startDate: DateTransformUtils.YM_START_YMD(profitCurrMonth),
    endDate: DateTransformUtils.YM_END_YMD(profitCurrMonth),
  })

  const {
    incomeHeads: { 'Net Profit': refMonthProfitData },
  } = useIncomeData({
    startDate: DateTransformUtils.YM_START_YMD(profitRefMonth),
    endDate: DateTransformUtils.YM_END_YMD(profitRefMonth),
  })

  // console.debug({ lastCompletedDate: lastMonthEndDate, incomeData, salesData })

  const profitMetric = {
    profitPercent: (profitData.total / salesData.total) * 100,
    profit: profitData.total,
    linkTo: '/statement/income',
    title: 'My Net Profit',
    period: fyString,
    compare: {
      changePercent: (currMonthProfitData.total - refMonthProfitData.total) / refMonthProfitData.total,
      currMonth: profitCurrMonth,
      refMonth: profitRefMonth,
    },
  }

  const chartRef = useRef()

  const data = useMemo(() => {
    return metrics[0].fyData.monthly.map((x, i) => ({
      month: x.yearMonth.toString(),
      revenue: x.yearMonth <= latestMonth ? x.amount : undefined,
      expense:
        x.yearMonth <= latestMonth
          ? metrics[1].fyData.monthly[i].amount + metrics[2].fyData.monthly[i].amount
          : undefined,
      profit:
        profitData.monthly[i] && profitData.monthly[i].amount !== undefined ? profitData.monthly[i].amount : undefined,
      profitPercent:
        profitData.monthly[i] && profitData.monthly[i].amount !== undefined
          ? 100 * (!isNearZero(x.amount) ? profitData.monthly[i].amount / x.amount : 0)
          : undefined,
    }))
  }, [latestMonth, metrics, profitData.monthly])

  // console.debug({ data })

  const { chartProps, RevenueLegend } = useRevenueChart({
    chartRef,
    data: data,
  })

  return (
    <div className={styles.content}>
      <div className={styles.metricWrapper}>
        <div className={styles.metricCards}>
          {metrics.map((metric) => (
            <SummaryCard key={metric.title} {...metric} />
          ))}
        </div>
        <div className={styles.metricCards}>
          {smallMetrics.map((metric) => (
            <SummaryCard key={metric.title} {...metric} />
          ))}
          <SummaryProfitCard {...profitMetric} />
        </div>
      </div>

      <div className={styles.revenueTitle}>
        <h2>Revenue, Total Expenses and Net Profit Margin</h2>
        <RevenueLegend />
      </div>
      <div className={styles.revenueChart}>
        <ChartComponent chartRef={chartRef} props={chartProps} />
      </div>
    </div>
  )
}
