import React from 'react'
import { Card, NavLink } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

import { ReactComponent as OpenIcon } from '../../assets/images/chevron-circle-right.svg'
import { Amount } from '../number/Amount'

import styles from './summary-card.module.scss'

export interface SummaryCardProps {
  title: string
  linkTo: string
  data: {
    period: string
    value: number
  }[]
}

export const SummaryCard = ({ title, linkTo, data: [primary, ...secondary] }: SummaryCardProps) => {
  return (
    <Card className={styles.card}>
      <Card.Body>
        <span className={styles.primaryWrap}>
          <span className={styles.primaryData}>
            <span className={styles.title}>{title}</span>
            <span className={styles.primaryPeriod}>{primary.period}</span>
          </span>

          <LinkContainer to={linkTo}>
            <NavLink className={styles.icon}>
              <OpenIcon />
            </NavLink>
          </LinkContainer>
        </span>
        <span className={styles.primaryValue} data-number={primary.value}>
          <Amount options={{ maximumFractionDigits: 0 }}>{primary.value}</Amount>
        </span>
        {secondary.length > 0 && (
          <span className={styles.secondaryWrap}>
            {secondary.map((s, i) => (
              <span key={i} className={styles.secondaryValue}>
                <span>{s.period}</span>
                <Amount options={{ maximumFractionDigits: 0 }}>{s.value}</Amount>
              </span>
            ))}
          </span>
        )}
      </Card.Body>
    </Card>
  )
}
