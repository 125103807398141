import classNames from 'classnames'
import { useState } from 'react'
import { Collapse } from 'react-bootstrap'

import { ReactComponent as ChevronIcon } from '../../../assets/images/chevron.svg'
import { ReactComponent as UserIcon } from '../../../assets/images/user.svg'
import { Button } from '../../common/button/Button'

import styles from './user-profile.module.scss'

export interface UserProfileProps {
  image?: string
  name: string
  handleLogout?: () => void
  handleSettings?: () => void
}

export const UserProfile = ({ name, handleLogout, handleSettings }: UserProfileProps) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className={styles.container}>
      <Button
        variant={'transparent'}
        className={classNames(styles.profileButton, expanded && styles.expanded)}
        onClick={() => setExpanded((e) => !e)}
      >
        <UserIcon className={styles.image} />
        <span className={styles.name}>{name}</span>
        <ChevronIcon className={classNames(styles.chevron, expanded && styles.expanded)} />
      </Button>
      <Collapse in={expanded}>
        <div className={styles.menu}>
          <Button
            variant={'transparent'}
            className={classNames(styles.item, styles.menuButton)}
            onClick={() => handleSettings && handleSettings()}
          >
            Settings
          </Button>
          <Button
            variant={'transparent'}
            className={classNames(styles.item, styles.menuButton, styles.logout)}
            onClick={() => handleLogout && handleLogout()}
          >
            Logout
          </Button>
        </div>
      </Collapse>
    </div>
  )
}
