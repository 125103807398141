import { MonthlyAggregate } from '../types/accounting'
import { DateTransformUtils } from './date'

export const monthlyAggregations = <T>(
  data: T[],
  dateKey: keyof T,
  valueKey: keyof T,
  fillMonths?: { start: number; end: number },
): MonthlyAggregate[] => {
  const _data = [...data]
  if (fillMonths) {
    const allMonths = DateTransformUtils.LIST_YM_BETWEEN_YMD(fillMonths.start, fillMonths.end)
    _data.push(...allMonths.map((x) => ({ [dateKey]: x * 100 + 1, [valueKey]: 0 } as any)))
  }

  return groupedAggregate(
    _data.map((x) => ({ yearMonth: Math.floor(+x[dateKey] / 100), amount: +x[valueKey] })),
    'yearMonth',
    'amount',
    'yearMonth',
    1,
  ).map((x) => ({ yearMonth: +x.yearMonth, amount: +x.amount }))
}

export const cumulateMonthlyAggregations = (
  aggregates: MonthlyAggregate[],
  openingBalance: number = 0,
): MonthlyAggregate[] => {
  let sum = openingBalance
  const cumulativeAggregates: MonthlyAggregate[] = []
  for (let i = 0; i < aggregates.length; i++) {
    cumulativeAggregates.push({ ...aggregates[i], amount: aggregates[i].amount + sum })
    sum += aggregates[i].amount
  }

  return cumulativeAggregates
}

export const sum = (arr: number[]) => arr.reduce((sum, x) => sum + x, 0)

export const groupedAggregate = <T, G extends keyof T, V extends keyof T>(
  data: T[],
  groupByKey: G,
  valueKey: V,
  sortByKey: G | V = valueKey,
  sortOrder: 1 | -1 = 1,
): ({ [k in G]: string } & { [k in V]: number })[] => {
  return Object.entries(
    data.reduce(
      (a, x) => ({ ...a, [`${x[groupByKey]}`]: +x[valueKey] + (a[`${x[groupByKey]}`] || 0) }),
      {} as { [k: string]: number },
    ),
  )
    .map(([k, v]) => ({ [groupByKey]: k, [valueKey]: v } as { [k in G]: string } & { [k in V]: number }))
    .sort((a, b) => (sortOrder === 1 ? +a[sortByKey] - +b[sortByKey] : +b[sortByKey] - +a[sortByKey]))
}

export const isNearZero = (num: number) => Math.abs(num) < 1e-4

export const monthlyGroup = <T, D extends string = 'yearMonth', V extends string = 'data'>(
  data: T[],
  dateKey: keyof T,
  options?: {
    yearMonthKey?: D
    groupKey?: V
    startDate?: number
    endDate?: number
  },
): ({ [k in D]: number } & { [k in V]: T[] })[] => {
  options = options || {}
  options.yearMonthKey = options.yearMonthKey || ('yearMonth' as D)
  options.groupKey = options.groupKey || ('data' as V)

  const dataDates = data.map((x) => +x[dateKey])
  options.startDate = options.startDate || Math.min(...dataDates)
  options.endDate = options.endDate || Math.max(...dataDates)

  const months = DateTransformUtils.LIST_YM_BETWEEN_YMD(options.startDate, options.endDate)
  const monthlyGroups = {} as Record<number, T[]>

  for (const month of months) {
    monthlyGroups[month] = [] as T[]
  }

  for (const dataPoint of data) {
    const yearMonth = DateTransformUtils.YMD_YM(+dataPoint[dateKey])
    monthlyGroups[yearMonth] = [...(monthlyGroups[yearMonth] || []), dataPoint]
  }

  return Object.entries(monthlyGroups).map(
    ([yearMonth, monthlyData]) =>
      ({
        [options?.yearMonthKey as string]: +yearMonth,
        [options?.groupKey as string]: monthlyData,
      } as { [k in D]: number } & { [k in V]: T[] }),
  )
}
