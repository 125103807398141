import { ReactNode, createContext } from 'react'

export interface Settings {
  locale: string
  currency: string
}

export const defaultSettings: Settings = {
  locale: 'en-in',
  currency: 'inr',
}

export const SettingsContext = createContext<Settings>(defaultSettings)

interface SettingsContextProviderProps {
  children?: ReactNode
}

export const SettingsContextProvider = ({ children }: SettingsContextProviderProps) => {
  return <SettingsContext.Provider value={defaultSettings}>{children}</SettingsContext.Provider>
}
