import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'

import { AppRouter } from './AppRouter'
import { AppContextProvider } from './app/AppContextProvider'
import { AppErrorBoundary } from './app/AppErrorBoundary'

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnWindowFocus: false,
      },
    },
  })

  return (
    <AppErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider>
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </AppContextProvider>
      </QueryClientProvider>
    </AppErrorBoundary>
  )
}

export default App
