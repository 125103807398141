import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import {
  ColumnInstance,
  Row,
  TableInstance,
  UseExpandedInstanceProps,
  UseExpandedOptions,
  UseFiltersColumnOptions,
  UseFiltersColumnProps,
  UseGroupByRowProps,
  UseGroupByState,
  UseSortByColumnOptions,
  UseSortByState,
  UseTableColumnOptions,
  UseTableOptions,
  useExpanded,
  useFilters,
  useGroupBy,
  useSortBy,
  useTable,
} from 'react-table'

import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg'
import { Button } from '../common/button/Button'

import styles from './reports-list.module.scss'

export interface ReportsListProps<T> {
  data: T[]
  nameKey: keyof T
  title: string
  groupKey?: keyof T
  activeIdx?: number
  setActiveIdx?: (name: number) => void
}

export const ReportsList = <T extends object>({
  data,
  nameKey,
  title,
  groupKey,
  activeIdx,
  setActiveIdx,
}: ReportsListProps<T>) => {
  const [active, setActive] = useState<number>(activeIdx || -1)

  useEffect(() => {
    if (activeIdx && active !== activeIdx) {
      setActive(activeIdx)
    }
  }, [active, activeIdx])

  const tableOptions = useMemo(
    () =>
      ({
        id: 'name',
        data: data,
        columns: [
          {
            accessor: nameKey,
            Header: title,
            Filter: ({ column }) => {
              const { filterValue, setFilter } = column as ColumnInstance<T> & UseFiltersColumnProps<T>

              return (
                <div className={styles.search}>
                  <SearchIcon />
                  <input
                    placeholder={'Search here'}
                    value={filterValue || ''}
                    onChange={(e) => setFilter(e.target.value || undefined)}
                  />
                </div>
              )
            },
          } as UseTableColumnOptions<T> & UseFiltersColumnOptions<T>,
          ...(groupKey
            ? [
                {
                  accessor: groupKey,
                  Filter: () => null,
                  sortType: (rowA, rowB, columnId) =>
                    rowA.values[columnId] === undefined
                      ? 1
                      : rowB.values[columnId] === undefined
                      ? -1
                      : rowA.values[columnId] <= rowB.values[columnId]
                      ? -1
                      : 1,
                } as UseTableColumnOptions<T> & UseFiltersColumnOptions<T> & UseSortByColumnOptions<T>,
              ]
            : []),
        ],
        initialState: {
          groupBy: [groupKey],
          hiddenColumns: [groupKey],
          sortBy: [{ id: nameKey }, { id: groupKey }],
        } as UseGroupByState<T> & UseSortByState<T>,
        autoResetExpanded: false,
      } as UseTableOptions<T> & UseExpandedOptions<T>),
    [data, groupKey, nameKey, title],
  )

  const plugins = [useFilters, useGroupBy, useSortBy, useExpanded]
  const tableInstance = useTable<T>(tableOptions, ...plugins) as TableInstance<T> & UseExpandedInstanceProps<T>
  const { headerGroups, getTableProps, rows, prepareRow, toggleAllRowsExpanded } = tableInstance

  useEffect(() => {
    // console.debug('expanding all the rows')
    toggleAllRowsExpanded(true)
  }, [toggleAllRowsExpanded, data])

  return (
    <div className={styles.panel}>
      <table>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((header) => (
                <th {...header.getHeaderProps()}>
                  <div className={styles.header}>
                    <div className={styles.title}>{header.render('Header')}</div>
                    <div>{header.render('Filter')}</div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableProps()}>
          {rows.map((row) => {
            const groupRow = row as Row<T> & UseGroupByRowProps<T>
            prepareRow(row)

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>
                    <div className={styles.cell}>
                      {groupRow.isGrouped ? (
                        <div className={styles.groupHeader}>{groupRow.values[groupKey as string] || 'Others'}</div>
                      ) : (
                        <Button
                          className={classNames(styles.value, row.index === active && styles.active)}
                          onClick={() => {
                            setActive(row.index)
                            setActiveIdx && setActiveIdx(row.index)
                          }}
                        >
                          {cell.render('Cell')}
                        </Button>
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
