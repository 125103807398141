import { useContext } from 'react'

import { SettingsContext } from '../app/Settings'
import { useNumberFormat } from './useNumberFormat'

export interface CurrencyFormatOptions extends Omit<Intl.NumberFormatOptions, 'style' | 'currency'> {}

export const useCurrencyFormat = (options?: CurrencyFormatOptions) => {
  const { currency } = useContext(SettingsContext)

  options = options || {}
  options = { minimumIntegerDigits: 1, minimumFractionDigits: 0, maximumFractionDigits: 2, ...options }

  const formatOptions = { ...options, style: 'currency', currency }

  const formatter: Intl.NumberFormat = useNumberFormat(formatOptions)
  const simpleFormatter: Intl.NumberFormat = useNumberFormat({ ...formatOptions, style: 'decimal' })

  const currencySymbol = formatter.formatToParts(NaN)[0].value

  return {
    currencySymbol,
    formatValue: (amount: number): string => simpleFormatter.format(amount),
    formatValueWithCurrency: (amount: number): string => formatter.format(amount),
    formatOptions,
  }
}
