import React, { Fragment } from 'react'
import { Card } from 'react-bootstrap'

import { UseDoughnutLegendProps, useDoughnutLegend } from './useDoughnutLegend'

import styles from './doughnut-legend.module.scss'

export interface DoughnutLegendProps extends UseDoughnutLegendProps {
  title: string
  ValueFormatter?: (props: any) => React.ReactElement
  displayTotal?: boolean
}

const DefaultValueFormatter = (props: any) => <Fragment>{props.children}</Fragment>

export const DoughnutLegend = ({
  chartRef,
  title,
  ValueFormatter = DefaultValueFormatter,
  displayTotal = false,
}: DoughnutLegendProps) => {
  // console.debug('DoughnutLegend')
  const { legendItems, total } = useDoughnutLegend({ chartRef })

  return (
    <Card className={styles.card}>
      <Card.Header className={styles.header}>
        <span className={styles.title}>{title}</span>
        {displayTotal && total !== undefined && (
          <span className={styles.total}>
            <ValueFormatter>{total}</ValueFormatter>
          </span>
        )}
      </Card.Header>
      <Card.Body className={styles.body}>
        {legendItems.map((legendItem, i) => (
          <div
            key={i}
            className={styles.item}
            style={{
              maxWidth: `${110 / legendItems.length}%`,
            }}
          >
            <div className={styles.color} style={{ backgroundColor: legendItem.color }} />
            <div className={styles.value}>
              <ValueFormatter>{legendItem.value}</ValueFormatter>
            </div>
            <div className={styles.label}>{legendItem.label}</div>
          </div>
        ))}
      </Card.Body>
    </Card>
  )
}
