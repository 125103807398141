import { Fragment, useContext, useMemo, useState } from 'react'
import { Column, UseTableOptions } from 'react-table'

import { IncomeHead, IncomeHeads } from '../../accounting/useStatementData'
import { Amount } from '../../components/number/Amount'
import { ReportsList } from '../../components/reports/ReportsList'
import { Table } from '../../components/table/Table'
import { useNumberFormat } from '../../hooks/useNumberFormat'
import { AccountingContext } from '../../layouts/AccountingLayout'
import { Master } from '../../types/accounting'
import { sum } from '../../utils/calc'
import { DateFormatUtils, DateFyUtils } from '../../utils/date'

import styles from './ledgers.module.scss'

export const Ledgers = () => {
  const { vouchers, selectedFy, lastVoucherDate } = useContext(AccountingContext)

  const startDate = DateFyUtils.START_YMD(selectedFy)
  const endDate = DateFyUtils.END_YMD(selectedFy)

  const fyTransactions = vouchers.filter((x) => x.date >= startDate && x.date <= endDate)

  const fyMasters = Object.values(
    vouchers
      .filter((x) => x.date <= endDate)
      .map((x) => x.master)
      .reduce((o, x) => ({ ...o, [x._id]: x }), {} as Record<string, Master>),
  ).map((x) => ({
    ...x,
    group:
      x.parent?.name === 'Sundry Debtors'
        ? 'Sundry Debtors'
        : x.parent?.name === 'Sundry Creditors'
        ? 'Sundry Creditors'
        : undefined,
  }))

  const [active, setActive] = useState<number>()

  const master = (active !== undefined && fyMasters[active]) || ({} as Master)

  const openingBalance =
    master._id !== undefined
      ? (master.openingBalance || 0) +
        sum(vouchers.filter((x) => x.date < startDate && x.master._id === master._id).map((x) => x.amount))
      : undefined
  let closingBalance: number | undefined =
    openingBalance !== undefined
      ? openingBalance +
        sum(
          vouchers
            .filter((x) => x.date >= startDate && x.date <= endDate && x.master._id === master._id)
            .map((x) => x.amount),
        )
      : undefined

  const ledgerTransactions = fyTransactions
    .filter((x) => x.master._id === master._id)
    .map((x) => ({
      ...x,
      particular: x.particulars.find((y) => Math.sign(y.amount) !== Math.sign(x.amount))?.ledgerName,
      debit: x.amount <= 0 ? x.amount : undefined,
      credit: x.amount > 0 ? x.amount : undefined,
      canExpand: true,
    }))

  // console.debug({ ledgerTransactions })

  if (IncomeHeads.indexOf(master.superParent?.name as IncomeHead) === -1) {
    ledgerTransactions.splice(0, 0, {
      date: startDate,
      particular: 'Opening Balance',
      debit: openingBalance && openingBalance <= 0 ? Math.abs(openingBalance) : undefined,
      credit: openingBalance && openingBalance > 0 ? openingBalance : undefined,
      canExpand: false,
    } as typeof ledgerTransactions[number])
  } else if (closingBalance !== undefined) {
    closingBalance -= openingBalance || 0
  }

  ledgerTransactions.push({
    particular: 'Period Total',
    debit: sum(ledgerTransactions.map((x) => x.debit || 0)),
    credit: sum(ledgerTransactions.map((x) => x.credit || 0)),
    canExpand: false,
  } as typeof ledgerTransactions[number])

  ledgerTransactions.push({
    particular: 'Closing Balance',
    debit: closingBalance && closingBalance <= 0 ? Math.abs(closingBalance) : undefined,
    credit: closingBalance && closingBalance > 0 ? closingBalance : undefined,
    canExpand: false,
  } as typeof ledgerTransactions[number])

  const { format } = useNumberFormat()

  const tableOptions = useMemo(
    () =>
      master._id !== undefined
        ? ({
            data: ledgerTransactions,
            columns: [
              // {
              //   id: 'index',
              //   accessor: (originalRow, index) => index + 1,
              //   Header: 'S.No.',
              // },
              {
                accessor: 'date',
                Header: 'Date',
                Cell: (props) =>
                  props.value ? DateFormatUtils.YMD(props.value, { monthStyle: 'short', spaceStyle: 'space' }) : '',
              },
              {
                accessor: 'particular',
                Header: 'Particulars',
                className: styles.narrationCol,
              },
              {
                accessor: 'voucherType',
                Header: 'Voucher Type',
              },
              {
                accessor: 'voucherNumber',
                Header: 'Voucher No.',
              },
              {
                id: 'debit',
                accessor: 'debit',
                Header: 'Amount (Dr.)',
                Cell: (props) => (props.value !== undefined ? format(props.value) : ''),
              },
              {
                id: 'credit',
                accessor: 'credit',
                Header: 'Amount (Cr.)',
                Cell: (props) => (props.value !== undefined ? format(props.value) : ''),
              },
            ],
          } as UseTableOptions<typeof ledgerTransactions[number]>)
        : undefined,
    [format, ledgerTransactions, master._id],
  )

  return (
    <div className={styles.page}>
      <div className={styles.list}>
        <ReportsList
          data={fyMasters}
          title={'All Ledgers'}
          nameKey={'name'}
          groupKey={'group'}
          activeIdx={active}
          setActiveIdx={setActive}
        />
      </div>
      <div className={styles.detail}>
        {active === undefined || active === -1 ? (
          <span>Select a ledger to view the details</span>
        ) : (
          <div>
            <div className={styles.titleRow}>
              <span className={styles.title}>{master.name}</span>
              <span className={styles.period}>
                {DateFormatUtils.YMD(startDate, { spaceStyle: 'space', monthStyle: 'short' })} to{' '}
                {DateFormatUtils.YMD(Math.min(endDate, lastVoucherDate), {
                  spaceStyle: 'space',
                  monthStyle: 'short',
                })}
              </span>
            </div>

            <div className={styles.detailList}>
              <span className={styles.label}>Closing Balance</span>
              <span className={styles.value}>
                {closingBalance !== undefined ? (
                  <span>
                    <Amount as={'span'}>{Math.abs(closingBalance)}</Amount>{' '}
                    {closingBalance < 0 ? '(Dr.)' : closingBalance > 0 ? '(Cr.)' : null}
                  </span>
                ) : (
                  '-'
                )}
              </span>

              <span className={styles.label}>Parent</span>
              <span className={styles.value}>{master.parent?.name || '-'}</span>

              {(master.parent?.name === 'Sundry Debtors' || master.parent?.name === 'Sundry Creditors') && (
                <Fragment>
                  <span className={styles.label}>GSTIN</span>
                  <span className={styles.value}>{master.gstin || '-'}</span>

                  <span className={styles.label}>Address</span>
                  <span className={styles.value}>
                    {[
                      master.address?.line1,
                      master.address?.line2,
                      master.address?.city,
                      master.address?.state,
                      master.address?.pinCode,
                    ]
                      .filter((x) => !!x)
                      .join(', ') || '-'}
                  </span>

                  <span className={styles.label}>Country</span>
                  <span className={styles.value}>{master.address?.country || '-'}</span>
                </Fragment>
              )}
            </div>

            {tableOptions && (
              <Table
                tableProps={tableOptions}
                variant={'fullWidth'}
                rowExpansion={'subComponent'}
                SubComponent={({ row }) => {
                  const voucherTransactions = vouchers
                    .filter((x) => row.original.voucherGuid === x.voucherGuid)
                    .map((x) => ({
                      ...x,
                      debit: x.amount <= 0 ? x.amount : undefined,
                      credit: x.amount > 0 ? x.amount : undefined,
                    }))
                    .map((x) => ({ ...x, by: x.debit !== undefined ? 'By' : 'To' }))
                    .sort((a, b) => (b.entryIdx || 0) - (a.entryIdx || 0))
                    .sort((a) => (a.debit !== undefined ? -1 : 1))

                  // console.debug({ voucherTransactions })

                  return (
                    <div className={styles.voucherSubTable}>
                      <Table
                        variant={'subTable'}
                        tableProps={{
                          data: voucherTransactions,
                          columns: [
                            {
                              accessor: 'by',
                            },
                            {
                              Header: 'Particular',
                              id: 'particular',
                              accessor: (row) => row.master.name,
                            },
                            {
                              id: 'dr',
                              accessor: (row) => (row.debit !== undefined ? 'Dr.' : ''),
                            },
                            {
                              Header: 'Debit',
                              accessor: 'debit',
                            },
                            {
                              Header: 'Credit',
                              accessor: 'credit',
                            },
                          ] as Column<typeof voucherTransactions[number]>[],
                        }}
                      />
                      <div>Narration: {voucherTransactions[0]?.narration || '-'}</div>
                    </div>
                  )
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
