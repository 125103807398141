import dayjs from 'dayjs'

export const DATETIME_FORMAT = 'MMM DD, YYYY hh:mmA'

export const DateUtils = {
  IS_YM_END_YMD: (ymd: number) => DateTransformUtils.YM_END_YMD(DateTransformUtils.YMD_YM(ymd)) === ymd,
}

export const DateTransformUtils = {
  /**
   * Create YYYYMM from Y and M
   * @param y
   * @param m
   * @constructor
   */
  YM: (y: number, m: number) => y * 100 + m,

  /**
   * Create YYYYMMDD from Y, M and D
   * @param y
   * @param m
   * @param d
   * @constructor
   */
  YMD: (y: number, m: number, d: number) => DateTransformUtils.YM(y, m) * 100 + d,

  YM_START_YMD: (ym: number) => ym * 100 + 1,
  YM_END_YMD: (ym: number) =>
    +dayjs(`${ym}`, 'YYYYMM').set('date', 1).add(1, 'month').subtract(1, 'day').format('YYYYMMDD'),

  M_FIRSTDAY: (y: number, m: number) => DateTransformUtils.YMD(y, m, 1),
  M_LASTDAY: (y: number, m: number) =>
    +dayjs().set('year', y).set('month', m).set('date', 1).subtract(1, 'day').format('YYYYMMDD'),

  /**
   * Replace year in YYYYMMDD with Y
   * @param ymd
   * @param y
   * @constructor
   */
  SET_Y: (ymd: number, y: number) => y * 10000 + (ymd % 10000),

  /**
   * Get Y from YYYYMM
   * @param ym
   * @constructor
   */
  YM_Y: (ym: number) => Math.floor(ym / 100),

  /**
   * Get M from YYYYMM
   * @param ym
   * @constructor
   */
  YM_M: (ym: number) => ym % 100,

  /**
   * Get YYYYMM from YYYYMMDD
   * @param ymd
   * @constructor
   */
  YMD_YM: (ymd: number) => Math.floor(ymd / 100),

  /**
   * Get M from YYYYMMDD
   * @param ymd
   * @constructor
   */
  YMD_M: (ymd: number) => DateTransformUtils.YMD_YM(ymd) % 100,

  /**
   * Get Y from YYYYMMDD
   * @param ymd
   * @constructor
   */
  YMD_Y: (ymd: number) => Math.floor(DateTransformUtils.YMD_YM(ymd) / 100),

  /**
   * Subtracts months from YYYYMMDD
   * @param ymd
   * @param numMonths
   * @constructor
   */
  YMD_SUBTRACT_M: (ymd: number, numMonths: number = 1) => {
    return +dayjs(DateTransformUtils.YMD_DATE(ymd)).subtract(numMonths, 'months').format('YYYYMMDD')
  },

  /**
   * Subtracts months from YYYYMMDD
   * @param ymd
   * @param numDays
   * @constructor
   */
  YMD_SUBTRACT_D: (ymd: number, numDays: number = 1) => {
    return +dayjs(DateTransformUtils.YMD_DATE(ymd)).subtract(numDays, 'days').format('YYYYMMDD')
  },

  /**
   * Converts YYYYMMDD to Date object
   * @param ymd
   * @constructor
   */
  YMD_DATE: (ymd: number) => {
    return dayjs(`${ymd}`, 'YYYYMMDD').toDate()
  },

  /**
   * Lists all YYYYMM between two dates. Inclusive of start and end date.
   * @param startYMD
   * @param endYMD
   * @constructor
   */
  LIST_YM_BETWEEN_YMD: (startYMD: number, endYMD: number) => {
    const yms = [] as number[]
    const startYM = DateTransformUtils.YMD_YM(startYMD)
    const endYM = DateTransformUtils.YMD_YM(endYMD)

    let currentYM = startYM
    while (currentYM <= endYM) {
      yms.push(currentYM)

      currentYM += 1

      if ((currentYM % 100) % 13 === 0) {
        const y = DateTransformUtils.YM_Y(currentYM)
        currentYM = (y + 1) * 100 + 1
      }
    }

    return yms
  },
}

export const DateFyUtils = {
  /**
   * Returns FY from YYYYMMDD. Returns current date if not argument is passed
   * @param ymd
   * @constructor
   */
  FY: (ymd: number = +dayjs().format('YYYYMMDD')) => {
    const m = DateTransformUtils.YMD_M(ymd)
    const y = DateTransformUtils.YMD_Y(ymd)
    return m > 3 ? y : y - 1
  },

  /**
   * Returns FY from YYYYMM
   * @param ym
   * @constructor
   */
  YM_FY: (ym: number) => {
    return DateFyUtils.FY(ym * 100)
  },

  /**
   * Returns FY month offset from YYYYMMDD. April -> 1, March -> 12
   * @param date
   * @constructor
   */
  FY_M: (date: number): number => {
    return ((DateTransformUtils.YMD_M(date) + 8) % 12) + 1
  },

  /**
   * Returns start date of FY i.e. 1 April
   * @param y
   * @constructor
   */
  START_YMD: (y: number) => DateTransformUtils.YMD(y, 4, 1),

  /**
   * Returns end date of FY i.e. 31 March
   * @param y
   * @constructor
   */
  END_YMD: (y: number) => (y + 1) * 10000 + 331,

  /**
   * Returns end month of FY i.e. March FY+1
   * @param y
   * @constructor
   */
  END_YM: (y: number) => (y + 1) * 100 + 3,

  /**
   * Checks if YYYYMMDD lies within FY
   * @param ymd
   * @param y
   * @constructor
   */
  IN_FY: (ymd: number, y: number) => {
    return ymd >= DateFyUtils.START_YMD(y) && ymd <= DateFyUtils.END_YMD(y)
  },

  /**
   * Lists all YYYYMM in a FY
   * @param y
   * @constructor
   */
  LIST_YM: (y: number): number[] => {
    const yms = []
    for (let i = 3; i <= 14; i++) {
      yms.push((i < 12 ? y : y + 1) * 100 + (i % 12) + 1)
    }
    return yms
  },

  /**
   * Lists all FYs between YYYYMMDD and YYYYMMDD (inclusive of both)
   * @param ymStart
   * @param ymEnd
   * @constructor
   */
  LIST_FY_BETWEEN_YMD: (ymStart: number, ymEnd: number): number[] => {
    const startFy = DateFyUtils.FY(ymStart)
    const endFy = DateFyUtils.FY(ymEnd)

    return endFy >= startFy
      ? Array(endFy - startFy + 1)
          .fill(startFy)
          .map((fy, offset) => fy + offset)
      : []
  },
}

export const DateFormatUtils = {
  /**
   * Month string. Ex: short: "Sep", long: "September"
   * @param m
   * @param style
   * @constructor
   */
  M: (m: number, style: 'short' | 'long' = 'short') => dayjs(`${m}`, 'MM').format(style === 'short' ? 'MMM' : 'MMMM'),

  /**
   * Month Year string. Ex: "Sep 21"
   * @param ym
   * @param options
   * @constructor
   */
  YM: (
    ym: number,
    options: { monthStyle: 'short' | 'long'; spaceStyle: 'space' | 'dash' } = {
      monthStyle: 'short',
      spaceStyle: 'space',
    },
  ) => {
    const monthFormat = options.monthStyle === 'short' ? 'MMM' : 'MMMM'
    const spaceFormat = options.spaceStyle === 'space' ? ' ' : '-'
    return dayjs(`${ym}`, 'YYYYMM').format([monthFormat, 'YY'].join(spaceFormat))
  },

  /**
   * Date string. Ex: 01-Sep-21
   * @param ymd
   * @param options
   * @constructor
   */
  YMD: (
    ymd: number,
    options: { spaceStyle: 'space' | 'dot' | 'dash'; monthStyle: 'number' | 'short' | 'long' } = {
      monthStyle: 'short',
      spaceStyle: 'dash',
    },
  ) => {
    const monthFormat = options.monthStyle === 'number' ? 'MM' : options.monthStyle === 'short' ? 'MMM' : 'MMMM'
    const spaceFormat = options.spaceStyle === 'space' ? ' ' : options.spaceStyle === 'dot' ? '.' : '-'

    return dayjs(`${ymd}`, 'YYYYMMDD').format(['DD', monthFormat, 'YYYY'].join(spaceFormat))
  },

  /**
   * FY string. Ex: FY 2020-21
   * @param y
   * @constructor
   */
  FY: (y: number) => `FY ${y}-${(y + 1) % 100}`,
}
