import { Fragment } from 'react'

import { ReactComponent as CashInHandIcon } from '../../assets/icons/cash-in-hand.svg'

import styles from './liquidity-card-title.module.scss'

const logos: Record<string, string> = {
  'IDFC FIRST BANK LTD': '/bank-logos/idfc.svg',
  'AXIS BANK': '/bank-logos/axis.svg',
  'INDUSIND BANK': '/bank-logos/indusind.svg',
}

export interface BankLogoProps {
  name: string
}

export const LiquidityCardTitle = ({ name }: BankLogoProps) => {
  let logoUrl = logos[name.toUpperCase()]

  return (
    <span className={styles.logo}>
      {logoUrl ? (
        <svg height={'100%'}>
          <image xlinkHref={logoUrl} height={'100%'} />
        </svg>
      ) : (
        <Fragment>
          <CashInHandIcon className={styles.icon} />
          <span className={styles.name}>{name}</span>
        </Fragment>
      )}
    </span>
  )
}
