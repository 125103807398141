import { useContext } from 'react'

import { Ageing } from '../../components/invoice/Ageing'
import { AccountingContext } from '../../layouts/AccountingLayout'
import { Voucher } from '../../types/accounting'
import { DateFyUtils } from '../../utils/date'

export const ReceivableAgeing = () => {
  const { vouchers, selectedFy } = useContext(AccountingContext)

  const selectedFyEndDate = DateFyUtils.END_YMD(selectedFy)

  const ledgers = Object.entries(
    vouchers
      .filter((x) => x.master.parent?.name === 'Sundry Debtors' && x.date <= selectedFyEndDate)
      .map((x) => ({
        ...x,
        amount: -x.amount,
        billAllocations: x.billAllocations.map((b) => ({ ...b, amount: -b.amount })),
      }))
      .reduce((a, x) => ({ ...a, [x.master.name]: [...(a[x.master.name] || []), x] }), {} as Record<string, Voucher[]>),
  ).map(([ledgerName, ledgerVouchers]) => ({
    name: ledgerName,
    openingBalance: -1 * (ledgerVouchers[0]?.master.openingBalance || 0),
    vouchers: ledgerVouchers.sort((a, b) => a.date - b.date),
  }))

  return (
    <Ageing
      ledgers={ledgers}
      voucherTypes={['Sales', 'Debit Note']}
      text={{
        section1: 'Debtor Days',
        section2: 'Account Receivable Ageing',
        section3: 'Advance from Customer Ageing',
        legendTitle: 'Total Receivable',
        totalAmount: 'Receivable',
        adjustedAmount: 'Received',
      }}
    />
  )
}
