import axios from 'axios'
import { useContext, useMemo } from 'react'

import { ConfigContext } from '../app/Config'
import { AuthContext } from '../auth/AuthContext'

export const useApiClient = (authenticatedRequest = true) => {
  const {
    client: { accessToken },
  } = useContext(AuthContext)

  const config = useContext(ConfigContext)

  return useMemo(
    () =>
      axios.create({
        baseURL: config.apiURL,
        headers: {
          ...(authenticatedRequest && { Authorization: `Bearer ${accessToken}` }),
        },
      }),
    [config.apiURL, accessToken, authenticatedRequest],
  )
}
